import * as web3lib from '@vrjam/vrjam-web3-lib';

export const web3libSwitchNetwork = async () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isSwitched = await web3lib.connectors.MetaMask.switchNetwork(process.env.REACT_APP_DEFAULT_NETWORK_ID);
  if (isSwitched) {
    window.location.reload();
  }
}
