import { editFieldsFromData } from '@/core/components/Edit/settings';

export enum ActionTypes {
  LOADING = '@event/LOADING',
  ERROR = '@event/ERROR',
  SET_EVENT_LIST = '@event/SET_EVENT_LIST',
  DELETE_EVENT = '@event/DELETE_EVENT',
  SET_EVENT_EDIT_ITEM = '@event/SET_EVENT_EDIT_ITEM',
  SET_EVENT_CREATORS_LIST = '@event/SET_EVENT_CREATORS_LIST',
}

export interface EventStateProps {
  itemsList: any;
  editedItem: any;
  creatorsList: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_EVENT_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.DELETE_EVENT;
      payload: any;
    }
  | {
      type: ActionTypes.SET_EVENT_EDIT_ITEM;
      payload: any;
    }
  | {
      type: ActionTypes.SET_EVENT_CREATORS_LIST;
      payload: any;
    };

const eventListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: eventListState,
  editedItem: null,
  creatorsList: [],
  isLoading: false,
  isError: false,
};

export const eventReducer = (state: EventStateProps = initialState, action: Action): EventStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_EVENT_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_EVENT_EDIT_ITEM: {
      const gallery = Object.keys(action.payload.gallery).map((uuid) => ({
        uuid,
        ...action.payload.gallery[uuid],
      }));
      const myData = { ...action.payload, gallery: gallery };

      return {
        ...state,
        editedItem: myData,
        isError: false,
      };
    }

    case ActionTypes.SET_EVENT_CREATORS_LIST: {
      return {
        ...state,
        creatorsList: action.payload,
        isLoading: false,
        isError: false,
      };
    }

    default:
      return state;
  }
};
