
export enum ActionTypes {
  LOADING = '@assistant/LOADING',
  ERROR = '@assistant/ERROR',
  SET_ASSISTANT_LIST = '@assistant/SET_ASSISTANT_LIST',
  SET_ASSISTANT_EDIT_ITEM = '@avatar/SET_ASSISTANT_EDIT_ITEM',
  DELETE_ASSISTANT = '@assistant/DELETE_ASSISTANT',
}

export interface AssistantStateProps {
  itemsList: any;
  editedItem: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_ASSISTANT_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.SET_ASSISTANT_EDIT_ITEM;
      payload: any;
    }
  | {
      type: ActionTypes.DELETE_ASSISTANT;
      payload: any;
    };

const assistantListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: assistantListState,
  editedItem: null,
  isLoading: false,
  isError: false,
};

export const assistantReducer = (state: AssistantStateProps = initialState, action: Action): AssistantStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_ASSISTANT_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_ASSISTANT_EDIT_ITEM: {
      return {
        ...state,
        editedItem: action.payload,
        isError: false,
      };
    }

    default:
      return state;
  }
};
