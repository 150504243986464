import {useState} from 'react';
import {Box, FormLabel} from '@mui/material';
import {FieldInputProps, FormikProps} from 'formik';

import {SearchUser} from '@/shared/components/EditEventRole/containers/SearchUser';

import styles from './SetOwnerAddress.module.scss';
import {notify} from "@/shared/components/Notify/Notify";

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
}

export interface OptionsProps {
  label: string;
  value: string;
}

export const SetOwnerAddress = (props: any): JSX.Element => {
  const {form, field} = props;

  const [user, setUser] = useState<any>(null);

  const setUserHandler = (value: any) => {
    if (!value?.walletAddress) {
      notify.error('This user has no wallet associated');
    } else {
      setUser(value);
      form.setFieldValue(field.name, value?.walletAddress || '');
    }
  };

  return (
    <Box className={styles.setUserId}>
      <FormLabel className={styles.setUserId__label}>
        {props?.label}
        <span> *</span>
      </FormLabel>
      <SearchUser
        user={user}
        setUser={setUserHandler}
      />
      {Boolean(form.errors[field.name]) && (
        <div className={styles.setUserId__error}>{form.errors[field.name]}</div>
      )}
    </Box>
  );
};
