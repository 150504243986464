import { userApi } from '@/api/user';
import { TypedDispatch } from '@/core/models';
import { notify } from '@/shared/components/Notify/Notify';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { ActionTypes } from '../reducers';

export const setLoading = (loading: boolean) => ({ type: ActionTypes.LOADING, payload: loading });

export const actionSetUsersList = (data: any) => ({ type: ActionTypes.SET_USER_LIST, payload: data });

export const actionSetUserEditedData = (data: any) => ({ type: ActionTypes.SET_USER_EDIT_ITEM, payload: data });

export const actionRolesLists = (data: any) => ({ type: ActionTypes.SET_USER_ROLES_LIST, payload: data });

export const actionUsersList = (filters?: FilterProps) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const noDeletingInView = {...filters, isDeleted: false};
    const response = await userApi.getUserData(noDeletingInView);

    if (response.data.success) {
      dispatch(actionSetUsersList(response.data.data));
    } else {
      dispatch({type: ActionTypes.ERROR});
      throw response.data.message;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;

    notify.error(error);
    dispatch({type: ActionTypes.ERROR});
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};

export const actionUpdateUser =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.updateUserDetailsAdmin(body);

      if (response.data.success) {
        dispatch(actionUsersList());
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateUserData =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.updateUserIdDetailsAdmin(body);

      if (response.data.success) {
        dispatch(actionUsersList());
        notify.success('User data updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionBanUser =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.bunUser(body);

      if (response.data.success) {
        dispatch(actionUsersList());
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUnbanUser =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.unBunUser(body);

      if (response.data.success) {
        dispatch(actionUsersList());
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetUserById =
  (id: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.getUserItemByid({ id });

      if (response.data.success) {
        const user = response.data.data.items[0] as any;

        const updatedUserData = {
          ...user,
          roles: user.roles.map((roleItem: any) => roleItem.role),
        };

        dispatch(actionSetUserEditedData(updatedUserData));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionCreateNewUser =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.createNewUser(body);

      if (response.data.success) {
        dispatch(actionUsersList());
        notify.success('New user created successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetUserRolesList =
  (body?: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.getRolesList(body);

      if (response.data.success) {
        dispatch(actionRolesLists(response.data.data));
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionAddUserRoles =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const { id, roles } = body;

      roles.forEach((item: { id: string }) => {
        userApi.addUserRole({ userId: id, roleId: item.id });
      });
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionRemoveUserRoles =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const { id, roles } = body;

      roles.forEach((item: { id: string }) => {
        userApi.removeUserRole({ userId: id, roleId: item.id });
      });
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };
