import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { ActionTypes } from '../reducers';
import { avatarApi, customAvatarApi, userApi } from '@/api';

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetCustomAvatarList = (data: any) => ({
  type: ActionTypes.SET_CUSTOM_AVATAR_LIST,
  payload: data,
});

export const actionSetCustomAvatarEditedItem = (data: any) => ({
  type: ActionTypes.SET_CUSTOM_AVATAR_EDIT_ITEM,
  payload: data,
});

export const actionCustomAvatarList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await customAvatarApi.getCustomAvatar(filters);

      if (response.data.success) {
        const { data } = response.data;
        for (const item of data.items) {
          if (item.avatarId) {
            const avatarResponse = await avatarApi.getAvatarItemByid(item.avatarId);
            if (avatarResponse.data.success) {
              item.ownerId = avatarResponse.data.data.ownerId;
            }
          }
        }

        const ownerIds = data.items
          .map((item: Record<string, any>) => item.ownerId)
          .filter((id: string | undefined) => id);

        if (ownerIds.length > 0) {
          const uniqueOwnerIds = new Set(ownerIds);
          const ownersResponse = await userApi.getUsersByIds(Array.from(uniqueOwnerIds) as string[]);

          if (ownersResponse.data.success) {
            const users = {};
            ownersResponse.data.data.forEach((user: Record<string, any>) => {
              users[user.id] = user;
            });
            data.items.forEach((item: Record<string, any>) => {
              if (item.ownerId) {
                item.owner = users[item.ownerId].username;
              }
            });
          }
        }

        dispatch(actionSetCustomAvatarList(data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateCustomAvatarItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));

      const response = await customAvatarApi.updateCustomAvatarItem(body);

      if (response.data.success) {
        notify.success('Avatar item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteCustomAvatar = async (id: string): Promise<void> => {
  try {
    const response = await customAvatarApi.deleteCustomAvatarItem(id);

    if (response.status === 204) {
      notify.success('Avatar item deleted successfully');
    } else {
      const error = response?.data?.message || response.message;

      throw error;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  }
};

export const actionGetCustomAvatarItemById =
  (id: string) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await customAvatarApi.getCustomAvatarByid(id);

      if (response.data.success) {
        dispatch(actionSetCustomAvatarEditedItem(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionCreateCustomAvatarItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response = await customAvatarApi.createCustomAvatarItem(body, config);

      if (response.data.success) {
        notify.success('Avatar item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionAssignCustomAvatar =
  (body: any) =>
    async (dispatch: TypedDispatch): Promise<void> => {
      try {
        dispatch(setLoading(true));

        const reqBody = {
          customAvatarId: body.id,
          userId: body.userId,
          name: body.name,
          description: body.description
        };

        const response = await customAvatarApi.assignCustomAvatar(reqBody);

        if (response.data.success) {
          notify.success('Avatar successfully assigned');
        } else {
          throw response.data.message;
        }
      } catch (e: any) {
        const error = e.response?.data?.message || e.message || e;

        notify.error(error);
        throw e;
      } finally {
        dispatch(setLoading(false));
      }
    };