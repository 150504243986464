// this file use for shown fieled and rename it label from './createFieldNames.js
// you get ERROR if settings row not exist in './createFieldNames.js
// if delete some obj from array this fiels will be desapear from editing view
// If you add fields here and get error - check exist field.name in '@/data/validationSettings.js' ?

import * as React from 'react';
import { CustomSelect } from '@/shared/components/CustomSelect';
import { CustomSelectVenue } from '@/shared/components/CustomSelectVenue';
import { CustomSelectPool } from '@/shared/components/CustomSelectPool';
import { CustomSelectProposal } from '@/shared/components/CustomSelectProposal';
import { CustomTextEditor } from '@/shared/components/CustomTextEditor';
import { CustomFileUploader } from '@/shared/components/CustomFileUploader';
import {
  activatedOptions,
  adminStatusCreateOptions,
  avatarTypeOptions,
  clientStatusOptions, FactionOptions,
  genderOptions, LocationOptions,
} from '@/data/constants';
import { CustomInputPassword } from '@/shared/components/CustomInputPassword';
import { CustomSelectRole } from '@/shared/components/CustomSelectRole';
import { EventRolesMultiSelect } from '@/core/components/EventRolesMultiSelect';
import { CustomBigNumberInput } from '@/shared/components/CustomBigNumberInput';
import moment from "moment/moment";

interface OptionPropsItem {
  label: string;
  value: string | boolean;
}

interface FieldsPropsItem {
  name: string;
  label: string;
  isRequired: boolean;
  isDisabled?: boolean;
  component?: JSX.Element | any;
  options?: OptionPropsItem[];
  type?: React.InputHTMLAttributes<unknown>['type'];
  decimals?: number;
  min?: string;
}

interface FieldsProps {
  venue: FieldsPropsItem[];
  event: FieldsPropsItem[];
  eventRole: FieldsPropsItem[];
  user: FieldsPropsItem[];
  instance: FieldsPropsItem[];
  customAvatar: FieldsPropsItem[];
  pool: FieldsPropsItem[];
}

export const formCreateFields = (serviceName: string): FieldsPropsItem[] => {
  const createFields: FieldsProps = {
    venue: [
      { name: 'name', label: 'Name', isRequired: true },
      {
        name: 'poolId',
        label: 'Pool',
        isRequired: false,
        component: CustomSelectPool,
      },
      { name: 'sceneId', label: 'Scene Id', isRequired: true },
      { name: 'metaUrl', label: 'Meta URL', isRequired: true },
      {
        name: 'faction',
        label: 'Faction',
        isRequired: true,
        component: CustomSelect,
        options: FactionOptions,
      },
      {
        name: 'location',
        label: 'Location',
        isRequired: true,
        component: CustomSelect,
        options: LocationOptions,
      },
      {
        name: 'thumbnailFile',
        label: 'Thumbnail image',
        isRequired: true,
        component: CustomFileUploader,
      },
      {
        name: 'coverFile',
        label: 'Cover image',
        isRequired: true,
        component: CustomFileUploader,
      },
      {
        name: 'description',
        label: 'Description',
        isRequired: false,
        component: CustomTextEditor,
      },
    ],
    event: [
      { name: 'title', label: 'Event title', isRequired: true },
      {
        name: 'venueId',
        label: 'Venue',
        isRequired: true,
        component: CustomSelectVenue,
      },
      {
        name: 'startAt',
        label: 'Started',
        isRequired: true,
        type: 'datetime-local',
      },
      {
        name: 'finishAt',
        label: 'Finished',
        isRequired: true,
        type: 'datetime-local',
      },
      { name: 'logUrl', label: 'Log URL', isRequired: false },
      { name: 'videoUrl', label: 'Video URL', isRequired: false },
      { name: 'secondVideoURL', label: 'Second Video URL', isRequired: false },
      { name: 'previewVideoUrl', label: 'Preview Video URL', isRequired: false },
      { name: 'mockupPrefubId', label: 'Mockup Prefub ID', isRequired: false },
      {
        name: 'adminStatus',
        label: 'Admin Status',
        isRequired: true,
        component: CustomSelect,
        options: adminStatusCreateOptions,
      },
      {
        name: 'clientStatus',
        label: 'Client Status',
        isRequired: true,
        component: CustomSelect,
        options: clientStatusOptions,
      },
      { name: 'stream', label: 'Stream', isRequired: true },
      {
        name: 'thumbnailFile',
        label: 'Thumbnail image',
        isRequired: true,
        component: CustomFileUploader,
      },
      {
        name: 'coverFile',
        label: 'Cover image',
        isRequired: true,
        component: CustomFileUploader,
      },
      {
        name: 'description',
        label: 'Description',
        isRequired: false,
        component: CustomTextEditor,
      },
    ],
    eventRole: [
      { name: 'name', label: 'Event role', isRequired: true },
      {
        name: 'permissions',
        label: 'Permission',
        isRequired: true,
        component: EventRolesMultiSelect,
      },
    ],
    user: [
      { name: 'username', label: 'User name', isRequired: true },
      {
        name: 'email',
        label: 'Email',
        isRequired: true,
      },
      {
        name: 'password',
        label: 'Password',
        isRequired: true,
        component: CustomInputPassword,
      },
      {
        name: 'passwordConfirmation',
        label: 'Confirm password',
        isRequired: true,
        component: CustomInputPassword,
      },
      {
        name: 'isActivated',
        label: 'isActivated',
        isRequired: false,
        component: CustomSelect,
        options: activatedOptions,
      },
      {
        name: 'roleId',
        label: 'Role',
        isRequired: true,
        component: CustomSelectRole,
      },
      {
        name: 'faction',
        label: 'Faction',
        isRequired: true,
        component: CustomSelect,
        options: FactionOptions,
      },
    ],
    instance: [
      {
        name: 'venueId',
        label: 'Venue',
        isRequired: true,
        component: CustomSelectVenue,
      },
      { name: 'slots', label: 'Slots', isRequired: true, type: 'number' },
      { name: 'selfAddressUrl', label: 'Self address URL', isRequired: true },
      { name: 'voipStream', label: 'Voip stream', isRequired: true },
      { name: 'logUrl', label: 'log URL', isRequired: false },
    ],
    customAvatar: [
      {
        name: 'name',
        label: 'Name',
        isRequired: true,
      },
      {
        name: 'type',
        label: 'Type',
        isRequired: true,
        component: CustomSelect,
        options: avatarTypeOptions,
      },
      {
        name: 'gender',
        label: 'Gender',
        isRequired: true,
        component: CustomSelect,
        options: genderOptions,
      },
      {
        name: 'isActive',
        label: 'Active',
        isRequired: true,
        component: CustomSelect,
        options: activatedOptions,
      },
      {
        name: 'glbFile',
        label: '3D model',
        isRequired: true,
        component: CustomFileUploader,
      },
      {
        name: 'thumbnailFile',
        label: 'Thumbnail image',
        isRequired: true,
        component: CustomFileUploader,
      },
      {
        name: 'description',
        label: 'Description',
        isRequired: false,
        component: CustomTextEditor,
      },
    ],
    pool: [
      {
        name: 'proposalId',
        label: 'Proposal',
        isRequired: true,
        component: CustomSelectProposal,

      },
      {
        name: 'periodStart',
        label: 'Start',
        isRequired: true,
        type: 'datetime-local',
        min: moment().add(5, 'minutes').format('YYYY-MM-DDTHH:mm'),
      },
      {
        name: 'periodFinish',
        label: 'Finish',
        isRequired: true,
        type: 'datetime-local',
      },
      {
        name: 'amount',
        label: 'Amount',
        isRequired: true,
        component: CustomBigNumberInput,
        decimals: 16,
      },
      {
        name: 'duration',
        label: 'Lock period (days)',
        isRequired: true,
        type: 'number',
      },
    ],
  };

  return createFields[serviceName];
};
