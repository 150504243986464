import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import { actionGetCreatorsList } from '@/modules/EventsPage/actions';
import { serviceNameChecker } from '@/core/utils/serviceNameChecker';
import { FilterProps } from '@/core/hooks/useGetVenueList';
import { ErrorLoading } from '@/shared/components/ErrorLoading';
import { columns } from './columns';

import styles from './TableBody.module.scss';

interface TableBodyProps {
  filters: any;
  setFilters: any;
  serviceData: any;
}

export const TableBody = ({ filters, setFilters, serviceData }: TableBodyProps): JSX.Element | null => {
  const dispatch = useDispatch<any>();
  const currentPath = useLocation();

  const [tableItems, setTableItems] = useState<any>(null);

  const moduleName = currentPath.pathname.split('/')[1];
  const service = serviceNameChecker(moduleName);

  const { itemsList, isLoading, isError } = serviceData;
  const { items, meta } = itemsList;
  const { limit } = meta;

  useEffect(() => {
    if (items) {
      setTableItems(items);
    }
  }, [items]);

  useEffect(() => {
    if (service === 'event') {
      const usersIds: string[] = [];

      items.forEach((item: any) => {
        usersIds.push(item.createdBy);
      });

      if (usersIds.length) {
        dispatch(actionGetCreatorsList(usersIds));
      }
    }
  }, [items, service]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const filter: FilterProps = {
        ...filters,
        page: 1,
      };

      if (sortModel[0]?.field) {
        filter.orderBy = sortModel[0].field === 'venueName' ? sortModel[0]['venueId'] : sortModel[0].field;
        filter.orderDirection = sortModel[0].sort?.toUpperCase() || 'ASC';
      } else {
        delete filter.orderBy;
        delete filter.orderDirection;
      }
      setFilters(filter);
    },
    [filters],
  );

  const gridSettings = (): any => {
    return {
      hideFooter: true,
      disableSelectionOnClick: true,
      disableVirtualization: true,
      rowsPerPageOptions: [limit],
    };
  };

  if (!items) return null;

  const gridColumns = columns()[`${service}`];

  return (
    <ErrorLoading
      isError={!(items.length >= 0) && isError}
      isLoading={false}
      className={styles.tableBody__errorloading}
    >
      <Box className={styles.tableBody}>
        <DataGrid
          {...gridSettings()}
          rows={items}
          columns={gridColumns}
          pageSize={limit}
          classes={{ root: styles.tableBody }}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          loading={isLoading}
        />
      </Box>
    </ErrorLoading>
  );
};
