import { Button as MuiButton, ButtonProps as MuiButtonProps, Typography } from '@mui/material';
import { Spinner } from '../Spinner';

import styles from './Button.module.scss';

interface ButtonProps extends MuiButtonProps {
  contained?: boolean;
  loading?: boolean;
}

export const Button = (props: ButtonProps): JSX.Element => {
  const { onClick, children, contained, loading, disabled, className, ...rest } = props;

  const classes = {
    button: contained ? styles.button__contained : styles.button,
    loading: loading ? styles.button__loading : '',
  };

  return (
    <MuiButton className={`${classes.button} ${classes.loading} ${className}`} disabled={loading || disabled} onClick={onClick} {...rest}>
      {loading && <Spinner size={20} className={styles.button__spinner} />}
      <Typography fontSize={16} fontWeight={700}>
        {children}
      </Typography>
    </MuiButton>
  );
};
