import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import { Spinner } from '../Spinner';
import { Error } from '../Error';

import styles from './ErrorLoading.module.scss';

interface ErrorLoadingProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isLoading: boolean;
  isError: boolean;
  children: ReactNode;
}

export const ErrorLoading = ({ isLoading, isError, children, className, ...props }: ErrorLoadingProps): JSX.Element => {
  return (
    <div className={cn(styles.errorLoading, className)} {...props}>
      <>{isLoading ? <Spinner /> : isError ? <Error /> : <>{children}</>}</>
    </div>
  );
};
