import { editFieldsFromData } from '@/core/components/Edit/settings';

export enum ActionTypes {
  LOADING = '@instance/LOADING',
  LOADED = '@instance/LOADED',
  ERROR = '@instance/ERROR',
  SET_INSTANCE_LIST = '@instance/SET_INSTANCE_LIST',
  SET_INSTANCE_EDIT_ITEM = '@instance/SET_INSTANCE_EDIT_ITEM',
}

export interface InstanceList {
  itemsList: any;
  editedItem: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
    }
    | {
      type: ActionTypes.LOADED;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_INSTANCE_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.SET_INSTANCE_EDIT_ITEM;
      payload: any;
    };

const instanceState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: instanceState,
  editedItem: editFieldsFromData.instance,
  isLoading: false,
  isError: false,
};

export const instanceReducer = (state: InstanceList = initialState, action: Action): InstanceList => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.LOADED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }

    case ActionTypes.SET_INSTANCE_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isLoading: false,
        isError: false,
      };
    }

    case ActionTypes.SET_INSTANCE_EDIT_ITEM: {
      return {
        ...state,
        editedItem: action.payload,
        isLoading: false,
        isError: false,
      };
    }

    default:
      return state;
  }
};
