import {DetailedHTMLProps, HTMLAttributes, MouseEvent, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { Menu, MenuItem, IconButton, Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { actionMintVenueItem, actionVenueList, deleteVenue, actionMintLocationsItem } from '@/modules/VenuesPage/actions';
import { actionLockPoolItem } from '@/modules/PoolPage/actions';
import { actionEventList, deleteEvent } from '@/modules/EventsPage/actions';
import { actionAvatarList, deleteAvatar } from '@/modules/AvatarsPage/actions';
import { actionCustomAvatarList, deleteCustomAvatar } from '@/modules/CustomAvatarsPage/actions';
import { actionInstanceList, deleteInstance } from '@/modules/InstancePage/actions';
import { serviceNameChecker } from '@/core/utils/serviceNameChecker';
import { Button } from '@/shared/components/Button';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import styles from './RowActions.module.scss';
import useConnectWallet from '@/core/hooks/useConnectWallet';

interface RowActionsProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  itemId: string;
  ownerId?: string;
  isMinted?: boolean;
  isDeleted?: boolean;
  props?: any;
  className?: string;
  pool?: any;
  customAvatar?: any;
}

export const RowActions = ({ itemId, ownerId, isMinted, className, pool, customAvatar, isDeleted }: RowActionsProps): JSX.Element => {
  const dispatch = useDispatch<any>();
  const currentPath = useLocation();
  const moduleName = currentPath.pathname.split('/')[1];
  const service = serviceNameChecker(moduleName);
  const { isConnectedWallet, handleWalletPopup } = useConnectWallet();
  const [ isOwner, setIsOwner ] = useState(false);
  const data = useSelector(({ authReducer }: any) => authReducer);

  useEffect(() => {
    if (data.user) {
      setIsOwner(data.user.id === ownerId);
    }
  }, [data.user]);

  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const deleteAction = async () => {
    switch (service) {
      case 'venue':
        await deleteVenue(itemId);
        await dispatch(actionVenueList() as any);
        break;
      case 'event':
        await deleteEvent(itemId);
        await dispatch(actionEventList() as any);
        break;
      case 'instance':
        await deleteInstance(itemId);
        await dispatch(actionInstanceList() as any);
        break;
      case 'avatar':
        await deleteAvatar(itemId);
        await dispatch(actionAvatarList() as any);
        break;
      case 'customAvatar':
        await deleteCustomAvatar(itemId);
        await dispatch(actionCustomAvatarList() as any);
        break;
    }

    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openConfirmModal = () => setIsOpenedModal(true);

  const mintVenueHandler = useCallback(async () => {
    if (!isConnectedWallet) {
      await handleWalletPopup();
    } else {
      dispatch(actionMintVenueItem(itemId));
    }
  }, [isConnectedWallet, handleWalletPopup]);

  const mintLocationsHandler = useCallback(async () => {
    if (!isConnectedWallet) {
      await handleWalletPopup();
    } else {
      dispatch(actionMintLocationsItem(itemId));
    }
  }, [isConnectedWallet, handleWalletPopup, itemId]);

  const lockPoolHandler = useCallback(async (toLock: boolean) => {
    if (!isConnectedWallet) {
      await handleWalletPopup();
    } else {
      dispatch(actionLockPoolItem(pool.address, toLock));
    }
  }, [isConnectedWallet, handleWalletPopup, pool]);

  const handleCloseModal = () => {
    setIsOpenedModal(false);
    handleClose();
  };

  const viewLinkByService = (service: string) => {
    const viewLink = {
      venue: `${process.env.REACT_APP_PUBLIC_MARKETPLACE_DASHBOARD}/venues/${itemId}`,
      event: `${process.env.REACT_APP_PUBLIC_USER_DASHBOARD}/vrjam/events/${itemId}`,
      proposal: `${process.env.REACT_APP_PUBLIC_USER_DASHBOARD}/vrjam/proposals/${itemId}`,
      pool: `${process.env.REACT_APP_PUBLIC_USER_DASHBOARD}/vrjam/pool/${itemId}`,
    };
    return viewLink[service];
  };

  return (
    <div className={cn(styles.tableActions, className)}>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon className={styles.tableActions__icon_btn} />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        {service === 'customAvatar' && customAvatar?.type === 'CUSTOM' && (
          <MenuItem disabled={!!customAvatar.avatarId}>
            <Link
              to={`/customAvatars/${itemId}/assignAvatar`}
              className={styles.tableActions__link}
            >
              <PersonAddIcon className={styles.tableActions__icon}/>
              Assign
            </Link>
          </MenuItem>
        )}
        {!isMinted && (
          <div>
            <MenuItem className={styles.tableActions__type}>
              <Link
                to={`/${moduleName}/${itemId}`}
                className={styles.tableActions__link}
              >
                <EditIcon className={styles.tableActions__icon} />
                Edit
              </Link>
            </MenuItem>
            {service === 'venue' && (
              <MenuItem
                className={styles.tableActions__type}
                onClick={mintVenueHandler}
              >
                <MonetizationOnIcon className={styles.tableActions__icon} />
                Mint
              </MenuItem>
            )}
            {service === 'event' && (
              <MenuItem className={styles.tableActions__type}>
                <Link
                  to={`/${moduleName}/${itemId}/eventRoleToUser`}
                  className={styles.tableActions__link}
                >
                  <FaceRetouchingNaturalIcon className={styles.tableActions__icon} />
                  Roles
                </Link>
              </MenuItem>
            )}
            {service !== 'venue' && (
              <MenuItem
                className={styles.tableActions__type}
                onClick={openConfirmModal}
              >
                <DeleteIcon className={styles.tableActions__icon} />
                Delete
              </MenuItem>
            )}
          </div>
        )}

        {service === 'venue' && (
          <div>
            {!!pool && (
              <MenuItem
                className={styles.tableActions__type}
                onClick={mintLocationsHandler}
                disabled={!isMinted}
              >
                <MonetizationOnIcon className={styles.tableActions__icon} />
                Mint locations
              </MenuItem>
            )}
            {isMinted && isOwner && (
              <MenuItem className={styles.tableActions__type}>
                <Link
                  to={`/${moduleName}/${itemId}/venueOwner`}
                  className={styles.tableActions__link}
                >
                  <FaceRetouchingNaturalIcon className={styles.tableActions__icon} />
                  Set owner
                </Link>
              </MenuItem>
            )}
            <MenuItem
              className={styles.tableActions__type}
              onClick={openConfirmModal}
              disabled={!!isDeleted}
            >
              <DeleteIcon className={styles.tableActions__icon} />
              Delete
            </MenuItem>
          </div>
        )}
        {viewLinkByService(service) && (
          <MenuItem className={styles.tableActions__type}>
            <a
              href={viewLinkByService(service)}
              target="_blank"
              className={styles.tableActions__link}
              rel="noreferrer"
            >
              <VisibilityIcon className={styles.tableActions__icon} />
              View
            </a>
          </MenuItem>
        )}
      </Menu>
      {isOpenedModal && (
        <Modal
          open={isOpenedModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-delete-item"
          aria-describedby="modal-modal-description"
        >
          <Box className={styles.tableActions__modal}>
            <Typography
              className={styles.tableActions__text}
              id="modal-delete-item"
              variant="h6"
              component="h2"
            >
              Are you sure?
            </Typography>
            <Box className={styles.tableActions__buttons}>
              <Button
                className={styles.tableActions__btn}
                onClick={handleCloseModal}
              >
                No
              </Button>
              <Button
                className={styles.tableActions__btn}
                onClick={deleteAction}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};
