import { ReactComponent as SpinnerViewerIcon } from '@/resources/icons/spinner-viewer.svg';

import styles from './SpinnerViewer.module.scss';

interface SpinnerViewerProps {
  className?: string;
  size?: number;
}

export const SpinnerViewer = ({ size = 90, className, ...props }: SpinnerViewerProps): JSX.Element => {
  return (
    <div
      className={`${styles.spinner} ${className}`}
      {...props}
    >
      <SpinnerViewerIcon
        width={size}
        height={size}
      />
    </div>
  );
};
