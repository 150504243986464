import { editFieldsFromData } from '@/core/components/Edit/settings';

export enum ActionTypes {
  LOADING = '@proposal/LOADING',
  LOADED = '@proposal/LOADED',
  ERROR = '@proposal/ERROR',
  SET_PROPOSAL_LIST = '@proposal/SET_PROPOSAL_LIST',
  SET_PROPOSAL_EDIT_ITEM = '@proposal/SET_PROPOSAL_EDIT_ITEM',
}

export interface ProposalList {
  itemsList: any;
  editedItem: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.LOADED;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_PROPOSAL_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.SET_PROPOSAL_EDIT_ITEM;
      payload: any;
    };

const proposalState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: proposalState,
  editedItem: editFieldsFromData.proposal,
  isLoading: false,
  isError: false,
};

export const proposalReducer = (state: ProposalList = initialState, action: Action): ProposalList => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ActionTypes.LOADED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_PROPOSAL_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_PROPOSAL_EDIT_ITEM: {
      return {
        ...state,
        editedItem: action.payload,
        isError: false,
      };
    }

    default:
      return state;
  }
};
