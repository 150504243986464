export enum ActionTypes {
  LOADING = '@avatar/LOADING',
  ERROR = '@avatar/ERROR',
  SET_CUSTOM_AVATAR_LIST = '@avatar/SET_CUSTOM_AVATAR_LIST',
  DELETE_CUSTOM_AVATAR = '@avatar/DELETE_CUSTOM_AVATAR',
  SET_CUSTOM_AVATAR_EDIT_ITEM = '@avatar/SET_CUSTOM_AVATAR_EDIT_ITEM',
}

export interface AvatarStateProps {
  itemsList: any;
  editedItem: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_CUSTOM_AVATAR_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.DELETE_CUSTOM_AVATAR;
      payload: any;
    }
  | {
      type: ActionTypes.SET_CUSTOM_AVATAR_EDIT_ITEM;
      payload: any;
    };

const customAvatarListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: customAvatarListState,
  editedItem: null,
  isLoading: false,
  isError: false,
};

export const customAvatarReducer = (state: AvatarStateProps = initialState, action: Action): AvatarStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_CUSTOM_AVATAR_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_CUSTOM_AVATAR_EDIT_ITEM: {
      return {
        ...state,
        editedItem: action.payload,
        isError: false,
      };
    }

    default:
      return state;
  }
};
