import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetVenueItemById } from '@/modules/VenuesPage/actions';
import { actionGetEventItemById } from '@/modules/EventsPage/actions';
import { actionGetUserById } from '@/modules/UsersPage/actions';
import { actionGetInstanceItemById } from '@/modules/InstancePage/actions';
import { actionGetAvatarItemById } from '@/modules/AvatarsPage/actions';
import { actionGetEvenRoleById } from '@/modules/EventRolesPage/actions';
import { actionGetCustomAvatarItemById } from '@/modules/CustomAvatarsPage/actions';
import { actionGetCreatorById } from '@/modules/CreatorsPage/actions';
import { actionGetProposalItemById } from '@/modules/ProposalPage/actions';
import { actionGetPoolItemById } from '@/modules/PoolPage/actions';
import { actionGetAssistantItemById } from '@/modules/AssistantsPage/actions';

const useGetServiceItemById = (service: string, id: string, domainFolder = '') => {
  const dispatch = useDispatch<any>();

  const reducersMapping = {
    venue: useSelector(({ venueReducer }: any) => venueReducer),
    venueOwner: useSelector(({ venueReducer }: any) => venueReducer),
    event: useSelector(({ eventReducer }: any) => eventReducer),
    eventRoleToUser: useSelector(({ eventReducer }: any) => eventReducer),
    eventRole: useSelector(({ eventRolesReducer }: any) => eventRolesReducer),
    user: useSelector(({ userReducer }: any) => userReducer),
    creator: useSelector(({ creatorsReducer }: any) => creatorsReducer),
    instance: useSelector(({ instanceReducer }: any) => instanceReducer),
    avatar: useSelector(({ avatarReducer }: any) => avatarReducer),
    customAvatar: useSelector(({ customAvatarReducer }: any) => customAvatarReducer),
    assistant: useSelector(({ assistantReducer }: any) => assistantReducer),
    interactions: useSelector(({ assistantReducer }: any) => assistantReducer),
    proposal: useSelector(({ proposalReducer }: any) => proposalReducer),
    pool: useSelector(({ poolReducer }: any) => poolReducer),
    assignAvatar: useSelector(({ customAvatarReducer }: any) => customAvatarReducer),
  };

  const actionsMapping = {
    venue: () => actionGetVenueItemById(id),
    venueOwner: () => actionGetVenueItemById(id),
    event: () => actionGetEventItemById(id),
    eventRoleToUser: () => actionGetEventItemById(id),
    eventRole: () => actionGetEvenRoleById(id),
    user: () => actionGetUserById(id),
    creator: () => actionGetCreatorById(id),
    instance: () => actionGetInstanceItemById(id),
    avatar: () => actionGetAvatarItemById(id),
    customAvatar: () => actionGetCustomAvatarItemById(id),
    assistant: () => actionGetAssistantItemById(id),
    interactions: () => actionGetAssistantItemById(id),
    proposal: () => actionGetProposalItemById(id),
    pool: () => actionGetPoolItemById(id),
    assignAvatar: () => actionGetCustomAvatarItemById(id),
  };

  useEffect(() => {
    dispatch(actionsMapping[domainFolder || service]());
  }, [service, domainFolder]);

  return reducersMapping[domainFolder || service];
};

export default useGetServiceItemById;
