import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer } from '@mui/material';

import { Button } from '@/shared/components/Button';
import { mainNavigation } from '@/data/mainNavigation';
import { paths } from '@/data/paths';
import { useTypedSelector } from '@/core/hooks/useTypedSelector';
import { isAdmin } from '@/core/utils/userRoles';
import { actionNativeLogout } from '@/modules/Auth/actions';
import { Account } from '../Account';

import { ReactComponent as MobileMenuIcon } from '@/resources/icons/mobile-menu-icon.svg';
import { ReactComponent as CloseModalIcon } from '@/resources/icons/close-modal-icon.svg';
import { ReactComponent as ArrowSvg } from '@/resources/icons/arrow.svg';

import styles from './MobileMenu.module.scss';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const MobileMenu = ({ open, setOpen }: Props) => {
  const anchor = 'right';
  const { user, isLoading } = useTypedSelector(({ authReducer }) => authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, [setOpen]);

  const toggleDrawer = (anchor: Anchor, opened: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(opened);
  };

  const handleSignInClick = () => {
    setOpen(false);
    window.location.replace(paths.realityjam.login);
  };

  const getSectionSelectedClass = (options: { label: string; path: string; }[]): string => {
    const current = options.find((item: any) => item.path === location.pathname);
    if (current) return styles.selected;
    return '';
  };

  const getSelectedClass = (link: string) => (link === location.pathname ? styles.selected : '');

  const handleClickLink = (link: string) => () => {
    setOpen(false);
    if (link.includes('http')) {
      window.location.replace(link);
    } else {
      navigate(link);
    }
  };

  const handleLogoutClick = () => dispatch(actionNativeLogout() as any);

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={toggleDrawer(anchor, false)}
    >
      <Box className={styles.mobile_menu}>
        <Box className={styles.mobile_menu__header}>
          <Box className={styles.mobile_menu__header__menu}>
            <span>Menu</span>
            <MobileMenuIcon />
          </Box>
          <Box
            className={styles.mobile_menu__header__close}
            onClick={toggleDrawer(anchor, false)}
          >
            <CloseModalIcon />
          </Box>
        </Box>
        <nav className={styles.mobile_menu__navs}>
          {mainNavigation.dropdowns.map((item, index) => (
            <Accordion
              key={index}
              title={item.label}
              className={styles.mobile_menu__nav}
            >
              <AccordionSummary
                className={`${styles.mobile_menu__nav__section} ${getSectionSelectedClass(item.options)}`}
                expandIcon={<ArrowSvg className={styles.mobile_menu__nav__arrow} />}
              >
                {item.label}
              </AccordionSummary>
              <AccordionDetails>
                {item.options.map((item, index) => (
                  <Box
                    key={index}
                    onClick={handleClickLink(item.path)}
                    className={`${styles.mobile_menu__nav__section__link} ${getSelectedClass(item.path)}`}
                  >
                    {item.label}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </nav>

        {user ? (
          <>
            <Account showName />
            <Button
              className={styles.mobile_menu__logout}
              onClick={handleLogoutClick}
            >
              Logout
            </Button>
          </>
        ) : (
          <Button onClick={handleSignInClick}>Sign in</Button>
        )}

        {!user && <Button onClick={handleSignInClick}>Sign in</Button>}
      </Box>
    </Drawer>
  );
};
