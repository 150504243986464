import { FieldInputProps, FormikProps } from 'formik';
import { MenuItem, Select, SelectChangeEvent, FormLabel } from '@mui/material';
import { Box } from '@mui/system';

import useGetFilteredDataFromService from '@/core/hooks/useGetFilteredDataFromService';
import { PoolLifecycleStatus } from '@/data/constants';

import styles from './CustomSelectPool.module.scss';

export interface OptionsProps {
  id: string;
  name: string;
  proposal?: any;
}

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
}

export const CustomSelectPool = (props: InputProps): JSX.Element => {
  const { form, field, label, onChange, required } = props;

  const customSelectPoolFilter = {
    page: 1,
    limit: 50,
    lifecycleStatus: PoolLifecycleStatus.SUCCEED,
    isVenue: false,
  };

  const { serviceData } = useGetFilteredDataFromService('pool', customSelectPoolFilter);
  const { itemsList } = serviceData;

  const options: OptionsProps[] = itemsList?.items || [];

  const onChangeHandler = (event: SelectChangeEvent<any>) => {
    field.onChange(event);
    onChange && onChange(event);
    if (form.values['faction'] !== undefined || form.values['location'] !== undefined) {
      const search = options.find((item: any) => item.id === event.target.value);
      if (search) {
        if (form.values['faction'] !== undefined) {
          form.setFieldValue('faction', search.proposal?.faction);
        }
        if (form.values['location'] !== undefined) {
          form.setFieldValue('location', search.proposal?.location);
        }
      }
    }
    form.setFieldTouched(field.name, true, false);
  };

  return (
    <Box className={styles.select}>
      <FormLabel className={styles.select__label}>
        {label}
        {required && <span> *</span>}
      </FormLabel>
      <Select
        name={field.name}
        value={field.value}
        onChange={onChangeHandler}
        MenuProps={{ classes: { paper: styles.menuPaper } }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item?.proposal?.name}
          </MenuItem>
        ))}
      </Select>
      {form.touched[field.name] && Boolean(form.errors[field.name]) && (
        <div className={styles.select__error}>{form.errors[field.name]}</div>
      )}
    </Box>
  );
};
