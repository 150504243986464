import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as web3lib from '@vrjam/vrjam-web3-lib';

import MetamaskImg from '@/resources/images/metamask.png';
import VenlyImg from '@/resources/images/venly.png';
import useConnectWallet from '@/core/hooks/useConnectWallet';
import { Modal } from '@/shared/components/Modal';

import styles from './WalletChoosePopup.module.scss';

export function WalletChoosePopup() {
  const dispatch = useDispatch<any>();
  const { isOpenWalletPopup, setIsOpenWalletPopup, connectWalletHandler, checkConnectorHandler } = useConnectWallet();
  const handleClose = () => dispatch(setIsOpenWalletPopup(false));

  useEffect(() => {
    checkConnectorHandler();
    (window as any)?.ethereum?.on('accountsChanged', checkConnectorHandler);
  }, [checkConnectorHandler]);

  return (
    <Modal open={isOpenWalletPopup} onClose={handleClose} title='Choose wallet'>
      <div className={styles.walletPopup}>
        <div className={styles.walletPopup__description}>
          You can connect only one wallet to one account.<br/>
          <span> Please be careful connecting the wallet as it's a non-reversible action</span>
        </div>
        <div className={styles.walletPopup__cards}>
          <div
            className={styles.walletPopup__card}
            onClick={connectWalletHandler(web3lib.connectors.MetaMask)}
          >
            <div className={styles.walletPopup__card__inner}>
              <img alt='metamask' src={MetamaskImg}/>
            </div>
          </div>
          <div
            className={styles.walletPopup__card}
            onClick={connectWalletHandler(web3lib.connectors.Venly)}
          >
            <div className={styles.walletPopup__card__inner}>
              <div className={styles.walletPopup__card__inner__img}>
                <img alt='venly' src={VenlyImg}/>
              </div>
              {' '}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
