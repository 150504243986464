import { ChangeEvent, DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Box, Input, InputAdornment } from '@mui/material';
import cn from 'classnames';

import { ReactComponent as SearchSvg } from '@/resources/icons/search.svg';
import { ReactComponent as CloseSvg } from '@/resources/icons/close-modal-icon.svg';

import styles from './SectionSearchUser.module.scss';

interface SearchUserProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
  searchHandle?: any;
  searchValue?: string;
  changeHandler?: (value: string) => void;
}

export const SectionSearchUser = ({
  className,
  searchValue,
  changeHandler = () => null,
}: SearchUserProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    changeHandler(event.target.value);
  };

  const onClear = () => {
    changeHandler('');
  };

  return (
    <Box
      className={cn(styles.search, className, {
        [styles.search_focused]: focused,
      })}
    >
      <Input
        ref={anchorRef}
        value={searchValue}
        onChange={inputChangeHandler}
        inputRef={inputRef}
        className={styles.search__field}
        disableUnderline
        placeholder="Search"
        startAdornment={
          <InputAdornment
            position="start"
            variant="filled"
            className={styles.search__field_searchIcon}
          >
            <SearchSvg />
          </InputAdornment>
        }
        endAdornment={
          <>
            {searchValue && (
              <InputAdornment
                position="end"
                onClick={onClear}
                className={styles.search__field_closeIcon}
              >
                <CloseSvg />
              </InputAdornment>
            )}
          </>
        }
      />
    </Box>
  );
};
