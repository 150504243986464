import { Box } from '@mui/material';

import { ReactComponent as SpinnerIcon } from '@/resources/icons/spinner.svg';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  className?: string;
  size?: number;
}

export const Spinner = ({ size = 90, className, ...props }: SpinnerProps): JSX.Element => {
  return (
    <div
      className={`${styles.spinner} ${className}`}
      {...props}
    >
      <SpinnerIcon
        width={size}
        height={size}
      />
    </div>
  );
};
