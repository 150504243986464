import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { ActionTypes } from '../reducers';
import { eventApi } from '@/api';
import { userApi } from '@/api/user';

export const setLoading = (loading: boolean) => ({ type: ActionTypes.LOADING, payload: loading });

export const actionSetEventList = (data: any) => ({ type: ActionTypes.SET_EVENT_LIST, payload: data });

export const actionSetEventEditedItem = (data: any) => ({ type: ActionTypes.SET_EVENT_EDIT_ITEM, payload: data });

export const actionCreatorsLists = (data: any) => ({ type: ActionTypes.SET_EVENT_CREATORS_LIST, payload: data });

export const actionEventList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await eventApi.getEvent(filters);

      if (response.data.success) {
        dispatch(actionSetEventList(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateEventItem =
  (id: string, body: any, isShowNotification = true) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const response = await eventApi.updateEventItem(id, body, config);

      if (response.data.success) {
        isShowNotification && notify.success('Event item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteEvent = async (id: string): Promise<void> => {
  try {
    const response = await eventApi.deleteEventItem(id);

    if (response.status === 200) {
      notify.success('Event item deleted successfully');
    } else {
      const error = response?.data?.message || response.message;

      throw error;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  }
};

export const actionGetEventItemById =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await eventApi.getEventItemByid(body);

      if (response.data.success) {
        dispatch(actionSetEventEditedItem(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionCreateEventItem =
  (body: any, value: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response = await eventApi.createEventItem(body, config);

      if (response.data.success) {
        if (value.adminStatus === 'APPROVED') {
          await dispatch(actionUpdateEventItem(response.data.data.id, body, false));
        }
        notify.success('Event item created successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetCreatorsList =
  (userIds: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.getUsersByIds(userIds);

      if (response.data.success) {
        dispatch(actionCreatorsLists(response.data.data));
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionSetEventRole =
  (params: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await eventApi.setEventRoleToUser(params);

      if (!response.data.success) {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };
