import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FieldInputProps, FormikProps } from 'formik';
import { MenuItem, Select, SelectChangeEvent, FormLabel } from '@mui/material';
import { Box } from '@mui/system';
import useGetServiceData from '@/core/hooks/useGetServiceData';
import { actionGetUserRolesList } from '@/modules/UsersPage/actions';

import styles from './CustomSelectRole.module.scss';

export interface OptionsProps {
  label: string;
  value: string;
}

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
}

export const CustomSelectRole = (props: InputProps): JSX.Element => {
  const { form, field, label, onChange, required } = props;
  const dispatch = useDispatch();
  const { rolesList } = useGetServiceData('user');

  const [rolesOption, setRolesOption] = useState<OptionsProps[] | []>([]);

  useEffect(() => {
    dispatch(actionGetUserRolesList() as any);
  }, []);

  useEffect(() => {
    const rolesArray = rolesList?.items;

    const roles = rolesArray?.map((item: any) => {
      const newLabel = item.name.replace('_', ' ');

      return { label: newLabel, value: item.id };
    });

    setRolesOption(roles);
  }, [rolesList]);

  const onChangeHandler = (event: SelectChangeEvent<any>) => {
    field.onChange(event);
    onChange && onChange(event);
    form.setFieldTouched(field.name, true, false);
  };

  return (
    <Box className={styles.select}>
      <FormLabel className={styles.select__label}>
        {label}
        {required && <span> *</span>}
      </FormLabel>
      <Select
        name={field.name}
        value={field.value}
        onChange={onChangeHandler}
      >
        {rolesOption?.map((item: any) => (
          <MenuItem
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {form.touched[field.name] && Boolean(form.errors[field.name]) && (
        <div className={styles.select__error}>{form.errors[field.name]}</div>
      )}
    </Box>
  );
};
