import { Link, Typography } from '@mui/material';
import { Wrapper } from '@/core/components/Wrapper';

import LogoImage from '@/resources/images/logo.png';

import styles from './Footer.module.scss';

export const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <Wrapper>
        <div className={styles.footer__head}>
          <Link href="/">
            <img
              src={LogoImage}
              className={styles.footer__head__logo}
              alt="Logo"
            />
          </Link>
        </div>
        <div className={`${styles.footer__line} ${styles.footer__line_top}`} />
        <div className={`${styles.footer__line} ${styles.footer__line_bottom}`} />
        <Typography className={styles.footer__copyrights}>© {currentYear} All rights reserved</Typography>
      </Wrapper>
    </footer>
  );
};
