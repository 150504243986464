import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Field, Form, Formik, FormikValues } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { serviceNameChecker } from '@/core/utils/serviceNameChecker';
import { correctServiceName } from '@/core/utils/correctServiceName';
import { validationOfEditService } from '@/core/utils/validationOfService';
import useGetServiceItemById from '@/core/hooks/useGetServiceItemById';
import { editFieldsFromData } from '@/core/components/Edit/settings';
import { normalizeFileUrl } from '@/core/utils/fileStorageHandler';
import { ErrorLoading } from '@/shared/components/ErrorLoading';
import { CustomInput } from '@/shared/components/CustomInput';
import { CustomSelect } from '@/shared/components/CustomSelect';
import { Button } from '@/shared/components/Button';
import { CustomFileUploader } from '@/shared/components/CustomFileUploader';
import { activatedOptions, knowledgeBaseRequestStatusOptions, voiceOptions } from '@/data/constants';
import { actionUpdateAssistantItem, actionUpdateKnowledgeBaseItem } from '@/modules/AssistantsPage/actions';

import styles from './EditAssistant.module.scss';

export const EditAssistant = (): JSX.Element | null => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const currentPath = useLocation();
  const [moduleName, setModuleName] = useState(currentPath.pathname.split('/')[1]);
  const [getItemId, setGetItemId] = useState(currentPath.pathname.split('/')[2]);
  const [domainFolder, setDomainFolder] = useState(currentPath.pathname.split('/')[3]);
  const [serviceName, setServiceName] = useState<string>(serviceNameChecker(moduleName));

  useEffect(() => {
    const moduleName = currentPath.pathname.split('/')[1];
    const itemId = currentPath.pathname.split('/')[2];
    const domain = currentPath.pathname.split('/')[3];
    const service = serviceNameChecker(moduleName);

    setServiceName(service);
    setModuleName(moduleName);
    setGetItemId(itemId);
    setDomainFolder(domain);
  }, [currentPath.pathname]);

  const serviceAllData = useGetServiceItemById(serviceName, getItemId, domainFolder);
  const { editedItem, isLoading, isError } = serviceAllData;

  const validationSettings = validationOfEditService(serviceName, domainFolder);
  const validationParams = yup.object().shape({ ...validationSettings });

  const createFormData = (values: FormikValues) => {
    const bodyFormData = new FormData();

    bodyFormData.append('id', editedItem.id);
    for (const [key, value] of Object.entries(values)) {
      if (
        value !== editedItem[key] &&
        key !== 'webglFile'
      ) {
        bodyFormData.append(key, value);
      }
    }

    values.webglFile && bodyFormData.append('webglFile', values.webglFile[0]);
    return bodyFormData;
  };

  const submitHandler = useCallback(async (values: FormikValues) => {
    const updatedData = {...values};

    setIsLoadingUpdate(true);
    try {
      let isUpdated;
      if (updatedData.knowledgeBase.status === 'PREPARED') {
        isUpdated = await dispatch(actionUpdateKnowledgeBaseItem({
          id: editedItem.knowledgeBase.id,
          status: 'IN_PROGRESS',
        }));
      } else {
        const bodyFormData = createFormData(updatedData);
        isUpdated = await dispatch(actionUpdateAssistantItem(bodyFormData));
      }
      if (isUpdated) {
        navigate(`/${ moduleName }`);
      }

      setIsLoadingUpdate(false);
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsLoadingUpdate(false);
    }
  }, [dispatch, domainFolder, serviceName, editedItem]);

  const downloadGlbHandler = () => {
    window.location.href = normalizeFileUrl(editedItem.avatar.glbLocation);
  }

  const downloadKnowledgeHandler = () => {
    window.location.href = normalizeFileUrl(editedItem.knowledgeBase.knowledgeLocation);
  }

  const downloadBackgroundHandler = () => {
    window.location.href = normalizeFileUrl(editedItem.backgroundLocation);
  }

  return (
    <div className={styles.edit}>
      <h2 className={styles.edit__title}>
        Manage Assistant
      </h2>
      <ErrorLoading
        isLoading={ !editedItem }
        isError={ isError }
      >
        <Formik
          enableReinitialize
          initialValues={{
            ...editFieldsFromData[`${domainFolder || serviceName}`],
            ...editedItem,
          }}
          validationSchema={editedItem?.knowledgeBase.status !== 'PREPARED' && validationParams}
          onSubmit={submitHandler}
        >
          <Form className={ styles.edit__form }>
            <div className={ styles.edit__row }>
              <Field
                name={ 'id' }
                label={ 'Assistant ID' }
                component={ CustomInput }
                disabled={ true }
              />
            </div>
            <div className={ styles.edit__row }>
              <Field
                name={ 'knowledgeBase.status' }
                label={ 'Status' }
                component={ CustomSelect }
                disabled={ true }
                options={ knowledgeBaseRequestStatusOptions }
              />
            </div>
            <div className={ styles.edit__row }>
              <Field
                name={ 'isActive' }
                label={ 'Is active Assistant' }
                component={ CustomSelect }
                disabled={ true }
                options={ activatedOptions }
              />
            </div>
            <div className={ styles.edit__row }>
              <Field
                name={ 'voiceStyle' }
                label={ 'Voice Style' }
                component={ CustomSelect }
                disabled={ true }
                options={ voiceOptions }
              />
            </div>
            <div className={ styles.edit__row }>
              <Field
                name={ 'avatar.name' }
                label={ 'Avatar Name' }
                component={ CustomInput }
                disabled={ true }
              />
            </div>
            <div className={ styles.edit__row }>
              <Field
                name={ 'backstory' }
                label={ 'Avatar\'s Back Story' }
                component={ CustomInput }
                disabled={ true }
                multiline={ true }
                maxRows={ 15 }
              />
            </div>
            <div className={ styles.edit__row }>
              <Button
                className={ styles.edit__btn }
                loading={ !editedItem }
                type="button"
                onClick={ downloadGlbHandler }
              >
                Download Avatar GLB file
              </Button>
            </div>
            { editedItem?.backgroundLocation && (
              <div className={ styles.edit__row }>
                <Button
                  className={ styles.edit__btn }
                  loading={ !editedItem }
                  type="button"
                  onClick={ downloadBackgroundHandler }
                >
                  Download Background image
                </Button>
              </div>
            ) }
            <div className={ styles.edit__row }>
              <Button
                className={ styles.edit__btn }
                loading={ !editedItem }
                type="button"
                onClick={ downloadKnowledgeHandler }
              >
                Download Training Data file
              </Button>
            </div>
            { editedItem?.knowledgeBase.status !== 'PREPARED' && (
              <div className={ styles.edit__row }>
                <Field
                  name={ 'webglFile' }
                  label={ 'WebGL archive ' }
                  component={ CustomFileUploader }
                  required
                />
              </div>
            ) }
            <div className={ styles.edit__row }>
              <Button
                className={ styles.edit__btn }
                loading={ !editedItem || isLoadingUpdate }
                type="submit"
                contained
              >
                { editedItem?.knowledgeBase.status === 'READY' ?
                  'Already trained AI Assistant' :
                  editedItem?.knowledgeBase.status === 'PREPARED' ?
                    'Sent to Convai' : 'Upload WebGL file from Convai service'
                }
              </Button>
            </div>
          </Form>
        </Formik>
      </ErrorLoading>

      <Link
        to={ `/${ moduleName }` }
        className={ styles.edit__link }
        color="inherit"
        type="button"
      >
        Go to { correctServiceName(serviceName) } page
      </Link>
    </div>
  );
};
