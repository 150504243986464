import * as web3lib from '@vrjam/vrjam-web3-lib';
import { venueApi } from '@/api';
import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { ActionTypes } from '../reducers';
import { FilterProps } from '@/core/hooks/useGetVenueList';
import useConnectWallet from '@/core/hooks/useConnectWallet';

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetVenueList = (data: any) => ({
  type: ActionTypes.SET_VENUE_LIST,
  payload: data,
});

export const actionSetVenueEditedItem = (data: any) => ({
  type: ActionTypes.SET_VENUE_EDIT_ITEM,
  payload: data,
});

export const actionVenueItemMinted = (id: string) => ({
  type: ActionTypes.VENUE_ITEM_MINTED,
  payload: id,
});

export const actionVenueList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await venueApi.getVenue(filters);

      if (response.data.success) {
        dispatch(actionSetVenueList(response.data.data));
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateVenueItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const response = await venueApi.updateVenueItem(body, config);

      if (response.data.success) {
        notify.success('Venue item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteVenue = async (id: string): Promise<void> => {
  try {
    const response = await venueApi.deleteVenueItem(id);

    if (response.status === 204) {
      notify.success('Venue item deleted successfully');
    } else {
      const error = response?.data?.message || response.message;

      throw error;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  }
};

export const actionCreateVenueItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response = await venueApi.createVenueItem(body, config);

      if (response.data.success) {
        notify.success('Venue item created successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetVenueItemById =
  (id: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    {
      try {
        dispatch(setLoading(true));
        const response = await venueApi.getVenueItemByid(id);

        if (response.data.success) {
          dispatch(actionSetVenueEditedItem(response.data.data));
        } else {
          dispatch({ type: ActionTypes.ERROR });
          throw response.data.message;
        }
      } catch (e: any) {
        const error = e.response?.data?.message || e.message || e;

        notify.error(error);
        dispatch({ type: ActionTypes.ERROR });
        throw e;
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

export const actionMintVenueItem = (id: string) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const response = await venueApi.mintVenueItem(id);

    if (response.data.success) {
      const erc1155data = response.data.data.erc1155data;
      const mediator = await web3lib.api.mediator();
      await mediator.tokenize(erc1155data);
      dispatch(actionVenueItemMinted(id));
      dispatch(actionVenueList());
      notify.success('Venue minted successfully', 'Success');
    } else {
      throw response.data.message;
    }
  } catch (e: any) {
    if (e.message.includes('user rejected transaction')) {
      notify.error('User rejected transaction');
    } else {
      notify.error(e.message);
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const actionMintLocationsItem = (id: string) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const response = await venueApi.mintLocationsItem(id);

    if (response.data.success) {
      let locationsData = response.data.data.tokens;
      locationsData = locationsData.map((location: any) => location.erc1155data);
      const mediator = await web3lib.api.mediator();
      await mediator.multicall(locationsData);
      dispatch(actionVenueList());
      notify.success('Locations minted successfully', 'Success');
    } else {
      throw response.data.message;
    }
  } catch (e: any) {
    console.log('Error in actionMintLocationsItem', e);
    if (e.message.includes('user rejected transaction')) {
      notify.error('User rejected transaction');
    } else {
      notify.error(e.message);
    }
  } finally {
    dispatch(setLoading(false));
  }
};
