import * as web3lib from '@vrjam/vrjam-web3-lib';
import { web3libSwitchNetwork } from '@/shared/utils/web3libSwitchNetwork';

export const web3libAuth = async (userAddress = '', connector: any) => {
  try {
    if (!connector) {
      connector = web3lib.getConnector();
      if (connector) {
        await web3lib.checkConnector();
      } else {
        throw new Error(`Connector is required`);
      }
    } else {
      await web3lib.useConnector(connector);
    }
    const store = await web3lib.getStore();
    if (userAddress && store?.common.account !== userAddress) {
      await web3lib.disableConnector();
      throw new Error(`Switch to the address that is equal to your VRJAM account address: ${userAddress}`);
    }
    if (store?.common.chainId !== process.env.REACT_APP_DEFAULT_NETWORK_ID) {
      await web3libSwitchNetwork();
    }
    return store;
  } catch (e: any) {
    throw new Error(e.message);
  }
}
