import { useEffect } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import useGetServiceData from '@/core/hooks/useGetServiceData';
import { actionGetUserRolesList } from '@/modules/UsersPage/actions';
import { MultiSelect } from '@/shared/components/MultiSelect';

import styles from './UserRolesMultiSelect.module.scss';

export interface OptionsProps {
  label: string;
  value: string;
}

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  options?: OptionsProps[];
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
}

export const UserRolesMultiSelect = (props: InputProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const serviceData = useGetServiceData('user');
  const { rolesList } = serviceData;

  useEffect(() => {
    dispatch(actionGetUserRolesList() as any);
  }, []);

  if (!rolesList) return null;

  return (
    <MultiSelect
      formOptions={props}
      permissionsList={rolesList.items}
    />
  );
};
