import { Layout } from '@/core/layout';
import { Frame } from '@/shared/components/Frame';
import { Table } from '@/shared/components/Table';

export const ProposalPage = (): JSX.Element => {
  return (
    <Layout backgroundType="none">
      <Frame>
        <h1>Proposals</h1>
        <Table />
      </Frame>
    </Layout>
  );
};
