import { Box, IconButton, Typography } from '@mui/material';

import { normalizeFileUrl } from '@/core/utils/fileStorageHandler';

import { ReactComponent as PlusIcon } from '@/resources/icons/plus.svg';
import { ReactComponent as CloseIcon } from '@/resources/icons/close-modal-icon.svg';

import styles from './Gallery.module.scss';

export const Gallery = (props: any): JSX.Element => {
  const { form, field, helpers, label, options, onChange, required } = props;

  const removePhotoHandler = (file: any, index: number) => () => {
    const files = [...field.value];
    files.splice(index, 1);

    form.values.gallery = files;
    form.values.removeGalleryFiles.push(file.uuid);
    onChange && onChange(field.value);
    form.setFieldTouched(field.name, true, false);
  };

  const onChangeHandler = (value: any) => {
    form.values.gallery.push(value);

    onChange && onChange(value);
    form.setFieldTouched(field.name, true, false);
  };

  return (
    <Box className={styles.gallery}>
      <Typography
        variant="h4"
        className={styles.gallery__title}
      />
      <div className={styles.gallery__grid}>
        {field.value.map((image: any, index: number) => {
          return (
            <div
              key={index}
              className={styles.gallery__grid__item}
            >
              <IconButton
                onClick={removePhotoHandler(image, index)}
                className={styles.gallery__grid__item_remove}
              >
                <CloseIcon />
              </IconButton>
              <img
                alt="img"
                src={image.id ? normalizeFileUrl(image) : URL.createObjectURL(image)}
              />
            </div>
          );
        })}
        <label className={styles.gallery__label}>
          <span className={styles.gallery__add}>
            <PlusIcon className={styles.gallery__add_icon} />
          </span>
          Add photo
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={({ currentTarget }: any) => {
              const file = currentTarget.files[0];
              const reader = new FileReader();

              if (file) {
                reader.onloadend = () => {
                  onChangeHandler(file);
                };
                reader.readAsBinaryString(file);
              }
            }}
          />
        </label>
      </div>
    </Box>
  );
};
