import { useSelector } from 'react-redux';

const useGetServiceData = (serviceName: string) => {
  const reducersMapping = {
    venue: useSelector(({ venueReducer }: any) => venueReducer),
    event: useSelector(({ eventReducer }: any) => eventReducer),
    eventRole: useSelector(({ eventRolesReducer }: any) => eventRolesReducer),
    user: useSelector(({ userReducer }: any) => userReducer),
    creator: useSelector(({ creatorsReducer }: any) => creatorsReducer),
    instance: useSelector(({ instanceReducer }: any) => instanceReducer),
    avatar: useSelector(({ avatarReducer }: any) => avatarReducer),
    customAvatar: useSelector(({ customAvatarReducer }: any) => customAvatarReducer),
    assistant: useSelector(({ assistantReducer }: any) => assistantReducer),
    proposal: useSelector(({ proposalReducer }: any) => proposalReducer),
  };

  return reducersMapping[serviceName];
};

export default useGetServiceData;
