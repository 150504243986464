import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import styles from './CustomDatePicker.module.scss';

interface ICustomDatePicker {
  title: string;
  value: null | string;
  setValue: any;
}

export const CustomDatePicker = ({ title, value, setValue }: ICustomDatePicker): JSX.Element => {
  return (
    <Box className={styles.datepicker}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label={title}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};
