import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import { mainTheme } from '@/core/theme';
import { store } from '@/core/store';
import { paths } from '@/data/paths';
import Main from '@/pages/Main';
import Venues from '@/pages/Venues';
import VenueEdit from '@/pages/VenueEdit';
import VenueCreate from '@/pages/VenueCreate';
import VenueOwner from '@/pages/VenueOwner';
import Events from '@/pages/Events';
import EventEdit from '@/pages/EventEdit';
import EventCreate from '@/pages/EventCreate';
import Users from '@/pages/Users';
import UserEdit from '@/pages/UserEdit';
import UserCreate from '@/pages/UserCreate';
import Creators from '@/pages/Creators';
import Instance from '@/pages/Instance';
import InstanceEdit from '@/pages/InstanceEdit';
import InstanceCreate from '@/pages/InstanceCreate';
import Avatars from '@/pages/Avatars';
import AvatarEdit from '@/pages/AvatarEdit';
import Assistants from '@/pages/Assistants';
import AssistantEdit from '@/pages/AssistantEdit';
import AssistantInteractionsEdit from '@/pages/AssistantInteractionsEdit';
import EventRoles from '@/pages/EventRoles';
import EventRolesEdit from '@/pages/EventRolesEdit';
import EventRolesCreate from '@/pages/EventRolesCreate';
import CustomAvatars from '@/pages/CustomAvatars';
import CustomAvatarEdit from '@/pages/CustomAvatarEdit';
import CustomAvatarCreate from '@/pages/CustomAvatarCreate';
import CreatorsEdit from '@/pages/CreatorsEdit';
import ProposalEdit from '@/pages/ProposalsEdit';
import Proposal from '@/pages/Proposals';
import Pool from '@/pages/Pools';
import PoolCreate from '@/pages/PoolCreate';
import PoolEdit from '@/pages/PoolEdit';
import Locations from '@/pages/Locations';
import Guilds from '@/pages/Guilds';
import AssignCustomAvatar from '@/pages/AssignCustomAvatar';
import { WalletChoosePopup } from '@/shared/components/WalletChoosePopup';

import './core/styles/index.scss';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={mainTheme}>
          <Routes>
            <Route
              path={paths.admin.home}
              element={<Main />}
            />
            <Route
              path={paths.admin.venues}
              element={<Venues />}
            />
            <Route
              path={paths.admin.editVenue}
              element={<VenueEdit />}
            />
            <Route
              path={paths.admin.createVenue}
              element={<VenueCreate />}
            />
            <Route
              path={paths.admin.setOwnerVenue}
              element={<VenueOwner />}
            />
            <Route
              path={paths.admin.events}
              element={<Events />}
            />
            <Route
              path={paths.admin.eventEditRole}
              element={<EventEdit />}
            />
            <Route
              path={paths.admin.eventEdit}
              element={<EventEdit />}
            />
            <Route
              path={paths.admin.eventCreate}
              element={<EventCreate />}
            />
            <Route
              path={paths.admin.eventRoles}
              element={<EventRoles />}
            />
            <Route
              path={paths.admin.eventRolesEdit}
              element={<EventRolesEdit />}
            />
            <Route
              path={paths.admin.eventRolesCreate}
              element={<EventRolesCreate />}
            />
            <Route
              path={paths.admin.users}
              element={<Users />}
            />
            <Route
              path={paths.admin.usersEdit}
              element={<UserEdit />}
            />
            <Route
              path={paths.admin.usersCreate}
              element={<UserCreate />}
            />
            <Route
              path={paths.admin.creators}
              element={<Creators />}
            />
            <Route
              path={paths.admin.creatorEdit}
              element={<CreatorsEdit />}
            />
            <Route
              path={paths.admin.becomeCreator}
              element={<Creators />}
            />
            <Route
              path={paths.admin.instances}
              element={<Instance />}
            />
            <Route
              path={paths.admin.instanceEdit}
              element={<InstanceEdit />}
            />
            <Route
              path={paths.admin.instanceCreate}
              element={<InstanceCreate />}
            />
            <Route
              path={paths.admin.avatars}
              element={<Avatars />}
            />
            <Route
              path={paths.admin.avatarEdit}
              element={<AvatarEdit />}
            />
            <Route
              path={paths.admin.customAvatars}
              element={<CustomAvatars />}
            />
            <Route
              path={paths.admin.customAvatarEdit}
              element={<CustomAvatarEdit />}
            />
            <Route
              path={paths.admin.customAvatarCreate}
              element={<CustomAvatarCreate />}
            />
            <Route
              path={paths.admin.assistants}
              element={<Assistants />}
            />
            <Route
              path={paths.admin.assistantEdit}
              element={<AssistantEdit />}
            />
            <Route
              path={paths.admin.assistantInteractionsEdit}
              element={<AssistantInteractionsEdit />}
            />
            <Route
              path={paths.admin.proposals}
              element={<Proposal />}
            />
            <Route
              path={paths.admin.proposalsEdit}
              element={<ProposalEdit />}
            />
            <Route
              path={paths.admin.pools}
              element={<Pool />}
            />
            <Route
              path={paths.admin.poolCreate}
              element={<PoolCreate />}
            />
            <Route
              path={paths.admin.poolEdit}
              element={<PoolEdit />}
            />
            <Route
              path={paths.admin.locations}
              element={<Locations />}
            />
            <Route
              path={paths.admin.guilds}
              element={<Guilds />}
            />
            <Route
              path={paths.admin.assignCustomAvatar}
              element={<AssignCustomAvatar />}
            />
          </Routes>
          <WalletChoosePopup />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
