import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Menu, Link, MenuItem, Typography } from '@mui/material';
import { ReactComponent as ArrowSvg } from '@/resources/icons/arrow.svg';

import styles from './Dropdown.module.scss';

interface DropdownOptionsProps {
  label: string;
  path: string;
}

interface DropdownProps {
  title: string;
  options: DropdownOptionsProps[];
}

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const { title, options } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const isDropdown = typeof options === 'object';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isDropdown) {
      setAnchorEl(event.currentTarget);
    } else navigate(options);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (path: any) => () => {
    handleClose();
    navigate(path);
  };

  return (
    <>
      <Box
        className={styles.dropdown__header}
        onClick={handleClick}
      >
        <Typography fontWeight={500}>{title}</Typography>
        {isDropdown && <ArrowSvg />}
      </Box>
      {options.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          classes={{ paper: styles.dropdown__menu }}
          disableScrollLock
        >
          {options.map((item, index: number) => {
            return (
              <MenuItem
                className={`${
                  location.pathname === item.path ? styles.dropdown__menu_item_active : styles.dropdown__menu_item
                }`}
                onClick={handleClose}
                key={index}
              >
                <Link
                  href={item.path}
                  className={styles.dropdown__menu_link}
                >
                  <Typography fontSize={18}>{item.label}</Typography>
                </Link>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};
