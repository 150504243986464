import * as React from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { Box } from '@mui/system';

import styles from './CustomTextEditor.module.scss';
import { RichTextEditor } from '@/shared/components/RtfEditor';

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  placeholder?: string;
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
  disabled?: boolean;
}

export const CustomTextEditor = (props: InputProps): JSX.Element => {
  const { form, field, label, onChange, required, disabled, placeholder } = props;

  const onChangeHandler = (value: string) => {
    form.setFieldValue(field.name, value, true);
    onChange && onChange(value);
    form.setFieldTouched(field.name, true, false)
  }

  return (
    <Box className={styles.select}>
      <RichTextEditor
        placeholder={placeholder || ''}
        name={field.name}
        label={`${label}${required ? (<span> *</span>) : ''}`}
        value={field.value}
        onChange={onChangeHandler}
        disabled={disabled}
      />
      {form.touched[field.name] && Boolean(form.errors[field.name]) && (
        <div className={styles.select__error}>{form.errors[field.name]}</div>
      )}
    </Box>
  );
};
