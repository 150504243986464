export enum ActionTypes {
  LOADING = '@connect/LOADING',
  SET_CONNECTED = '@connect/SET_CONNECTED',
  SET_OPEN = '@connect/SET_OPEN',
}

export interface Session {
  isLoading: boolean;
  isOpenWalletPopup: boolean;
  isConnectedWallet: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.SET_CONNECTED;
      payload: boolean;
    }
  | {
      type: ActionTypes.SET_OPEN;
      payload: boolean;
    };

const initialState = {
  isLoading: false,
  isOpenWalletPopup: false,
  isConnectedWallet: false,
};

export const connectReducer = (state: Session = initialState, action: Action): Session => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ActionTypes.SET_CONNECTED: {
      return {
        ...state,
        isConnectedWallet: action.payload,
      };
    }

    case ActionTypes.SET_OPEN: {
      return {
        ...state,
        isOpenWalletPopup: action.payload,
      };
    }

    default:
      return state;
  }
};
