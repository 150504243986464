import { editFieldsFromData } from '@/core/components/Edit/settings';

export enum ActionTypes {
  LOADING = '@avatar/LOADING',
  ERROR = '@avatar/ERROR',
  SET_AVATAR_LIST = '@avatar/SET_AVATAR_LIST',
  DELETE_AVATAR = '@avatar/DELETE_AVATAR',
  SET_AVATAR_EDIT_ITEM = '@avatar/SET_AVATAR_EDIT_ITEM',
  SET_AVATAR_CREATORS_LIST = '@avatar/SET_AVATAR_CREATORS_LIST',
}

export interface AvatarStateProps {
  itemsList: any;
  editedItem: any;
  creatorsList: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_AVATAR_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.DELETE_AVATAR;
      payload: any;
    }
  | {
      type: ActionTypes.SET_AVATAR_EDIT_ITEM;
      payload: any;
    }
  | {
      type: ActionTypes.SET_AVATAR_CREATORS_LIST;
      payload: any;
    };

const avatarListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: avatarListState,
  editedItem: null,
  creatorsList: [],
  isLoading: false,
  isError: false,
};

export const avatarReducer = (state: AvatarStateProps = initialState, action: Action): AvatarStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_AVATAR_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_AVATAR_EDIT_ITEM: {
      return {
        ...state,
        editedItem: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_AVATAR_CREATORS_LIST: {
      return {
        ...state,
        creatorsList: action.payload,
        isLoading: false,
        isError: false,
      };
    }

    default:
      return state;
  }
};
