import * as React from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { FormLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';

import styles from './CustomInput.module.scss';

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  placeholder: string;
  type?: string;
  onChange?: any;
  value?: string;
  onBlur?: any | undefined;
  name?: string;
  id?: string;
  required?: boolean;
  label?: string;
  error?: string;
  multiline?: boolean;
  maxRows?: number;
  disabled?: boolean;
}

export const CustomInput = (props: InputProps): JSX.Element => {
  const { form, field, onChange, placeholder, type, label, error, multiline, maxRows, required, ...p } = props;

  return (
    <Box className={styles.input}>
      <FormLabel className={styles.input__label}>
        {label}
        {required && <span> *</span>}
      </FormLabel>
      <TextField
        name={field.name}
        value={field.value || ''}
        variant="outlined"
        autoComplete="off"
        hiddenLabel
        type={type}
        helperText={error}
        error={Boolean(error)}
        placeholder={placeholder}
        multiline={multiline}
        maxRows={maxRows}
        onChange={field.onChange}
        {...p}
      />
      {form.touched[field.name] && Boolean(form.errors[field.name]) && (
        <div className={styles.input__error}>{form.errors[field.name]}</div>
      )}
    </Box>
  );
};
