import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionVenueList } from '@/modules/VenuesPage/actions';
import { actionEventList } from '@/modules/EventsPage/actions';
import { actionUsersList } from '@/modules/UsersPage/actions';
import { actionCreatorsList } from '@/modules/CreatorsPage/actions';
import { actionInstanceList } from '@/modules/InstancePage/actions';
import { actionAvatarList } from '@/modules/AvatarsPage/actions';
import { actionEventRoleList, actionGetRolePermissionsList } from '@/modules/EventRolesPage/actions';
import { actionCustomAvatarList } from '@/modules/CustomAvatarsPage/actions';
import { actionAssistantList } from '@/modules/AssistantsPage/actions';
import { actionBecomeCreatorList } from '@/modules/BecomeCreatorPage/actions';
import { actionGetProposalList } from '@/modules/ProposalPage/actions';
import { actionGetPoolList } from '@/modules/PoolPage/actions';
import { PoolList } from '@/modules/PoolPage/reducers';
import { actionGetLocationsList } from '@/modules/LocationsPage/actions';
import { actionGetGuildsList } from '@/modules/GuildsPage/actions';

export interface FilterProps {
  page: number;
  limit?: number;
  name?: string;
  username?: string;
  email?: string;
  roleId?: string;
  isDeleted?: boolean;
  orderBy?: string;
  orderDirection?: string;
  status?: string;
  lifecycleStatus?: string;
  id?: string;
}

const initialFilter: FilterProps = {
  page: 1,
  limit: 10,
};

const useGetFilteredDataFromService = (serviceName: string, filter?: FilterProps) => {
  const dispatch = useDispatch<any>();
  const [filters, setFilters] = useState<FilterProps>(filter || initialFilter);

  useEffect(() => {
    if (serviceName) {
      dispatch(actionsMapping[serviceName]);
    }
  }, [serviceName, filters]);

  useEffect(() => {
    if (serviceName === 'eventRole') {
      dispatch(actionGetRolePermissionsList());
    }
  }, [serviceName]);

  const reducersMapping = {
    venue: useSelector(({ venueReducer }: any) => venueReducer),
    event: useSelector(({ eventReducer }: any) => eventReducer),
    eventRole: useSelector(({ eventRolesReducer }: any) => eventRolesReducer),
    user: useSelector(({ userReducer }: any) => userReducer),
    creator: useSelector(({ creatorsReducer }: any) => creatorsReducer),
    becomeCreator: useSelector(({ becomeCreatorReducer }: any) => becomeCreatorReducer),
    instance: useSelector(({ instanceReducer }: any) => instanceReducer),
    avatar: useSelector(({ avatarReducer }: any) => avatarReducer),
    customAvatar: useSelector(({ customAvatarReducer }: any) => customAvatarReducer),
    assistant: useSelector(({ assistantReducer }: any) => assistantReducer),
    proposal: useSelector(({ proposalReducer }: any) => proposalReducer),
    pool: useSelector(({ poolReducer }: any) => poolReducer),
    location: useSelector(({ locationReducer }: any) => locationReducer),
    guild: useSelector(({ guildReducer }: any) => guildReducer),
  };

  const actionsMapping = {
    venue: actionVenueList(filters),
    event: actionEventList(filters),
    eventRole: actionEventRoleList(filters),
    user: actionUsersList(filters),
    creator: actionCreatorsList(filters),
    becomeCreator: actionBecomeCreatorList(filters),
    instance: actionInstanceList(filters),
    avatar: actionAvatarList(filters),
    customAvatar: actionCustomAvatarList(filters),
    assistant: actionAssistantList(filters),
    proposal: actionGetProposalList(filters),
    pool: actionGetPoolList(filters),
    location: actionGetLocationsList(filters),
    guild: actionGetGuildsList(filters),
  };

  const serviceData = reducersMapping[serviceName];

  return {
    serviceData,
    filters,
    setFilters,
  };
};

export default useGetFilteredDataFromService;
