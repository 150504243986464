import {guildApi} from '@/api';
import {userApi} from '@/api/user';
import {TypedDispatch} from '@/core/models';
import {notify} from '@/shared/components/Notify/Notify';
import {FilterProps} from '@/core/hooks/useGetFilteredDataFromService';
import {ActionTypes} from '../reducers';

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetGuildsList = (data: any) => ({
  type: ActionTypes.SET_GUILDS,
  payload: data,
});

export const actionGetGuildsList = (filters?: FilterProps) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const response = await guildApi.getGuilds(filters);

    if (response.data.success) {
      const userIds: string[] = [];
      const guildsList = response.data.data;

      guildsList.items &&
      guildsList.items.forEach((item: any) => {
        if (item.ownerId) {
          userIds.push(item.ownerId);
        }
      });

      if (userIds.length) {
        const usersResponse = await userApi.getUsersByIds(userIds);

        if (usersResponse.data.success) {
          const userList = usersResponse.data.data;
          guildsList.items.forEach((item: any) => {
            if (item.ownerId) {
              const userIndex = userList.findIndex(
                (user: { id: string; username: string }) => item.ownerId === user.id,
              );
              item.ownerName = userList[userIndex]?.username;
            }
          });
        }
      }

      dispatch(actionSetGuildsList(guildsList));
    } else {
      throw response.data.message;
    }
  } catch (e: any) {
    dispatch({type: ActionTypes.ERROR});
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};