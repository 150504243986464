import { Layout } from '@/core/layout';
import { Frame } from '@/shared/components/Frame';
import { Table } from '@/shared/components/Table';
import { useDispatch, useSelector } from 'react-redux';

export const UsersPage = () => {
  const dispatch = useDispatch<any>();

  return (
    <Layout backgroundType="none">
      <Frame>
        <h1>Users</h1>
        <Table />
      </Frame>
    </Layout>
  );
};
