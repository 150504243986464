import { Edit } from '@/core/components/Edit';
import { Layout } from '@/core/layout';
import { Frame } from '@/shared/components/Frame';

export const AvatarEditPage = () => {
  return (
    <Layout backgroundType="none">
      <Frame>
        <Edit />
      </Frame>
    </Layout>
  );
};
