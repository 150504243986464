import { useEffect, useRef, useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { Popper, ClickAwayListener, Grow, Paper } from '@mui/material';
import { Box } from '@mui/system';

import useSearchUser from '@/core/hooks/useSearchUser';
import { SectionSearchUser } from '../SectionSearchUser';

import styles from './SearchUser.module.scss';

export interface OptionsProps {
  id: string;
  name: string;
}

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
}

interface SearchUserProps {
  setUser: (user: any) => void;
  user: any;
}

export const SearchUser = ({ setUser, user }: SearchUserProps): JSX.Element => {
  const [username, setUsername] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const { users } = useSearchUser({ value: username, setOpen, user });
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!user) {
      setUsername('');
    }
  }, [user]);

  const onClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  const userClickHandler = (user: any) => () => {
    setUser(user);
    setUsername(user.username);
    setOpen(false);
  };

  const usernameChangeHandler = (value: string) => {
    setUsername(value);
    if (user) {
      setUser(null);
    }
  };

  return (
    <Box
      className={styles.searchUser}
      ref={anchorRef}
    >
      <SectionSearchUser
        searchValue={username}
        className={styles.searchUser__search}
        changeHandler={usernameChangeHandler}
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        className={styles.searchUser__popper}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Grow {...TransitionProps}>
              <Paper className={styles.searchUser__menu}>
                {users.map((user: any) => (
                  <div
                    key={user.id}
                    onClick={userClickHandler(user)}
                    className={styles.searchUser__menu__user}
                  >
                    {user.username}
                  </div>
                ))}
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};
