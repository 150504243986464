import { useEffect, useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { Box, FormLabel, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import useGetServiceData from '@/core/hooks/useGetServiceData';
import { actionEventRoleList } from '@/modules/EventRolesPage/actions';
import { Button } from '@/shared/components/Button';
import { notify } from '@/shared/components/Notify/Notify';
import { SearchUser } from './containers/SearchUser';

import { ReactComponent as CloseIcon } from '@/resources/icons/close-modal-icon.svg';

import styles from './EditEventRole.module.scss';

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
}

export interface OptionsProps {
  label: string;
  value: string;
}

const initialFilter: FilterProps = {
  page: 1,
  limit: 10,
};

export const EditEventRole = (props: any): JSX.Element => {
  const { form, field, label, options, onChange, required } = props;

  const dispatch = useDispatch();
  const [user, setUser] = useState<any>(null);
  const [listData, setListData] = useState<any>([]);
  const [roleData, setRoleData] = useState<any>([]);
  const [choosedRole, setChoosedRole] = useState<string | null>('');
  const [eventRolleValue, setEventRolleValue] = useState<any>(null);
  const [eventRolleData, setEventRolleData] = useState<any>([]);
  const [filters, setFilters] = useState<FilterProps>(initialFilter);
  const [rolesOption, setRolesOption] = useState<OptionsProps[] | []>([]);

  const serviceData = useGetServiceData('eventRole');
  const { itemsList } = serviceData;

  const role = form.values.role;

  useEffect(() => {
    dispatch(actionEventRoleList(filters) as any);
  }, []);

  useEffect(() => {
    const rolesArray = itemsList?.items;

    const roles = rolesArray?.map((item: any) => {
      return { label: item.name, value: item.id };
    });

    setRolesOption(roles);
  }, [itemsList]);

  useEffect(() => {
    if (user?.id && choosedRole) {
      setEventRolleData((prev: any) => [
        ...prev,
        {
          userId: user?.id,
          roleId: choosedRole,
        },
      ]);
    }
  }, [user, choosedRole]);

  useEffect(() => {
    setEventRolleValue(() => {
      return {
        eventId: form.values.id,
        data: eventRolleData,
      };
    });
  }, [eventRolleData]);

  const onChangeHandler = (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    const choosedRoleData = rolesOption.find((item) => item.value === value);

    setChoosedRole(value);
    setRoleData(choosedRoleData);

    field.onChange(event);
    onChange && onChange(event);
    form.setFieldTouched(choosedRole, true, false);
  };

  const saveRoleHandler = () => {
    if (role && user) {
      if (listData.some((value: any) => value.user.id === user.id)) {
        return notify.info(`User ${user.username} already exists`);
      }

      setListData((prev: any) => [...prev, { user: user, role: roleData }]);

      form.setFieldValue('eventRoleToUser', eventRolleValue);

      setChoosedRole('');

      field.onChange(user.id);
      onChange && onChange(user.id);
      form.setFieldTouched(user, true, false);

      if (user) {
        setUser(null);
      }
    }
  };

  const removeRoleHandler = (index: number) => () => {
    const roles = [...listData];
    roles.splice(index, 1);

    setListData(roles);
  };

  return (
    <Box className={styles.roles}>
      <div className={styles.roles__list}>
        {listData?.map((item: any, index: number) => (
          <div
            key={index}
            className={styles.roles__list_item}
          >
            <div className={styles.roles__list_item__role}>
              user: <span className={styles.roles__highlight}>{item.user.username}</span>, have role:{' '}
              <span className={styles.roles__highlight}>{item.role.label}</span>
            </div>
            <IconButton
              onClick={removeRoleHandler(index)}
              className={styles.roles__list_item__remove}
            >
              <CloseIcon className={styles.roles__list_item__remove_icon} />
            </IconButton>
          </div>
        ))}
      </div>

      <Box className={styles.roles__select}>
        <FormLabel className={styles.roles__label}>
          {label}
          {required && <span> *</span>}
        </FormLabel>
        <Select
          name={field.name}
          value={choosedRole}
          onChange={onChangeHandler}
        >
          {rolesOption?.map((item: any) => (
            <MenuItem
              key={item.value}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {form.touched[field.name] && Boolean(form.errors[field.name]) && (
          <div className={styles.roles__error}>{form.errors[field.name]}</div>
        )}
      </Box>
      <div className={styles.roles__search__wrapper}>
        <FormLabel className={styles.roles__label}>
          Find user
          <span> *</span>
        </FormLabel>
        <div className={styles.roles__search}>
          <SearchUser
            user={user}
            setUser={setUser}
          />
          <Button
            contained
            disabled={!user || !role}
            onClick={saveRoleHandler}
            className={styles.roles__search__submit}
          >
            Ok
          </Button>
        </div>
        {form.touched[field.name] && Boolean(form.errors[field.name]) && (
          <div className={styles.roles__error}>{form.errors[field.name]}</div>
        )}
      </div>
    </Box>
  );
};
