import {
  Box,
  Typography,
  IconButton,
  Modal as MuiModal,
  ModalProps as MuiModalProps
} from '@mui/material';

import { ReactComponent as CloseIcon } from '@/resources/icons/close-modal-icon.svg';

import styles from './Modal.module.scss';

interface ModalProps extends MuiModalProps {
  title?: string;
  backgroundType?: 'light' | 'dark';
  titleWeight?: 'normal' | 'bold';
  titleUnderline?: 'none' | 'underline'
}

const backgroundClasses = {
  dark: styles.modal__dark,
  light: styles.modal__light
};

const titleWeightClasses = {
  normal: styles.modal__title_normal,
  bold: styles.modal__title_bold
};

const titleUnderlineClasses = {
  underline: styles.modal__title_underline,
  none: ''
};

export const Modal = (
  {
    open,
    title,
    onClose,
    children,
    titleWeight = 'bold',
    backgroundType = 'dark',
    titleUnderline = 'none',
    ...props
  }: ModalProps
): JSX.Element => {

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      classes={{root: styles.modal__root}}
      {...props}
    >
      <Box className={`${styles.modal} ${backgroundClasses[backgroundType]}`}>
        <Typography
          variant='h4'
          className={`${styles.modal__title} ${titleWeightClasses[titleWeight]} ${titleUnderlineClasses[titleUnderline]}`}
        >
          {title}
        </Typography>
        <IconButton
          onClick={onClose as () => void}
          className={styles.modal__close}
        >
          <CloseIcon/>
        </IconButton>
        {children}
      </Box>
    </MuiModal>
  );
};
