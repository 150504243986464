import { DetailedHTMLProps, HTMLAttributes, MouseEvent, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';

import styles from './RowActionEdit.module.scss';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { serviceNameChecker } from '@/core/utils/serviceNameChecker';

interface RowActionsProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  itemId: string;
  isEditable: boolean;
  props?: any;
  className?: string;
}

export const RowActionEdit = ({ itemId, isEditable, className }: RowActionsProps): JSX.Element => {
  const currentPath = useLocation();
  const moduleName = currentPath.pathname.split('/')[1];
  const service = serviceNameChecker(moduleName);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewLinkByService = (service: string) => {
    const viewLink = {
      proposal: `${process.env.REACT_APP_PUBLIC_USER_DASHBOARD}/vrjam/proposals/${itemId}`,
    };
    return viewLink[service];
  };

  return (
    <div className={cn(styles.tableActions, className)}>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon
          className={`${isEditable ? styles.tableActions__icon_btn : styles.tableActions__icon_btn_gray}`}
        />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        {viewLinkByService(service) && (
          <MenuItem className={styles.tableActions__type}>
            <a
              href={viewLinkByService(service)}
              target="_blank"
              className={styles.tableActions__link}
              rel="noreferrer"
            >
              <VisibilityIcon className={styles.tableActions__icon} />
              View
            </a>
          </MenuItem>
        )}
        {isEditable && (
          <MenuItem className={styles.tableActions__type}>
            <Link
              to={`/${moduleName}/${itemId}`}
              className={styles.tableActions__link}
            >
              <EditIcon className={styles.tableActions__icon} />
              Edit
            </Link>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
