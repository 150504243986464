import { api } from '@/core/api/api';

// performs a request and resolves with JSON
export const fetchJson = async (url, init = {}) => {
  const res = await api.get(url, {params: init});

  if (res.status !== 200) {
    const error = res.response?.data?.message || res.message || res.statusText;
    throw new Error(`${res.status}: ${error}`);
  }

  return res;
};

// get JSON from multiple URLs and pass to setters
export const fetchAndSetAll = async (collection) => {
  // fetch all data first
  const allData = await Promise.all(collection.map(
    ({ url, init }) => fetchJson(url, init)
    ));
  // iterate setters and pass in data
  collection.forEach(({ setter }, i) => {
    setter(allData[i].data.data);
  });
};
