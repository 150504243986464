import React, { useEffect, useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { Box, FormLabel, TextField } from '@mui/material';
import { parseUnits } from '@ethersproject/units';

import styles from './CustomBigNumberInput.module.scss';

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  placeholder: string;
  type?: string;
  onChange?: any;
  value?: string;
  onBlur?: any | undefined;
  name?: string;
  id?: string;
  required?: boolean;
  label?: string;
  error?: string;
  multiline?: boolean;
  maxRows?: number;
  disabled?: boolean;
  decimals?: number;
  min?: number;
  max?: number;
}

export const CustomBigNumberInput = (props: InputProps) => {
  const { form, field, onChange, placeholder, type, label, error, multiline, maxRows, required,
    decimals = 18, min = 0, max, ...p } = props;
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (!field.value) {
      setInputValue('')
    } else {
      let parseInputValue

      try {
        parseInputValue = parseUnits(inputValue || '0', decimals)
      } catch (e) {
        // do nothing
      }

      if (!parseInputValue || parseInputValue !== field.value) {
        setInputValue(field.value)
      }
    }
  }, [field.value, decimals, inputValue])

  const updateValue = (event:  React.ChangeEvent<any>) => {
    const { value } = event.currentTarget

    if (value === '') {
      field.onChange(event)
      if (onChange) {
        onChange(event)
      }
      setInputValue(value)
      return
    }

    let newValue
    try {
      newValue = parseUnits(value, decimals)
    } catch (e) {
      return
    }

    const invalidValue = newValue.lt(0) || (min && newValue.lt(min)) || (max && newValue.gt(max))
    if (invalidValue) {
      return
    }

    setInputValue(value)
    if (onChange) {
      field.onChange(event)
      onChange(event)
    } else {
      field.onChange(event)
    }
  }

  return (
    <Box className={styles.input}>
      <FormLabel className={styles.input__label}>
        {label}
        {required && <span> *</span>}
      </FormLabel>
      <TextField
        name={field.name}
        value={field.value || ''}
        variant="outlined"
        autoComplete="off"
        hiddenLabel
        type={type}
        helperText={error}
        error={Boolean(error)}
        placeholder={placeholder}
        multiline={multiline}
        maxRows={maxRows}
        onChange={updateValue}
        {...p}
      />
      {form.touched[field.name] && Boolean(form.errors[field.name]) && (
        <div className={styles.input__error}>{form.errors[field.name]}</div>
      )}
    </Box>
  )
}
