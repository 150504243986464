import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { sidebarNavigation } from '@/data/sidebarNavigation';
import { paths } from '@/data/paths';
import { actionNativeLogout } from '@/modules/Auth/actions';

import { ReactComponent as PlusIcon } from '@/resources/icons/plus.svg';
import { ReactComponent as LogoutIcon } from '@/resources/icons/account-logout.svg';

import styles from './Sidebar.module.scss';

export const Sidebar = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const sidebarHandler = () => {
    setOpen((prevState) => !prevState);
  };

  const itemClickHandler = (href: string) => () => {
    navigate(href);
  };

  const plusClickHandler = (hrefPlus: string) => () => {
    hrefPlus && navigate(hrefPlus);
  };

  const handleLogoutClick = async () => {
    await dispatch(actionNativeLogout() as any);
    window.location.replace(paths.marketplace.home);
  };

  return (
    <>
      <Drawer
        open={open}
        variant="permanent"
        classes={{
          paper: `${styles.sidebar} ${open ? styles.open : ''}`,
        }}
        onMouseEnter={sidebarHandler}
        onMouseLeave={sidebarHandler}
      >
        <List classes={{ root: styles.sidebar__list }}>
          {sidebarNavigation.top.map((item: any, index: number) => (
            <ListItem
              key={index}
              disablePadding
              classes={{ root: styles.sidebar__list__item }}
            >
              <ListItemButton
                classes={{
                  root: `
                  ${styles.sidebar__list__item__button} 
                  ${location.pathname === item.href ? styles.sidebar__list__item__button_active : ''}
                `,
                }}
              >
                <ListItemIcon
                  onClick={plusClickHandler(item.href)}
                  classes={{
                    root: `
                    ${styles.sidebar__list__item__button_icon} 
                    ${location.pathname === item.href ? styles.sidebar__list__item__button_icon_active : ''}
                  `,
                  }}
                >
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  className={styles.sidebar__list__item__button_text}
                  primary={item.name}
                  onClick={plusClickHandler(item.href)}
                />
                {item.hasPlus && (
                  <ListItemIcon
                    classes={{ root: styles.sidebar__list__item__button_plus }}
                    onClick={plusClickHandler(item.hrefPlus)}
                  >
                    <PlusIcon />
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider className={styles.sidebar__divider} />
        <List classes={{ root: styles.sidebar__list }}>
          {sidebarNavigation.bottom.map((item: any, index: number) => (
            <ListItem
              key={index}
              disablePadding
              classes={{ root: styles.sidebar__list__item }}
              onClick={itemClickHandler(item.href)}
            >
              <ListItemButton
                classes={{
                  root: `
                  ${styles.sidebar__list__item__button} 
                  ${location.pathname === item.href ? styles.sidebar__list__item__button_active : ''}
                `,
                }}
              >
                <ListItemIcon
                  classes={{
                    root: `
                    ${styles.sidebar__list__item__button_icon} 
                    ${location.pathname === item.href ? styles.sidebar__list__item__button_icon_active : ''}
                  `,
                  }}
                >
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  className={styles.sidebar__list__item__button_text}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem
            disablePadding
            classes={{ root: styles.sidebar__list__item }}
          >
            <ListItemButton
              classes={{ root: styles.sidebar__list__item__button }}
              onClick={handleLogoutClick}
            >
              <ListItemIcon
                classes={{
                  root: `${styles.sidebar__list__item__button_icon} ${styles.sidebar__list__item__button_icon_logout}`,
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                className={`${styles.sidebar__list__item__button_text} ${styles.sidebar__list__item__button_text_logout}`}
                primary="Logout"
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
