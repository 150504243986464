// this file use for shown field and rename it label from './editFieldsFromData.js
// you get ERROR if settings row not exist in './editFieldsFromData.js
// if delete some obj from array this fields will be disappeared from editing view
// If you add fields here and get error - check exist field.name in '@/data/validationSettings.js' ?

import * as React from 'react';
import moment from "moment";

import { CustomSelect } from '@/shared/components/CustomSelect';
import { CustomTextEditor } from '@/shared/components/CustomTextEditor';
import { CustomFileUploader } from '@/shared/components/CustomFileUploader';
import { EditEventRole } from '@/shared/components/EditEventRole';
import { Gallery } from '@/core/components/Gallery';
import { EventRolesMultiSelect } from '@/core/components/EventRolesMultiSelect';
import { UserRolesMultiSelect } from '@/core/components/UserRolesMultiSelect';
import {
  activatedOptions,
  adminStatusEditOptions,
  avatarTypeOptions,
  blockedOptions,
  clientStatusOptions,
  deletedOptions, FactionOptions,
  genderOptions, LocationOptions,
  proposalDecisionOptions,
} from '@/data/constants';
import { CustomBigNumberInput } from '@/shared/components/CustomBigNumberInput';
import { SetUserId } from '@/modules/AssignCustomAvatarPage/components/SetUserId';
import { SetOwnerAddress } from "@/modules/VenueOwnerPage/components/SetOwnerAddress";

interface OptionPropsItem {
  label: string;
  value: string | boolean;
}

interface FieldsPropsItem {
  name: string;
  label: string;
  isRequired: boolean;
  isDisabled?: boolean;
  component?: JSX.Element | any;
  options?: OptionPropsItem[];
  type?: React.InputHTMLAttributes<unknown>['type'];
  decimals?: number;
  min?: string;
}

interface DomainFolderProps {
  venueOwner: FieldsPropsItem[];
  eventRoleToUser: FieldsPropsItem[];
  assignAvatar: FieldsPropsItem[];
  interactions: FieldsPropsItem[];
}

interface FieldsProps {
  venue: FieldsPropsItem[];
  event: FieldsPropsItem[];
  eventRole: FieldsPropsItem[];
  user: FieldsPropsItem[];
  creator: FieldsPropsItem[];
  instance: FieldsPropsItem[];
  avatar: FieldsPropsItem[];
  customAvatar: FieldsPropsItem[];
  assistant: FieldsPropsItem[];
  proposal: FieldsPropsItem[];
  pool: FieldsPropsItem[];
}

export const formEditFields = (serviceName: string, domainFolderName = ''): FieldsPropsItem[] => {
  if (domainFolderName) {
    const domainFolderEditFields: DomainFolderProps = {
      eventRoleToUser: [
        {
          name: 'role',
          label: 'Event role',
          isRequired: true,
          component: EditEventRole,
        },
      ],
      assignAvatar: [
        {
          name: 'userId',
          label: 'User',
          isRequired: true,
          component: SetUserId,
        },
        {
          name: 'name',
          label: 'Name',
          isRequired: true,
        },
        {
          name: 'description',
          label: 'Description',
          isRequired: false,
          component: CustomTextEditor,
        },
        {
          name: 'glbFile',
          label: '3D model',
          isRequired: false,
          component: CustomFileUploader,
          isDisabled: true,
        },
      ],
      interactions: [
        {
          name: 'interactions',
          label: 'Interactions',
          isRequired: true,
          type: 'number',
        },
      ],
      venueOwner: [
        {
          name: 'walletAddress',
          label: 'Venue owner',
          isRequired: true,
          component: SetOwnerAddress,
        },
      ],
    };

    return domainFolderEditFields[domainFolderName];
  }

  const editFields: FieldsProps = {
    venue: [
      { name: 'name', label: 'Name', isRequired: true },
      { name: 'pool.proposal.name', label: 'Pool', isRequired: false, isDisabled: true },
      { name: 'sceneId', label: 'Scene Id', isRequired: true },
      { name: 'metaUrl', label: 'Mata URL', isRequired: true },
      {
        name: 'faction',
        label: 'Faction',
        isRequired: true,
        component: CustomSelect,
        options: FactionOptions,
      },
      {
        name: 'location',
        label: 'Location',
        isRequired: true,
        component: CustomSelect,
        options: LocationOptions,
      },
      {
        name: 'thumbnailFile',
        label: 'Thumbnail image',
        isRequired: false,
        component: CustomFileUploader,
      },
      {
        name: 'coverFile',
        label: 'Cover image',
        isRequired: false,
        component: CustomFileUploader,
      },
      {
        name: 'description',
        label: 'Description',
        isRequired: false,
        component: CustomTextEditor,
      },
    ],
    event: [
      { name: 'title', label: 'Title', isRequired: true },
      {
        name: 'venueName',
        label: 'Venue',
        isRequired: true,
        isDisabled: true,
      },
      {
        name: 'startAt',
        label: 'Started',
        isRequired: true,
        type: 'datetime-local',
      },
      {
        name: 'finishAt',
        label: 'Finished',
        isRequired: true,
        type: 'datetime-local',
      },
      { name: 'logUrl', label: 'Log URL', isRequired: false },
      { name: 'videoUrl', label: 'Video URL', isRequired: false },
      { name: 'secondVideoURL', label: 'Second Video URL', isRequired: false },
      { name: 'previewVideoUrl', label: 'Preview Video URL', isRequired: false },
      { name: 'mockupPrefubId', label: 'Mockup Prefub ID', isRequired: false },
      {
        name: 'adminStatus',
        label: 'Admin Status',
        isRequired: true,
        component: CustomSelect,
        options: adminStatusEditOptions,
      },
      {
        name: 'clientStatus',
        label: 'Client Status',
        isRequired: true,
        component: CustomSelect,
        options: clientStatusOptions,
      },
      { name: 'stream', label: 'Stream', isRequired: true },
      {
        name: 'thumbnailFile',
        label: 'Thumbnail image',
        isRequired: false,
        component: CustomFileUploader,
      },
      {
        name: 'coverFile',
        label: 'Cover image',
        isRequired: false,
        component: CustomFileUploader,
      },
      {
        name: 'gallery',
        label: 'Gallery image',
        isRequired: false,
        component: Gallery,
      },
      {
        name: 'description',
        label: 'Description',
        isRequired: false,
        component: CustomTextEditor,
      },
    ],
    eventRole: [
      { name: 'name', label: 'Event role', isRequired: true },
      {
        name: 'permissions',
        label: 'Permission',
        isRequired: true,
        component: EventRolesMultiSelect,
      },
    ],
    user: [
      { name: 'username', label: 'User name', isRequired: true },
      {
        name: 'email',
        label: 'Email',
        isRequired: true,
        isDisabled: true,
      },
      {
        name: 'isActivated',
        label: 'isActivated',
        isRequired: false,
        component: CustomSelect,
        options: activatedOptions,
      },
      {
        name: 'isBlocked',
        label: 'isBlocked',
        isRequired: true,
        component: CustomSelect,
        options: blockedOptions,
      },
      {
        name: 'isDeleted',
        label: 'isDeleted',
        isRequired: true,
        component: CustomSelect,
        options: deletedOptions,
      },
      {
        name: 'roles',
        label: 'Roles',
        isRequired: true,
        component: UserRolesMultiSelect,
      },
      {
        name: 'faction',
        label: 'Faction',
        isRequired: false,
        component: CustomSelect,
        options: FactionOptions,
      },
    ],
    creator: [
      { name: 'username', label: 'User name', isRequired: true },
      {
        name: 'email',
        label: 'Email',
        isRequired: true,
        isDisabled: true,
      },
      {
        name: 'isActivated',
        label: 'isActivated',
        isRequired: false,
        component: CustomSelect,
        options: activatedOptions,
      },
      {
        name: 'isBlocked',
        label: 'isBlocked',
        isRequired: true,
        component: CustomSelect,
        options: blockedOptions,
      },
      {
        name: 'isDeleted',
        label: 'isDeleted',
        isRequired: true,
        component: CustomSelect,
        options: deletedOptions,
      },
    ],
    instance: [
      {
        name: 'venueName',
        label: 'Venue',
        isRequired: true,
        isDisabled: true,
      },
      { name: 'slots', label: 'Slots', isRequired: true, type: 'number' },
      { name: 'selfAddressUrl', label: 'Self address URL', isRequired: true },
      { name: 'voipStream', label: 'Voip stream', isRequired: true },
      { name: 'logUrl', label: 'log URL', isRequired: false },
    ],
    avatar: [
      { name: 'name', label: 'Name', isRequired: true },
      {
        name: 'description',
        label: 'Description',
        isRequired: false,
        component: CustomTextEditor,
      },
      {
        name: 'glbFile',
        label: '3D model',
        isRequired: false,
        component: CustomFileUploader,
      },
    ],
    customAvatar: [
      {
        name: 'name',
        label: 'Name',
        isRequired: true,
      },
      {
        name: 'type',
        label: 'Type',
        isRequired: true,
        component: CustomSelect,
        options: avatarTypeOptions,
      },
      {
        name: 'gender',
        label: 'Gender',
        isRequired: true,
        component: CustomSelect,
        options: genderOptions,
      },
      {
        name: 'isActive',
        label: 'Active',
        isRequired: true,
        component: CustomSelect,
        options: activatedOptions,
      },
      {
        name: 'glbFile',
        label: '3D model',
        isRequired: false,
        component: CustomFileUploader,
      },
      {
        name: 'thumbnailFile',
        label: 'Thumbnail image',
        isRequired: false,
        component: CustomFileUploader,
      },
      {
        name: 'description',
        label: 'Description',
        isRequired: false,
        component: CustomTextEditor,
      },
    ],
    assistant: [
      {
        name: 'webglFile',
        label: 'WebGL file',
        isRequired: true,
        component: CustomFileUploader,
      },
    ],
    proposal: [
      {
        name: 'name',
        label: 'Name',
        isRequired: false,
        isDisabled: true,
      },
      {
        name: 'decision',
        label: 'Approvement',
        isRequired: true,
        component: CustomSelect,
        options: proposalDecisionOptions,
      },
      {
        name: 'votingEndsAt',
        label: 'Voting ends at',
        isRequired: true,
        type: 'datetime-local',
      },
      {
        name: 'lifecycleStatus',
        label: 'Status',
        isRequired: false,
        isDisabled: true,
      },
      {
        name: 'faction',
        label: 'Faction',
        isRequired: true,
        component: CustomSelect,
        options: FactionOptions,
      },
      {
        name: 'location',
        label: 'Location',
        isRequired: true,
        component: CustomSelect,
        options: LocationOptions,
      },
      {
        name: 'thumbnailFile',
        label: 'Thumbnail image',
        isRequired: false,
        component: CustomFileUploader,
      },
      {
        name: 'coverFile',
        label: 'Cover image',
        isRequired: false,
        component: CustomFileUploader,
      },
      // {
      //   name: 'gallery',
      //   label: 'Gallery image',
      //   isRequired: false,
      //   component: Gallery,
      // },

      {
        name: 'description',
        label: 'Description',
        isRequired: false,
        component: CustomTextEditor,
      },
    ],
    pool: [
      {
        name: 'proposal.name',
        label: 'Proposal',
        isRequired: true,
        isDisabled: true,
      },
      {
        name: 'periodStart',
        label: 'Start',
        isRequired: true,
        type: 'datetime-local',
        min: moment().add(5, 'minutes').format('YYYY-MM-DDTHH:mm'),
      },
      {
        name: 'periodFinish',
        label: 'Finish',
        isRequired: true,
        type: 'datetime-local',
      },
      {
        name: 'amount',
        label: 'Amount',
        isRequired: true,
        component: CustomBigNumberInput,
        decimals: 16,
      },
      {
        name: 'duration',
        label: 'Lock period (days)',
        isRequired: true,
        type: 'number',
      },
    ],
  };

  return editFields[serviceName];
};
