import { api } from '@/core/api';
import { userPaths } from '@/core/api/api.config';
import { AxiosRequestConfig } from 'axios';

export const userApi = {
  getUserData: (params: any) => api.get(userPaths.userDetailsAdmin, { params }),
  updateUserDetailsAdmin: (body: any, config?: AxiosRequestConfig) => api.put(userPaths.userDetailsAdmin, body, config),
  updateUserIdDetailsAdmin: (body: any, config?: AxiosRequestConfig) =>
    api.put(userPaths.userDetailsAdmin, body, config),
  getUsersByIds: (id: string[]) => {
    return api.get(userPaths.getUsersByIds, { params: { id } });
  },
  getUserItemByid: (params: any) => api.get(userPaths.userDetailsAdmin, { params }),
  getRolesList: (params: any) => api.get(userPaths.getRolesList, { params }),
  createNewUser: (body: any, config?: AxiosRequestConfig) => api.post(userPaths.createUser, body, config),
  bunUser: (body: any, config?: AxiosRequestConfig) => api.put(userPaths.bunUser, body, config),
  unBunUser: (body: any, config?: AxiosRequestConfig) => api.put(userPaths.unbunUser, body, config),
  addUserRole: (body: any, config?: AxiosRequestConfig) => api.patch(userPaths.addUserRole, body, config),
  removeUserRole: (body: any, config?: AxiosRequestConfig) => api.patch(userPaths.removeUserRole, body, config),
};
