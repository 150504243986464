import { formEditFields } from '@/core/components/Edit/settings/formEditFields';
import { formCreateFields } from '@/core/components/Create/settings/formCreateFields';
import { validationSettings } from '@/data/validationSettings';

export const validationOfEditService = (serviceName: string, domainFolderName = '') => {
  if (!serviceName) return null;

  const arrayOfFields = formEditFields(serviceName, domainFolderName);

  const generateValidationObj = arrayOfFields.map((item) => {
    return validationSettings[item.name] || {};
  }); // => [{fieldsValidation.value}, {fieldsValidation.value}]

  const validateThemeArray = arrayOfFields.map((item, index) => {
    if (item.isRequired) {
      return { [item.name]: generateValidationObj[`${index}`].required };
    } else {
      return { [item.name]: generateValidationObj[`${index}`].simple };
    }
  }); // => [{key: validation params}, {key: validation params}]

  const validateSettings = {};

  for (let index = 0; index < validateThemeArray.length; index++) {
    const element = validateThemeArray[index];
    const key = Object.keys(element)[0];
    validateSettings[key] = Object.values(element)[0];
  }

  return validateSettings;
};

export const validationOfCreateService = (serviceName: string) => {
  if (!serviceName) return null;

  const arrayOfFields = formCreateFields(serviceName);
  const generateValidationObj = arrayOfFields?.map((item) => {
    return validationSettings[item.name] || {};
  }); // => [{fieldsValidation.value}, {fieldsValidation.value}]

  const validateThemeArray = arrayOfFields?.map((item, index) => {
    if (item.isRequired) {
      return { [item.name]: generateValidationObj[`${index}`].required };
    } else {
      return { [item.name]: generateValidationObj[`${index}`].simple };
    }
  }); // => [{key: validation params}, {key: validation params}]

  const validateSettings = {};

  for (let index = 0; index < validateThemeArray.length; index++) {
    const element = validateThemeArray[index];
    const key = Object.keys(element)[0];
    validateSettings[key] = Object.values(element)[0];
  }

  return validateSettings;
};
