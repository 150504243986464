import React, { DetailedHTMLProps, HTMLAttributes, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Button } from '@/shared/components/Button';
import { RoleRequestStatus } from '@/data/constants';
import { changeStatusToBeCreator } from '@/modules/BecomeCreatorPage/actions';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

import styles from './RowBecomeCreatorActions.module.scss';

interface RowBecomeCreatorActions extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  userId: string;
  roleId: string;
  requestId: string;
  status: RoleRequestStatus;
  props?: any;
  className?: string;
}

export const RowBecomeCreatorActions = ({
  userId,
  roleId,
  requestId,
  className,
  status,
}: RowBecomeCreatorActions): JSX.Element => {
  const dispatch = useDispatch();

  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleApprove = () => {
    dispatch(changeStatusToBeCreator(requestId, RoleRequestStatus.APPROVED) as any);
    handleClose();
  };

  const handleReject = () => {
    dispatch(changeStatusToBeCreator(requestId, RoleRequestStatus.DECLINED) as any);
    handleClose();
    handleCloseModal();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openConfirmModal = () => {
    setIsOpenedModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenedModal(false);
    handleClose();
  };

  return (
    <div className={cn(styles.tableActions, className)}>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon
          className={`${status !== 'PENDING' ? styles.tableActions__icon_btn_gray : styles.tableActions__icon_btn}`}
        />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        {status === RoleRequestStatus.PENDING ? (
          <div>
            <MenuItem
              className={styles.tableActions__type}
              onClick={handleApprove}
            >
              <HowToRegIcon className={styles.tableActions__icon} />
              Approve
            </MenuItem>

            <MenuItem
              className={styles.tableActions__type}
              onClick={openConfirmModal}
            >
              <DoDisturbIcon className={styles.tableActions__icon} />
              Reject
            </MenuItem>
          </div>
        ) : (
          <MenuItem className={styles.tableActions__type}>No actions</MenuItem>
        )}
      </Menu>

      {isOpenedModal && (
        <Modal
          open={isOpenedModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-delete-item"
          aria-describedby="modal-modal-description"
        >
          <Box className={styles.tableActions__modal}>
            <Typography
              className={styles.tableActions__text}
              id="modal-delete-item"
              variant="h6"
              component="h2"
            >
              Are you sure?
            </Typography>
            <Box className={styles.tableActions__buttons}>
              <Button
                className={styles.tableActions__btn}
                onClick={handleCloseModal}
              >
                No
              </Button>
              <Button
                className={styles.tableActions__btn}
                onClick={handleReject}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};
