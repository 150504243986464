import { Layout } from '@/core/layout';
import { Frame } from '@/shared/components/Frame';
import { Edit } from '@/core/components/Edit';

export const CreatorsEditPage = (): JSX.Element => {
  return (
    <Layout backgroundType="none">
      <Frame>
        <Edit />
      </Frame>
    </Layout>
  );
};
