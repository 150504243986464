import * as web3lib from '@vrjam/vrjam-web3-lib';
import moment from 'moment';

import { ActionTypes } from '../reducers';
import { poolApi } from '@/api';
import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { actionVenueList } from '@/modules/VenuesPage/actions';
import useConnectWallet from '@/core/hooks/useConnectWallet';

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetPoolList = (data: any) => ({
  type: ActionTypes.SET_POOL_LIST,
  payload: data,
});

export const actionSetPoolEditedItem = (data: any) => ({
  type: ActionTypes.SET_POOL_EDIT_ITEM,
  payload: data,
});

export const actionGetPoolList = (filters?: FilterProps) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const response = await poolApi.getPool(filters);

    if (response.data.success) {
      dispatch(actionSetPoolList(response.data.data));
    } else {
      throw response.data.message;
    }
  } catch (e: any) {
    dispatch({ type: ActionTypes.ERROR });
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};

export const actionCreatePoolItem = (body: any) => async (dispatch: TypedDispatch): Promise<any> => {
  try {
    dispatch(setLoading(true));

    const response = await poolApi.createPoolItem({
      ...body,
      duration: body.duration * 86400 // convert days to seconds
    });

    if (response.data.success) {
      notify.success('Pool item created successfully');
      return response.data.data;
    } else {
      throw response.data.message;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;

    console.log(error, ' <<<< ERROR from backend '); // TODO

    notify.error(error);
    throw error;
  } finally {
    dispatch(setLoading(false));
  }
};

export const actionGetPoolItemById = (id: string) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const response = await poolApi.getPoolById(id);

    if (response.data.success) {
      const { data } = response.data;
      dispatch(actionSetPoolEditedItem({
        ...data,
        duration: Math.floor(data.duration / 86400) // convert seconds to days
      }));
    } else {
      dispatch({ type: ActionTypes.ERROR });
      throw response.data.message;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;

    notify.error(error);
    dispatch({ type: ActionTypes.ERROR });
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};

export const actionUpdatePoolItem = (id: string, body: any) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const response = await poolApi.updatePoolItem(
      id,
      {
        ...body,
        duration: body.duration * 86400 // convert days to seconds
      }
    );

    if (response.data.success) {
      notify.success('Pool item updated successfully');
      return response.data.data;
    } else {
      throw response.data.message;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;

    notify.error(error);
    throw error;
  }
};

export const actionClaimPoolItem = (poolAddress: string) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const poolApi = await web3lib.api.venuePool([poolAddress]);
    const account = web3lib.api.defaultAddress;
    await poolApi.claim(poolAddress, account);
    dispatch(actionGetPoolList());
    notify.success('Claimed successfully', 'Success');
  } catch (e: any) {
    console.log('Error in actionClaimPoolItem', e);
    if (e.message.includes('user rejected transaction')) {
      notify.error('User rejected transaction');
    } else {
      notify.error(e.message);
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const actionLockPoolItem = (poolAddress: string, toLock: boolean) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const poolApi = await web3lib.api.venuePool([poolAddress]);
    if (toLock) {
      await poolApi.lock(poolAddress);
      notify.success('Pool locked successfully', 'Success');
    } else {
      await poolApi.unlock(poolAddress);
      notify.success('Pool unlocked successfully', 'Success');
    }
    dispatch(actionVenueList());
  } catch (e: any) {
    console.log('Error in actionLockPoolItem', e);
    if (e.message.includes('user rejected transaction')) {
      notify.error('User rejected transaction');
    } else {
      notify.error(e.message);
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const actionCreatePool = (pool: any) => async (): Promise<void> => {
  try {
    const venuePoolFactory = await web3lib.api.venuePoolFactory();
    const data: web3lib.ICreatePool = {
      id: pool.id,
      cap: pool.amount,
      start: moment(pool.periodStart).unix(),
      end: moment(pool.periodFinish).unix(),
      duration: pool.duration,
    };
    await venuePoolFactory.createPool(data);
  } catch (e) {
    console.log('createPool in web3lib error', e);
    throw e;
  }
};
