import { editFieldsFromData } from '@/core/components/Edit/settings';

export enum ActionTypes {
  LOADING = '@venues/LOADING',
  LOADED = '@venues/LOADED',
  ERROR = '@venues/ERROR',
  SET_VENUE_LIST = '@venues/SET_VENUE_LIST',
  SET_VENUE_EDIT_ITEM = '@venues/SET_VENUE_EDIT_ITEM',
  VENUE_ITEM_MINTED = '@venues/VENUE_ITEM_MINTED'
}

export interface VenueList {
  itemsList: any;
  editedItem: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.LOADED;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_VENUE_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.SET_VENUE_EDIT_ITEM;
      payload: any;
    }
  | {
    type: ActionTypes.VENUE_ITEM_MINTED;
    id: string;
    };

const venuesState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: venuesState,
  editedItem: editFieldsFromData.venue,
  isLoading: false,
  isError: false,
};

export const venueReducer = (
  state: VenueList = initialState,
  action: Action,
): VenueList => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ActionTypes.LOADED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_VENUE_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_VENUE_EDIT_ITEM: {
      return {
        ...state,
        editedItem: action.payload,
        isError: false,
      };
    }

    // case ActionTypes.VENUE_ITEM_MINTED: {
    //   return {
    //     ...state,
    //     itemsList: state.itemsList.map((item: any) => item.id === action.id ? { ...item, isMinted: true} : item)
    //   }
    // }

    default:
      return state;
  }
};
