import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import styles from './MultiSelect.module.scss';

export const MultiSelect = ({ formOptions, permissionsList }: any): JSX.Element | null => {
  const { form, field, label, options, onChange, required } = formOptions;
  const [selectedIds, setSelectedIds] = useState<any>([]);

  useEffect(() => {
    const selectedArray = field.value?.map((item: any) => item?.id);

    setSelectedIds(selectedArray || []);
  }, [field]);

  const handlerValue = () => {
    const valueArray = field.value.map((relation: any) => relation?.name);

    return valueArray.join(', ');
  };

  if (!permissionsList) return null;

  const onChangeHandler = (event: SelectChangeEvent<any>) => {
    const value = event.target.value;

    const valueObjMapping = value.map((valueItem: string) => {
      return permissionsList.find((permitionItem: any) => valueItem === permitionItem.id);
    }); // => [{activeCheckedPermitions}]

    field.onChange(event);
    onChange && onChange(event);
    form.setFieldValue(field.name, valueObjMapping, true);
  };

  return (
    <Box className={styles.select}>
      <FormLabel className={styles.select__label}>
        {label}
        {required && <span> *</span>}
      </FormLabel>
      <Select
        name={field.name}
        multiple
        value={selectedIds}
        onChange={onChangeHandler}
        renderValue={handlerValue}
      >
        {permissionsList?.map((item: any) => (
          <MenuItem
            key={item.id}
            value={item.id}
            className={styles.select__dropdown}
          >
            <ListItemIcon>
              <Checkbox
                size="small"
                checked={!!field.value?.find((relation: any) => relation?.id === item.id)}
              />
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
