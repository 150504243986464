import { api } from '@/core/api';
import { eventPaths } from '@/core/api/api.config';
import { AxiosRequestConfig } from 'axios';

export const eventApi = {
  getEvent: (params: any) => api.get(eventPaths.event, { params }),
  updateEventItem: (id: string, body: any, config?: AxiosRequestConfig) =>
    api.put(eventPaths.eventById(id), body, config),
  createEventItem: (body: any, config?: AxiosRequestConfig) => api.post(eventPaths.event, body, config),
  deleteEventItem: (id: string) => api.delete(eventPaths.eventById(id)),
  getEventItemByid: (id: string) => api.get(eventPaths.eventById(id)),
  setEventRoleToUser: (body: any, config?: AxiosRequestConfig) => api.post(eventPaths.eventSetRoleToUser, body, config),
};
