import { AxiosRequestConfig } from 'axios';
import { api } from '@/core/api';
import { poolPaths } from '@/core/api/api.config';

export const poolApi = {
  getPool: (params: any) => api.get(poolPaths.poolAdmin, { params }),
  getPoolById: (id: string) => api.get(poolPaths.poolById(id)),
  createPoolItem: (body: any, config?: AxiosRequestConfig) => api.post(poolPaths.pool, body, config),
  updatePoolItem: (id: string, body: any, config?: AxiosRequestConfig) => api.put(poolPaths.poolById(id), body, config),
};
