import { api } from '@/core/api';
import { avatarPaths } from '@/core/api/api.config';
import { AxiosRequestConfig } from 'axios';

export const avatarApi = {
  getAvatar: (params: any) => api.get(avatarPaths.avatar, { params }),
  updateAvatarItem: (body: any, config?: AxiosRequestConfig) => api.patch(avatarPaths.avatar, body, config),
  createAvatarItem: (body: any, config?: AxiosRequestConfig) => api.post(avatarPaths.avatar, body, config),
  deleteAvatarItem: (id: string) => api.delete(avatarPaths.avatarById(id)),
  getAvatarItemByid: (id: string) => api.get(avatarPaths.avatarById(id)),
  getDefaultAvatar: (id: string) => api.get(avatarPaths.getDefaultAvatar(id)),
};
