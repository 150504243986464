import { useCallback, useEffect } from 'react';
import * as web3lib from '@vrjam/vrjam-web3-lib';
import { useDispatch } from 'react-redux';

import { web3libAuth } from '@/shared/utils/web3libAuth';
import { useTypedSelector } from '@/core/hooks/useTypedSelector';
import { notify } from '@/shared/components/Notify/Notify';
import { setIsConnected, setIsOpenWalletPopup } from '@/modules/Connect/actions';
import { actionAddWallet } from '@/modules/Auth/actions';

const useConnectWallet = () => {
  const dispatch = useDispatch<any>();
  const { isOpenWalletPopup, isConnectedWallet } = useTypedSelector(({ connectReducer }) => connectReducer);
  const { user } = useTypedSelector(({ authReducer }) => authReducer);

  const connectHandler = useCallback(async () => {
    if (!user?.walletAddress) {
      const store = await web3lib.getStore();
      if (store?.common) {
        await dispatch(actionAddWallet(store.common, store.common.account));
      }
    }
  }, [user]);

  const checkConnectorHandler = useCallback( async () => {
    try {
      const isConnectedApp = await web3lib.checkConnector();
      dispatch(setIsConnected(isConnectedApp));
      const store = await web3lib.getStore();
      if (store?.common && user?.walletAddress && store.common.account !== user.walletAddress) {
        await web3lib.disableConnector();
        throw new Error(`Switch to the address that is equal to your VRJAM account address: ${user.walletAddress}`);
      }
    } catch (e: any) {
      console.log(e);
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      dispatch(setIsConnected(false));
      dispatch(setIsOpenWalletPopup(false));
    }
  }, [user]);

  const connectWalletHandler = useCallback( (connector: any) => async () => {
    try {
      await web3libAuth(user?.walletAddress, connector);
      await connectHandler();
      notify.success('Wallet connected successfully.')
      dispatch(setIsConnected(true));
      dispatch(setIsOpenWalletPopup(false));
    } catch (e: any) {
      console.log('Error in connectWalletHandler', e);
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      await web3lib.disableConnector();
      dispatch(setIsConnected(false));
      dispatch(setIsOpenWalletPopup(false));
    }
  }, [user, checkConnectorHandler, connectHandler]);

  const handleWalletPopup = useCallback(async () => {
    try {
      if (!isConnectedWallet) {
        const isConnectedApp = await web3lib.checkConnector();
        if (isConnectedApp) {
          dispatch(setIsConnected(true));
        } else {
          dispatch(setIsOpenWalletPopup(true));
        }
      }
    } catch (e) {
      console.log('Error in handleWalletPopup', e);
      dispatch(setIsConnected(false));
      dispatch(setIsOpenWalletPopup(true));
    }
  }, [dispatch]);

  return {
    isOpenWalletPopup,
    handleWalletPopup,
    isConnectedWallet,
    connectWalletHandler,
    setIsOpenWalletPopup: (isOpen: boolean) => dispatch(setIsOpenWalletPopup(isOpen)),
    checkConnectorHandler,
  };
};

export default useConnectWallet;
