export enum ActionTypes {
  LOADING = '@becomeCreator/LOADING',
  LOADED = '@becomeCreator/LOADED',
  ERROR = '@becomeCreator/ERROR',
  SET_BECOMECREATORS_LIST = '@becomeCreator/SET_BECOMECREATORS_LIST',
}

export interface BecomeCreatorList {
  itemsList: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_BECOMECREATORS_LIST;
      payload: any;
    };

const becomeCreatorState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: becomeCreatorState,
  isLoading: false,
  isError: false,
};

export const becomeCreatorReducer = (state: BecomeCreatorList = initialState, action: Action): BecomeCreatorList => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }

    case ActionTypes.SET_BECOMECREATORS_LIST: {
      const data = action.payload;
      const showUserName = data.items.map((item: any) => {
        return { username: item.user.username, ...item };
      });
      const newData = { ...data, items: showUserName };

      return {
        ...state,
        itemsList: newData,
        isError: false,
      };
    }

    default:
      return initialState;
  }
};
