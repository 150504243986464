export enum ActionTypes {
  LOADING = '@creator/LOADING',
  ERROR = '@creator/ERROR',
  SET_CREATOR_LIST = '@creator/SET_CREATOR_LIST',
  SET_CREATOR_ROLES_LIST = '@creator/SET_CREATOR_ROLES_LIST',
  SET_CREATOR_EDIT_ITEM = '@creator/SET_CREATOR_EDIT_ITEM',
}

export interface CreatorStateProps {
  itemsList: any;
  editedItem: any;
  rolesList: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_CREATOR_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.SET_CREATOR_EDIT_ITEM;
      payload: any;
    }
  | {
      type: ActionTypes.SET_CREATOR_ROLES_LIST;
      payload: any;
    };

const creatorListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: creatorListState,
  editedItem: null,
  rolesList: [],
  isLoading: false,
  isError: false,
};

export const creatorsReducer = (state: CreatorStateProps = initialState, action: Action): CreatorStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case ActionTypes.SET_CREATOR_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }
    case ActionTypes.SET_CREATOR_ROLES_LIST: {
      return {
        ...state,
        rolesList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_CREATOR_EDIT_ITEM: {
      const editedItemState = action.payload.items[0];

      delete editedItemState['walletAddress'];

      return {
        ...state,
        editedItem: editedItemState,
        isError: false,
      };
    }
    default:
      return state;
  }
};
