import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';

import { Frame } from '@/shared/components/Frame';
import { CustomDatePicker } from '@/shared/components/CustomDatePicker';
import { Layout } from '@/core/layout';
import { fetchAndSetAll } from '@/core/utils/fetch-helpers';
import {
  avatarPaths,
  customAvatarPaths,
  eventPaths,
  instancePaths,
  userPaths,
  venuePaths,
} from '@/core/api/api.config';

import styles from './MainPage.module.scss';

export const MainPage = () => {
  const [userStat, setUserStat] = useState([]);
  const [venuesStat, setVenuesStat] = useState([]);
  const [eventStat, setEventStat] = useState([]);
  const [instanceStat, setInstanceStat] = useState([]);
  const [avatarStat, setAvatarStat] = useState([]);
  const [customAvatarStat, setCustomAvatarStat] = useState([]);

  const [startDate, setStartDate] = useState(moment().add(-1, 'day').format('YYYY-MM-DD')); // get yesturday
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD')); // today

  const enteredUserdata = useSelector(({ authReducer }: any) => authReducer);
  const { isExist } = enteredUserdata;

  const searchDate = {
    from: moment(startDate).format('YYYY-MM-DD'),
    to: moment(endDate).format('YYYY-MM-DD'),
  };

  useEffect(() => {
    fetchAndSetAll([
      {
        url: userPaths.userStat,
        init: searchDate,
        setter: setUserStat,
      },
      {
        url: venuePaths.venueStat,
        init: searchDate,
        setter: setVenuesStat,
      },
      {
        url: eventPaths.eventStat,
        init: searchDate,
        setter: setEventStat,
      },
      {
        url: instancePaths.instanceStat,
        init: searchDate,
        setter: setInstanceStat,
      },
      {
        url: avatarPaths.avatarStat,
        init: searchDate,
        setter: setAvatarStat,
      },
      {
        url: customAvatarPaths.customAvatarStat,
        init: searchDate,
        setter: setCustomAvatarStat,
      },
    ]).catch(console.error);
  }, [isExist, endDate, startDate]);

  const isStringNumber = (value: any) => {
    return typeof value == 'string' || typeof value == 'number';
  };

  const dataTypeDetect = (data: any) => {
    if (Array.isArray(data)) {
      return data.map((item: any, index: number) => {
        return (
          <div
            className={cn(styles.mainPage__list__item, styles.mainPage__list__list, {})}
            key={index}
          >
            <p className={styles.mainPage__list__desc}>{item.name}</p>:{' '}
            <span className={styles.mainPage__list__text}>{item.count}</span>
          </div>
        );
      });
    } else if (typeof data === 'object' && data !== null) {
      return Object.keys(data).map((item: any, index: number) => (
        <div
          className={cn(styles.mainPage__list__list, {
            [styles.mainPage__list__item]: isStringNumber(data[item]),
          })}
          key={index}
        >
          <p className={styles.mainPage__list__desc}>{item}</p>:{' '}
          <div className={styles.mainPage__list__text}>{data[item]}</div>
        </div>
      ));
    } else return data;
  };

  const showData = (data: any) => {
    return Object.keys(data).length ? (
      Object.keys(data).map((item: any, index: number) => (
        <div
          className={cn(styles.mainPage__list__list, {
            [styles.mainPage__list__item]: isStringNumber(data[item]),
          })}
          key={index}
        >
          <p className={styles.mainPage__list__desc}>{item}</p>:{' '}
          <div className={styles.mainPage__list__text}>{dataTypeDetect(data[item])}</div>
        </div>
      ))
    ) : (
      <span className={styles.noData}>NO DATA</span>
    );
  };

  return (
    <Layout backgroundType="none">
      <Frame>
        <h1 className={styles.mainPage__title}>Main Page </h1>
        <div className={styles.mainPage__dates}>
          <CustomDatePicker
            title={'Start date'}
            value={startDate}
            setValue={setStartDate}
          />
          <CustomDatePicker
            title={'End date'}
            value={endDate}
            setValue={setEndDate}
          />
        </div>
        <h5 className={styles.mainPage__subtitle}>User statistic data:</h5>
        <ul className={styles.mainPage__list}>{showData(userStat)}</ul>
        <h5 className={styles.mainPage__subtitle}>Venue statistic data:</h5>
        <ul className={styles.mainPage__list}>{showData(venuesStat)}</ul>
        <h5 className={styles.mainPage__subtitle}>Event statistic data:</h5>
        <ul className={styles.mainPage__list}>{showData(eventStat)}</ul>
        <h5 className={styles.mainPage__subtitle}>Instance statistic data:</h5>
        <ul className={styles.mainPage__list}>{showData(instanceStat)}</ul>
        <h5 className={styles.mainPage__subtitle}>Avatar statistic data:</h5>
        <ul className={styles.mainPage__list}>{showData(avatarStat)}</ul>
        <h5 className={styles.mainPage__subtitle}>Custom avatar statistic data:</h5>
        <ul className={styles.mainPage__list}>{showData(customAvatarStat)}</ul>
      </Frame>
    </Layout>
  );
};
