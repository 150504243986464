import { Dispatch, SetStateAction } from 'react';
import { Pagination as MuiPagination, Stack } from '@mui/material';

import styles from './Pagination.module.scss';

interface MetaProps {
  page: number;
  limit: number;
  total: number;
}

interface PaginationProps {
  meta: MetaProps;
  setNewPage: (page: number) => void;
  activePage: number;
}

export const Pagination = ({ meta, setNewPage, activePage }: PaginationProps): JSX.Element => {
  const { limit, total } = meta;

  const pagesCount = (venueItems: number, itemsPerPage: number) => {
    return Math.ceil(venueItems / itemsPerPage) || 1;
  };

  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="flex-end"
    >
      <MuiPagination
        classes={{ ul: styles.pagination__list }}
        page={activePage}
        count={pagesCount(total, limit)}
        color="primary"
        showFirstButton
        showLastButton
        onChange={(e: any, num: number) => {
          setNewPage(num);
        }}
      />
    </Stack>
  );
};
