export enum ActionTypes {
  LOADING = '@eventrole/LOADING',
  ERROR = '@eventrole/ERROR',
  SET_EVENTROLES_LIST = '@eventrole/SET_EVENTROLES_LIST',
  DELETE_EVENTROLE = '@eventrole/DELETE_EVENTROLE',
  SET_EVENTROLE_EDIT = '@eventrole/SET_EVENTROLE_EDIT',
  SET_EVENTROLES_PERMISSION_LIST = '@eventrole/SET_EVENTROLES_PERMISSION_LIST',
}

export interface EventStateProps {
  itemsList: any;
  editedItem: any;
  permissionsList: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_EVENTROLES_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.DELETE_EVENTROLE;
      payload: any;
    }
  | {
      type: ActionTypes.SET_EVENTROLE_EDIT;
      payload: any;
    }
  | {
      type: ActionTypes.SET_EVENTROLES_PERMISSION_LIST;
      payload: any;
    };

const eventRoleListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: eventRoleListState,
  editedItem: null,
  permissionsList: null,
  isLoading: false,
  isError: false,
};

export const eventRolesReducer = (state: EventStateProps = initialState, action: Action): EventStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_EVENTROLES_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_EVENTROLE_EDIT: {
      return {
        ...state,
        editedItem: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_EVENTROLES_PERMISSION_LIST: {
      return {
        ...state,
        permissionsList: action.payload,
        isError: false,
      };
    }

    default:
      return state;
  }
};
