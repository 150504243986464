import styles from './Frame.module.scss';

interface FrameProps {
  children: React.ReactNode;
  customClass?: string;
}

export const Frame = ({ children, customClass }: FrameProps): JSX.Element => {
  return <div className={`${styles.frame} ${customClass}`}>{children}</div>;
};
