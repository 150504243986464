export enum ActionTypes {
  LOADING = '@user/LOADING',
  ERROR = '@user/ERROR',
  SET_USER_LIST = '@user/SET_USER_LIST',
  SET_USER_ROLES_LIST = '@user/SET_USER_ROLES_LIST',
  SET_USER_EDIT_ITEM = '@user/SET_USER_EDIT_ITEM',
}

export interface UserStateProps {
  itemsList: any;
  editedItem: any;
  rolesList: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_USER_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.SET_USER_EDIT_ITEM;
      payload: any;
    }
  | {
      type: ActionTypes.SET_USER_ROLES_LIST;
      payload: any;
    };

const userListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: userListState,
  editedItem: null,
  rolesList: [],
  isLoading: false,
  isError: false,
};

export const userReducer = (state: UserStateProps = initialState, action: Action): UserStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case ActionTypes.SET_USER_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }
    case ActionTypes.SET_USER_ROLES_LIST: {
      return {
        ...state,
        rolesList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_USER_EDIT_ITEM: {
      const editedItemState = action.payload;

      delete editedItemState['walletAddress'];

      return {
        ...state,
        editedItem: editedItemState,
        isError: false,
      };
    }
    default:
      return state;
  }
};
