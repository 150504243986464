import axios from 'axios';
import { paths } from '@/data/paths';
import { deleteDomainCookie, getCookie } from '@/core/api/cookie';
import { authApi } from '@/api';
import * as web3lib from '@vrjam/vrjam-web3-lib';

export const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    'x-api-key': process.env.REACT_APP_PUBLIC_X_API_KEY as string,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (
      error.response?.status >= 400 &&
      originalRequest.url.includes('auth/refresh')
    ) {
      await web3lib.disableConnector();
      deleteDomainCookie('authIndicator');
      window.location.href = paths.realityjam.login;
      return Promise.reject(error);
    }

    if (error.response?.status !== 401) {
      return Promise.reject(error);
    }

    try {
      const authIndicator = getCookie('authIndicator');
      if (authIndicator?.length) {
        const result = await authApi.loginByRefreshToken()
        if (result.status === 200) {
          return axiosInstance(originalRequest);
        } else {
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default axiosInstance;
