import React from 'react';
import '@google/model-viewer';

import { SpinnerViewer } from './components/SpinnerViewer';

import styles from './ModelViewer.module.scss';

export interface ModelViewerProps
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>, 'ref'> {
  src: string;
  poster?: string;
  alt?: string;
  'ios-src'?: string;
  'seamless-poster'?: boolean;
  'camera-controls'?: boolean;
  'disable-zoom'?: boolean;
  'auto-rotate'?: boolean;
  ar?: boolean;
  autoplay?: boolean;
  'shadow-intensity'?: number | string;
  'ar-scale'?: number | string;
  style?: React.CSSProperties;
  ref?: React.MutableRefObject<ModelViewerProps>;
  toBlob?: ({ mimeType, idealAspect }: { mimeType?: string; idealAspect?: boolean }) => Promise<Blob>;
  toDataURL?: (type?: string) => string;
  addEventListener?: any;
  removeEventListener?: any;
}

// Add model-viewer to the IntrinsicElements list
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': ModelViewerProps;
    }
  }
}

export const ModelViewer: any = ({ glbUrl, thumbnailUrl = null, disableZoom = false, modelRef }: any) => {
  return (
    <div className={styles.viewer}>
      <model-viewer
        ref={modelRef}
        src={glbUrl}
        alt="3D model"
        shadow-intensity="1"
        seamless-poster
        camera-controls
        interaction-prompt={disableZoom ? 'when-focused' : 'auto'}
        poster={thumbnailUrl ? thumbnailUrl : null}
        {...(disableZoom && { 'disable-zoom': true })}
      >
        {thumbnailUrl ? (
          <></>
        ) : (
          <div
            slot="poster"
            className={styles.viewer__poster}
          >
            <SpinnerViewer />
          </div>
        )}
      </model-viewer>
    </div>
  );
};

export default ModelViewer;
