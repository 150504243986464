import { api } from '@/core/api';
import { customAvatarPaths } from '@/core/api/api.config';
import { AxiosRequestConfig } from 'axios';

export const customAvatarApi = {
  getCustomAvatar: (params: any) => api.get(customAvatarPaths.customAvatar, { params }),
  updateCustomAvatarItem: (body: any, config?: AxiosRequestConfig) =>
    api.put(customAvatarPaths.customAvatar, body, config),
  createCustomAvatarItem: (body: any, config?: AxiosRequestConfig) =>
    api.post(customAvatarPaths.customAvatar, body, config),
  deleteCustomAvatarItem: (id: string) => api.delete(customAvatarPaths.customAvatarById(id)),
  getCustomAvatarByid: (id: string) => api.get(customAvatarPaths.customAvatarById(id)),
  assignCustomAvatar: (body: any) => api.post(customAvatarPaths.assignAvatar, body),
};
