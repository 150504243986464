import styles from './Error.module.scss';

export const Error = () => {
  return (
    <div className={styles.error}>
      <h3 className={styles.error__title}>Error:</h3>
      <p className={styles.error__description}>Check your Internet connection or сontact with developers</p>
    </div>
  );
};
