import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { creatorsApi } from '@/api/creators/creators';
import { RoleRequestStatus } from '@/data/constants';
import { ActionTypes } from '../reducers';

export const setLoading = (loading: boolean) => ({ type: ActionTypes.LOADING, payload: loading });

export const actionSetBecomeCreatorList = (data: any) => ({ type: ActionTypes.SET_BECOMECREATORS_LIST, payload: data });

export const actionBecomeCreatorList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await creatorsApi.getRequests(filters);

      if (response.data.success) {
        dispatch(actionSetBecomeCreatorList(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const changeStatusToBeCreator =
  (requestId: string, status: RoleRequestStatus) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await creatorsApi.changeRequestStatus(requestId, { status });

      if (response.data.success) {
        notify.success(`Creator ${status.toLowerCase()}`);
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(actionBecomeCreatorList());
    }
  };
