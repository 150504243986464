import {useState} from 'react';
import {Box, FormLabel} from '@mui/material';
import {FieldInputProps, FormikProps} from 'formik';

import {SearchUser} from '@/shared/components/EditEventRole/containers/SearchUser';

import styles from './SetUserId.module.scss';

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
}

export interface OptionsProps {
  label: string;
  value: string;
}

export const SetUserId = (props: any): JSX.Element => {
  const {form, field} = props;

  const [user, setUser] = useState<any>(null);

  const setUserHandler = (value: any) => {
    setUser(value);
    form.setFieldValue('userId', value?.id || '');
  };

  return (
    <Box className={styles.setUserId}>
      <FormLabel className={styles.setUserId__label}>
        Find user
        <span> *</span>
      </FormLabel>
      <SearchUser
        user={user}
        setUser={setUserHandler}
      />
      {Boolean(form.errors[field.name]) && (
        <div className={styles.setUserId__error}>{form.errors[field.name]}</div>
      )}
    </Box>
  );
};
