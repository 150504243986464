export * from './createFieldNames';
export * from './formCreateFields';

// TODO !!!
export const editTypes = {
  VENUE_CREATE: 'venue',
};

export const editSettings = {
  [editTypes.VENUE_CREATE]: {
    name: '',
    poolId: undefined,
    sceneId: '',
    metaUrl: '', // (URL),
    description: '', // ? not required
    thumbnailFile: null, // File,
    coverFile: null, // File,
  },
  some: [{ name: 'name', required: true, fieldType: null }],
};
