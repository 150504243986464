import { Layout } from '@/core/layout';
import { Frame } from '@/shared/components/Frame';
import { Create } from '@/core/components/Create';

export const InstanceCreatePage = (): JSX.Element => {
  return (
    <Layout backgroundType="none">
      <Frame>
        <Create />
      </Frame>
    </Layout>
  );
};
