import { FieldInputProps, FormikProps, FormikValues } from 'formik';
import { useLocation } from 'react-router-dom';
import { FormLabel } from '@mui/material';
import { Box } from '@mui/system';
import { ModelViewer } from '@/shared/components/ModelViewer';
import { serviceNameChecker } from '@/core/utils/serviceNameChecker';
import { normalizeFileUrl } from '@/core/utils/fileStorageHandler';

import styles from './CustomFileUploader.module.scss';

export interface OptionsProps {
  label: string;
  value: string;
}

export interface InputProps {
  form: FormikProps<FormikValues>;
  field: FieldInputProps<any>;
  label: string;
  options: OptionsProps[];
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
  disabled?: boolean;
}

export const CustomFileUploader = (props: InputProps): JSX.Element => {
  const { form, field, label, options, onChange, required, disabled } = props;

  const currentPath = useLocation();
  const moduleName = currentPath.pathname.split('/')[1];
  const service = serviceNameChecker(moduleName);

  const compareFile = (values: FormikValues, key: string) => {
    if (values[key]) {
      return values[key][1];
    }
    switch (key) {
      case 'glbFile':
        return normalizeFileUrl(values['glbLocation']);
      case 'thumbnailFile':
        return normalizeFileUrl(values['thumbnailLocation']);
      case 'coverFile':
        return normalizeFileUrl(values['coverLocation']);
      case 'webglFile':
        return normalizeFileUrl(values['webglLocation']);
      default:
        return values[key][1];
    }
  };

  const onChangeHandler = (value: [File, string]) => {
    form.setFieldValue(field.name, value);
    onChange && onChange(value);
    form.setFieldTouched(field.name, true, false);
  };

  const hideFileUploader = service !== 'avatar';

  const checkExistImages = (value: string) => {
    const checker = {
      glbFile: normalizeFileUrl(form.values['glbLocation']),
      thumbnailFile: normalizeFileUrl(form.values['thumbnailLocation']),
      coverFile: normalizeFileUrl(form.values['coverLocation']),
      webglFile: normalizeFileUrl(form.values['webglLocation']),
    };

    if (form.values[value] || checker[value]) {
      return true;
    }
  };

  return (
    <Box className={styles.uploader}>
      <FormLabel className={styles.uploader__label}>
        {label}
        {required && <span> *</span>}
      </FormLabel>
      <div className={styles.uploader__row_dashed}>
        {(hideFileUploader && !disabled) && (
          <input
            name={field.name}
            id={field.name}
            onChange={({ currentTarget }: any) => {
              const file = currentTarget.files[0];
              const preview = URL.createObjectURL(file);
              const reader = new FileReader();

              if (file) {
                reader.onloadend = () => {
                  // form.setFieldValue(field.name, [file, preview]);
                  onChangeHandler([file, preview]);
                };
                reader.readAsBinaryString(file);
              }
            }}
            type="file"
            accept={field.name === 'glbFile' ? '.glb' : field.name === 'webglFile' ? '.zip,.rar,.7zip,.tar.gz' : 'image/*'}
          />
        )}
        {form.touched[field.name] && Boolean(form.errors[field.name]) && (
          <div className={styles.uploader__error}>{form.errors[field.name] as string}</div>
        )}

        {checkExistImages(field.name) &&
          (field.name === 'glbFile' ? (
            <ModelViewer glbUrl={compareFile(form.values, field.name)} />
          ) : field.name !== 'webglFile' && (
            <img
              className={styles.uploader__image}
              src={compareFile(form.values, field.name)}
              alt={field.name}
            />
          ))}
      </div>
    </Box>
  );
};
