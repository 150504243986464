import { authApi, avatarApi } from '@/api';
import { TypedDispatch } from '@/core/models';
import { store } from '@/core/store';
import { notify } from '@/shared/components/Notify/Notify';
import { ActionTypes } from '../reducers';
import * as web3lib from '@vrjam/vrjam-web3-lib';

const isAdmin = (roles: any) => {
  const indexRole = roles?.findIndex((role: any) => role.name === 'SUPER_ADMIN');
  return indexRole >= 0;
};

export const actionCheckSession = (logoutCallback?: () => any) => async (dispatch: TypedDispatch) => {
  try {
    dispatch({ type: ActionTypes.START_LOADING });
    const profile = await authApi.getProfile();

    if (isAdmin(profile.data?.data?.roles)) {
      dispatch({ type: ActionTypes.PROFILE, payload: profile.data });
      dispatch(getDefaultAvatar());
    } else {
      throw new Error('Not admin user');
    }
  } catch (error) {
    console.log(error);

    dispatch({ type: ActionTypes.LOGOUT });
    if (logoutCallback) {
      await logoutCallback();
    }
  } finally {
    dispatch({ type: ActionTypes.FINISH_LOADING });
  }
};

export const actionNativeLogout = () => async (dispatch: TypedDispatch) => {
  try {
    const res = await authApi.nativeLogout();
    if (res.data.success) {
      await web3lib.disableConnector();
      dispatch({ type: ActionTypes.LOGOUT });
      notify.success('You successfully logged out');
    }
  } catch (e) {
    dispatch({ type: ActionTypes.LOGOUT });
  }
};

export const getDefaultAvatar = () => async (dispatch: TypedDispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_DEFAULT_AVATAR, payload: true });
    const { id } = store.getState().authReducer.user;
    const response = await avatarApi.getDefaultAvatar(id);
    if (response.data.success) {
      dispatch({ type: ActionTypes.SET_DEFAULT_AVATAR, payload: response.data.data });
    }
  } catch (_) {
    // ignore
  } finally {
    dispatch({ type: ActionTypes.LOADING_DEFAULT_AVATAR, payload: false });
  }
};

export const actionAddWallet = (common: any, walletAddress: string) => async (dispatch: TypedDispatch) => {
  const response = await authApi.updateProfile({ walletAddress });
  if (response.data.success) {
    const profile = await authApi.getProfile();
    dispatch({ type: ActionTypes.PROFILE, payload: profile.data });
  }
}
