import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { ActionTypes } from '../reducers';
import { avatarApi } from '@/api';
import { userApi } from '@/api/user';

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetAvatarList = (data: any) => ({
  type: ActionTypes.SET_AVATAR_LIST,
  payload: data,
});

export const actionSetAvatarEditedItem = (data: any) => ({
  type: ActionTypes.SET_AVATAR_EDIT_ITEM,
  payload: data,
});

export const actionCreatorsLists = (data: any) => ({
  type: ActionTypes.SET_AVATAR_CREATORS_LIST,
  payload: data,
});

export const actionAvatarList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await avatarApi.getAvatar(filters);

      if (response.data.success) {
        const userIds: string[] = [];
        const avatarList = response.data.data;

        avatarList.items &&
          avatarList.items.forEach((item: any) => {
            if (item.ownerId) {
              userIds.push(item.ownerId);
            }
          });

        if (userIds.length) {
          const usersResponse = await userApi.getUsersByIds(userIds);

          if (usersResponse.data.success) {
            const userList = usersResponse.data.data;
            avatarList.items.forEach((item: any) => {
              if (item.ownerId) {
                const userIndex = userList.findIndex(
                  (user: { id: string; username: string }) => item.ownerId === user.id,
                );
                item.ownerName = userList[userIndex]?.username;
              }
            });
          }
        }

        dispatch(actionSetAvatarList(avatarList));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateAvatarItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));

      const response = await avatarApi.updateAvatarItem(body);

      if (response.data.success) {
        notify.success('Avatar item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteAvatar = async (id: string): Promise<void> => {
  try {
    const response = await avatarApi.deleteAvatarItem(id);

    if (response.status === 204) {
      notify.success('Avatar item deleted successfully');
    } else {
      const error = response?.data?.message || response.message;

      throw error;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  }
};

export const actionGetAvatarItemById =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await avatarApi.getAvatarItemByid(body);

      if (response.data.success) {
        dispatch(actionSetAvatarEditedItem(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionCreateAvatarItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response = await avatarApi.createAvatarItem(body, config);

      if (response.data.success) {
        notify.success('Avatar item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetCreatorsList =
  (userIds: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.getUsersByIds(userIds);

      if (response.data.success) {
        dispatch(actionCreatorsLists(response.data.data));
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };
