import { api } from '@/core/api';
import { authPaths } from '@/core/api/api.config';

export const authApi = {
  nativeLogin: (body: any) => api.post(authPaths.login, body),

  nativeLogout: () => api.post(authPaths.logout, {}),

  loginByRefreshToken: () => api.post(authPaths.refreshToken, {}),

  getProfile: () => api.get(authPaths.getProfile),

  updateProfile: (data: any) => api.put(authPaths.user, data),
};
