import { DetailedHTMLProps, HTMLAttributes, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { Menu, MenuItem, IconButton, Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Button } from '@/shared/components/Button';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import styles from './RowAssistantActions.module.scss';
import { actionAssistantList, deleteAssistant } from '@/modules/AssistantsPage/actions';

interface RowActionsProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  itemId: string;
  isMinted?: boolean;
  props?: any;
  className?: string;
}

export const RowAssistantActions = ({ itemId, isMinted, className }: RowActionsProps): JSX.Element => {
  const dispatch = useDispatch<any>();
  const currentPath = useLocation();
  const moduleName = currentPath.pathname.split('/')[1];

  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const deleteAction = async () => {
    await deleteAssistant(itemId);
    await dispatch(actionAssistantList() as any);

    handleClose();
  };

  const openConfirmModal = () => setIsOpenedModal(true);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setIsOpenedModal(false);
    handleClose();
  };

  return (
    <div className={cn(styles.tableActions, className)}>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon className={styles.tableActions__icon_btn} />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        <MenuItem className={styles.tableActions__type}>
          <Link
            to={`/${moduleName}/${itemId}`}
            className={styles.tableActions__link}
          >
            <ViewIcon className={styles.tableActions__icon} />
            Manage
          </Link>
        </MenuItem>
        <MenuItem className={styles.tableActions__type}>
          <Link
            to={`/${moduleName}/${itemId}/interactions`}
            className={styles.tableActions__link}
          >
            <EditIcon className={styles.tableActions__icon} />
            Interactions
          </Link>
        </MenuItem>
        <MenuItem
          className={styles.tableActions__type}
          onClick={openConfirmModal}
        >
          <DeleteIcon className={styles.tableActions__icon} />
          Delete
        </MenuItem>
      </Menu>
      {isOpenedModal && (
        <Modal
          open={isOpenedModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-delete-item"
          aria-describedby="modal-modal-description"
        >
          <Box className={styles.tableActions__modal}>
            <Typography
              className={styles.tableActions__text}
              id="modal-delete-item"
              variant="h6"
              component="h2"
            >
              Are you sure?
            </Typography>
            <Box className={styles.tableActions__buttons}>
              <Button
                className={styles.tableActions__btn}
                onClick={handleCloseModal}
              >
                No
              </Button>
              <Button
                className={styles.tableActions__btn}
                onClick={deleteAction}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};
