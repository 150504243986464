import { createTheme, Theme, ThemeOptions } from '@mui/material';

const mainThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#01E0ED',
    },
    success: {
      main: '#24C38E',
    },
    warning: {
      main: '#F38B01',
    },
    error: {
      main: '#FF4443',
    },
    info: {
      main: 'rgba(253, 253, 253, 0.1)',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Roboto, sans-serif',
    allVariants: {
      color: '#fff',
    },
  },

  // shadows: [] as unknown as Theme['shadows'],
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#8b8b8b !important',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#181818', //$color-bg
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: '#3e3e3e',
          color: '#ffffff',
          border: 'none',
        },
      },
    },
  },
};

export const mainTheme = createTheme(mainThemeOptions);
