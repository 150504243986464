import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { paths } from '@/data/paths';
import { Header } from '@/core/containers/Header';
import { Footer } from '@/core/containers/Footer';
import { Sidebar } from '@/core/containers/Sidebar';
import { Wrapper } from '@/core/components/Wrapper';
import { getCookie } from '@/core/api/cookie';
import { actionCheckSession } from '@/modules/Auth/actions';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: ReactNode;
  withoutWrapper?: boolean;
  backgroundType?: 'primary' | 'secondary' | 'none';
}

const backgroundStyles = {
  primary: `${styles.layout_bg} ${styles.layout_bg_primary}`,
  secondary: `${styles.layout_bg} ${styles.layout_bg_secondary}`,
  none: '',
};

export const Layout = ({ children, withoutWrapper, backgroundType = 'primary' }: LayoutProps): JSX.Element | null => {
  const dispatch = useDispatch<any>();
  const data = useSelector(({ authReducer }: any) => authReducer);

  useEffect(() => {
    if (getCookie('authIndicator')) {
      if (!data.isExist) {
        dispatch(actionCheckSession(logoutCallback));
      }
    } else {
      logoutCallback();
    }
  }, []);

  const logoutCallback = () => (window.location.href = paths.realityjam.login);

  if (!data.user) return null;

  return (
    <Box className={`${styles.layout} ${backgroundStyles[backgroundType]}`}>
      <Header />
      <div className={styles.layout__content}>
        <Sidebar />
        {withoutWrapper ? <>{children}</> : <Wrapper>{children}</Wrapper>}
      </div>
      <Footer />
    </Box>
  );
};
