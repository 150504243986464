import { useEffect } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import useGetServiceData from '@/core/hooks/useGetServiceData';
import { actionGetRolePermissionsList } from '@/modules/EventRolesPage/actions';
import { MultiSelect } from '@/shared/components/MultiSelect';

import styles from './EventRolesMultiSelect.module.scss';

export interface OptionsProps {
  label: string;
  value: string;
}

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  options?: OptionsProps[];
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
}

export const EventRolesMultiSelect = (props: InputProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const serviceData = useGetServiceData('eventRole');
  const { permissionsList } = serviceData;

  useEffect(() => {
    dispatch(actionGetRolePermissionsList() as any);
  }, []);

  if (!permissionsList) return null;

  return (
    <MultiSelect
      formOptions={props}
      permissionsList={permissionsList}
    />
  );
};
