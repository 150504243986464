import { api } from '@/core/api';
import { eventRolePaths } from '@/core/api/api.config';
import { AxiosRequestConfig } from 'axios';

export const eventRoleApi = {
  getEventRole: (params?: any) => api.get(eventRolePaths.eventRole, { params }),
  getEventRoleById: (id: any) => api.get(eventRolePaths.eventRoleById(id)),
  getEventRolePermission: (params?: any) => api.get(eventRolePaths.permission, { params }),
  createEventRoleItem: (body: any, config?: AxiosRequestConfig) => api.post(eventRolePaths.eventRole, body, config),
  deleteEventRoleItem: (id: any) => api.delete(eventRolePaths.eventRoleDelete(id)),
  updateEventRoleItem: (body: any, config?: AxiosRequestConfig) => api.put(eventRolePaths.eventRole, body),
};
