import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';

import styles from './TableHead.module.scss';

interface TableHeadProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

export const TableHead = ({ children, className, ...props }: TableHeadProps): JSX.Element => {
  return (
    <div className={cn(styles.tableHead, className)} {...props}>
      {children}
    </div>
  );
};
