import * as React from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { MenuItem, Select, SelectChangeEvent, FormLabel } from '@mui/material';
import { Box } from '@mui/system';

import styles from './CustomSelect.module.scss';

export interface OptionsProps {
  label: string;
  value: string;
}

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  label: string;
  options: OptionsProps[];
  onChange?: any;
  onBlur?: any | undefined;
  id?: string;
  required?: boolean;
  disabled?: boolean;
}

export const CustomSelect = (props: InputProps): JSX.Element => {
  const { form, field, label, options, onChange, required, disabled } = props;

  const onChangeHandler = (event: SelectChangeEvent<any>) => {
    field.onChange(event);
    onChange && onChange(event);
    form.setFieldTouched(field.name, true, false);
  };

  return (
    <Box className={styles.select}>
      <FormLabel className={styles.select__label}>
        {label}
        {required && <span> *</span>}
      </FormLabel>
      <Select
        name={field.name}
        value={field.value}
        onChange={onChangeHandler}
        disabled={disabled}
      >
        {options.map((item: OptionsProps) => (
          <MenuItem
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {form.touched[field.name] && Boolean(form.errors[field.name]) && (
        <div className={styles.select__error}>{form.errors[field.name]}</div>
      )}
    </Box>
  );
};
