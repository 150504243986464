import { DetailedHTMLProps, HTMLAttributes, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import * as web3lib from '@vrjam/vrjam-web3-lib';

import { Menu, MenuItem, IconButton } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LockIcon from '@mui/icons-material/Lock';

import {
  actionGetPoolList,
  actionClaimPoolItem,
  actionLockPoolItem,
  actionCreatePool
} from '@/modules/PoolPage/actions';
import useConnectWallet from '@/core/hooks/useConnectWallet';
import { notify } from '@/shared/components/Notify/Notify';

import styles from './RowPoolActions.module.scss';

interface RowActionsProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  pool: any;
  className?: string;
}

export const RowPoolActions = ({ pool, className }: RowActionsProps): JSX.Element => {
  const dispatch = useDispatch<any>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(null);
  const [isPoolBalance, setIsPoolBalance] = useState(false);
  const open = Boolean(anchorEl);
  const { isConnectedWallet, handleWalletPopup } = useConnectWallet();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreatePool = async () => {
    if (!isConnectedWallet) {
      await handleWalletPopup();
    } else {
      try {
        await dispatch(actionCreatePool(pool));
        notify.success('Transaction for pool creation sent successfully!');
        dispatch(actionGetPoolList());
        handleClose();
      } catch (e: any) {
        console.log('createPool in web3lib error', e);
        const error = e?.response?.data?.message || e?.message || e;
        notify.error(error);
        throw e;
      }
    }
  };

  const claimHandler = useCallback(async () => {
    if (!isConnectedWallet) {
      await handleWalletPopup();
    } else {
      dispatch(actionClaimPoolItem(pool.address));
    }
  }, [isConnectedWallet, handleWalletPopup, pool]);

  const isPoolFinished = useMemo(() => {
    return moment().isAfter(moment(pool.periodFinish).add(pool.duration, 'seconds'));
  }, [pool]);

  const checkPoolBalance = useCallback(async () => {
    const networkId = process.env.REACT_APP_DEFAULT_NETWORK_ID as web3lib.CHAIN;
    const publicApi = new web3lib.PublicApi(networkId);
    const poolApi = await publicApi.venuePool([pool.address]);
    const balance = await poolApi.balance(pool.address);
    setIsPoolBalance(!Number(balance));
  }, [pool]);

  useEffect(() => {
    checkPoolBalance();
  }, [checkPoolBalance]);

  const unlockPoolHandler = useCallback(async () => {
    if (!isConnectedWallet) {
      await handleWalletPopup();
    } else {
      dispatch(actionLockPoolItem(pool.address, false));
    }
  }, [isConnectedWallet, handleWalletPopup, pool]);

  return (
    <div className={cn(styles.tableActions, className)}>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon className={styles.tableActions__icon_btn} />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        {pool.lifecycleStatus === 'PENDING' ? (
          <div>
            <MenuItem
              className={styles.tableActions__type}
              onClick={handleCreatePool}
              disabled={!pool.address}
            >
              <CreateIcon className={styles.tableActions__icon} />
              Create in blockchain
            </MenuItem>
            <MenuItem className={styles.tableActions__type}>
              <Link
                to={`/pools/${pool.id}`}
                className={styles.tableActions__link}
              >
                <EditIcon className={styles.tableActions__icon} />
                Edit
              </Link>
            </MenuItem>
          </div>
        ) : (
          <div>
            {pool.lifecycleStatus === 'TO_DEFEATED' && (
              <MenuItem
                className={styles.tableActions__type}
                onClick={unlockPoolHandler}
              >
                <LockIcon className={styles.tableActions__icon} />
                Unlock Pool
              </MenuItem>
            )}
            {pool.lifecycleStatus === 'SUCCEED' && isPoolFinished && (
              <MenuItem
                className={styles.tableActions__type}
                onClick={claimHandler}
                disabled={isPoolBalance}
              >
                <MonetizationOnIcon className={styles.tableActions__icon} />
                Claim
              </MenuItem>
            )}
            <MenuItem className={styles.tableActions__type}>
              <a
                href={`${process.env.REACT_APP_PUBLIC_USER_DASHBOARD}/vrjam/pools/${pool.id}`}
                target="_blank"
                className={styles.tableActions__link}
                rel="noreferrer"
              >
                <VisibilityIcon className={styles.tableActions__icon} />
                View
              </a>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};
