import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik, FormikValues } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import * as web3lib from '@vrjam/vrjam-web3-lib';

import { actionCreateVenueItem } from '@/modules/VenuesPage/actions';
import { actionCreateEventItem } from '@/modules/EventsPage/actions';
import { actionCreateNewUser } from '@/modules/UsersPage/actions';
import { actionCreateInstanceItem } from '@/modules/InstancePage/actions';
import { actionCreateEvenRoleItem } from '@/modules/EventRolesPage/actions';
import { actionCreateCustomAvatarItem } from '@/modules/CustomAvatarsPage/actions';
import { actionCreatePool, actionCreatePoolItem } from '@/modules/PoolPage/actions';

import { createFieldNames, formCreateFields } from './settings';
import { serviceNameChecker } from '@/core/utils/serviceNameChecker';
import { validationOfCreateService } from '@/core/utils/validationOfService';
import { correctServiceName } from '@/core/utils/correctServiceName';
import useConnectWallet from '@/core/hooks/useConnectWallet';
import { Button } from '@/shared/components/Button';
import { CustomInput } from '@/shared/components/CustomInput';
import { notify } from '@/shared/components/Notify/Notify';

import styles from './Create.module.scss';

export const Create = () => {
  const dispatch = useDispatch<any>();
  const currentPath = useLocation();
  const navigate = useNavigate();
  const { isConnectedWallet, handleWalletPopup } = useConnectWallet();

  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
  const [serviceName, setServiceName] = useState<string>('');
  const [moduleName, setModuleName] = useState('');
  const [createdPool, setCreatedPool] = useState();
  const service = serviceNameChecker(moduleName);

  useEffect(() => {
    const moduleName = currentPath.pathname.split('/')[1];
    const service = serviceNameChecker(moduleName);

    setServiceName(service);
    setModuleName(moduleName);
  }, []);

  const createFormData = (values: FormikValues) => {
    const bodyFormData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      if (value && key !== 'glbFile' && key !== 'thumbnailFile' && key !== 'coverFile') {
        bodyFormData.append(key, value);
      }
    }
    values.thumbnailFile && bodyFormData.append('thumbnailFile', values.thumbnailFile[0]);
    values.coverFile && bodyFormData.append('coverFile', values.coverFile[0]);
    values.glbFile && bodyFormData.append('glbFile', values.glbFile[0]);
    return bodyFormData;
  };

  const sendData = useCallback(async (body: any, values?: any) => {
    try {
      let newPool = createdPool;
      switch (serviceName) {
        case 'venue':
          await dispatch(actionCreateVenueItem(body) as any);
          break;
        case 'event':
          await dispatch(actionCreateEventItem(body, values) as any);
          break;
        case 'eventRole':
          await dispatch(actionCreateEvenRoleItem(body) as any);
          break;
        case 'user':
          await dispatch(actionCreateNewUser(body) as any);
          break;
        case 'instance':
          await dispatch(actionCreateInstanceItem(body) as any);
          break;
        case 'customAvatar':
          await dispatch(actionCreateCustomAvatarItem(body) as any);
          break;
        case 'pool':
          if (!web3lib.isZeroRemindOfDivWithDecimals(body.amount, 100)) {
            throw Error('Amount is invalid');
          }
          if (!isConnectedWallet) {
            await handleWalletPopup();
          } else {
            if (!createdPool) {
              newPool = await dispatch(actionCreatePoolItem(body) as any);
            }
            setCreatedPool(newPool);
            await dispatch(actionCreatePool(newPool));
          }
          break;
      }
      navigate(`/${moduleName}`);
      setIsLoadingCreate(false);
    } catch (e: any) {
      console.warn(e);
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      setIsLoadingCreate(false);
    }
  }, [dispatch, createdPool, serviceName, moduleName, isConnectedWallet, handleWalletPopup]);

  // validation
  const validationSettings = validationOfCreateService(serviceName);
  const validationParams = yup.object().shape({ ...validationSettings });

  const submitHandler = useCallback((values: FormikValues) => {
    setIsLoadingCreate(true);
    const pullData = {...values};
    for (const [key, value] of Object.entries(pullData)) {
      if (key === 'startAt' || key === 'finishAt' || key === 'periodStart' || key === 'periodFinish') {
        pullData[key] = moment(value).utc().format();
      }
    }

    const bodyFormData = createFormData(pullData);

    switch (serviceName) {
      case 'user':
      case 'avatar':
      case 'instance':
      case 'eventRole':
      case 'pool':
        return sendData(pullData);
      case 'event':
        return sendData(bodyFormData, pullData);
      default:
        return sendData(bodyFormData);
    }
  }, [sendData, serviceName]);

  if (!serviceName) return null;

  return (
    <div className={styles.create}>
      <h2 className={styles.create__title}>CREATE NEW {`${correctServiceName(service).toUpperCase()}`}</h2>
      <Formik
        initialValues={createFieldNames[`${serviceName}`]}
        validationSchema={validationParams}
        onSubmit={submitHandler}
      >
        {({ values, touched, errors, setFieldValue, handleBlur, initialValues }) => (
          <Form className={styles.create__form}>
            {formCreateFields(serviceName)?.map((item: any) => (
              <div
                key={item.name}
                className={styles.create__row}
              >
                <Field
                  name={item.name}
                  label={item.label}
                  options={item.options}
                  component={item.component || CustomInput}
                  decimals={item.decimals}
                  required={item.isRequired}
                  type={item.type}
                  disabled={!!createdPool || item.isDisabled}
                  InputProps={{
                    inputProps: { min: item.min || 0 },
                  }}
                />
              </div>
            ))}

            <Button
              className={styles.create__btn}
              variant="contained"
              type="submit"
              loading={isLoadingCreate}
            >
              {createdPool ? 'Initiate transaction' : 'Submit'}
            </Button>
          </Form>
        )}
      </Formik>
      <Link
        to={`/${moduleName}`}
        className={styles.create__link}
        color="inherit"
        type="button"
      >
        Go to {correctServiceName(service)} page
      </Link>
    </div>
  );
};
