import { paths } from './paths';

export const mainNavigation = {
  links: [],

  dropdowns: [
    {
      label: 'Marketplace',
      options: [
        {
          label: 'Home',
          path: paths.marketplace.home,
        },
        {
          label: 'NFT Marketplace',
          path: paths.marketplace.nftListings,
        },
        {
          label: 'Venues',
          path: paths.marketplace.venues,
        },

        {
          label: 'Staking',
          path: paths.marketplace.staking,
        },
        {
          label: 'Vesting',
          path: paths.marketplace.vesting,
        },
        {
          label: 'Get in touch',
          path: paths.marketplace.getInTouch,
        },
      ],
    },

    {
      label: 'VRJAM',
      options: [
        {
          label: 'Home',
          path: paths.user.home,
        },
        {
          label: 'Create Avatar',
          path: paths.realityjam.createAvatar,
        },
        {
          label: 'Avatar Gallery',
          path: paths.user.avatarGallery,
        },
        {
          label: 'My NFTs',
          path: paths.user.myNFTs,
        },
        {
          label: 'Transactions',
          path: paths.user.transactions,
        },
        {
          label: 'Guilds',
          path: paths.user.guilds,
        },
        {
          label: 'Events',
          path: paths.user.events,
        },
        {
          label: 'Venues',
          path: paths.user.venues,
        },
        {
          label: 'Become a Creator',
          path: paths.user.becomeCreator,
        },
      ],
    },

    {
      label: 'AIJAM',
      options: [
        {
          label: 'Home',
          path: paths.aijam.home,
        },
        {
          label: 'Create Avatar',
          path: paths.realityjam.createAvatar,
        },
        {
          label: 'Avatar Gallery',
          path: paths.aijam.avatarGallery,
        },
        {
          label: 'My NFTs',
          path: paths.aijam.myNFTs,
        },
        {
          label: 'AI assistant Gallery',
          path: paths.aijam.assistantGallery,
        },
      ],
    },

    {
      label: 'Admin',
      options: [
        {
          label: 'Home',
          path: paths.admin.home,
        },
        {
          label: 'Venues',
          path: paths.admin.venues,
        },
        {
          label: 'Events',
          path: paths.admin.events,
        },
        {
          label: 'Event roles',
          path: paths.admin.eventRoles,
        },
        {
          label: 'Users',
          path: paths.admin.users,
        },
        {
          label: 'Creators',
          path: paths.admin.creators,
        },
        {
          label: 'Instances',
          path: paths.admin.instances,
        },
        {
          label: 'Avatars',
          path: paths.admin.avatars,
        },
        {
          label: 'Custom avatars',
          path: paths.admin.customAvatars,
        },
        {
          label: 'Assistants',
          path: paths.admin.assistants,
        },
        {
          label: 'Proposals',
          path: paths.admin.proposals,
        },
        {
          label: 'Pools',
          path: paths.admin.pools,
        },
        {
          label: 'Locations',
          path: paths.admin.locations,
        },
        {
          label: 'Guilds',
          path: paths.admin.guilds,
        },
      ],
    },
  ],
};
