import { editFieldsFromData } from '@/core/components/Edit/settings';

export enum ActionTypes {
  LOADING = '@pool/LOADING',
  LOADED = '@pool/LOADED',
  ERROR = '@pool/ERROR',
  SET_POOL_LIST = '@pool/SET_POOL_LIST',
  SET_POOL_EDIT_ITEM = '@pool/SET_POOL_EDIT_ITEM',
}

export interface PoolList {
  itemsList: any;
  editedItem: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.LOADED;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_POOL_LIST;
      payload: any;
    }
  | {
      type: ActionTypes.SET_POOL_EDIT_ITEM;
      payload: any;
    };

const poolState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: poolState,
  editedItem: editFieldsFromData.pool,
  isLoading: false,
  isError: false,
};

export const poolReducer = (state: PoolList = initialState, action: Action): PoolList => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ActionTypes.LOADED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_POOL_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    case ActionTypes.SET_POOL_EDIT_ITEM: {
      return {
        ...state,
        editedItem: action.payload,
        isError: false,
      };
    }

    default:
      return state;
  }
};
