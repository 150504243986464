import { AxiosRequestConfig } from 'axios';
import { api } from '@/core/api';
import { proposalPaths } from '@/core/api/api.config';

export const proposalApi = {
  getProposal: (params: any) => api.get(proposalPaths.proposal, { params }),
  getProposalById: (id: string) => api.get(proposalPaths.proposalById(id)),
  updateProposalItem: (body: any, config?: AxiosRequestConfig) => api.patch(proposalPaths.proposal, body, config),
  updateDecisionItem: (body: any, config?: AxiosRequestConfig) =>
    api.patch(proposalPaths.proposalDecision, body, config),
};
