import {paths} from '@/data/paths';

import {ReactComponent as FAQIcon} from '@/resources/icons/faq.svg';
import {ReactComponent as ContactIcon} from '@/resources/icons/contact.svg';
import {ReactComponent as PlusIcon} from '@/resources/icons/plus-circle.svg';
import {ReactComponent as InfoIcon} from '@/resources/icons/account-info.svg';

export const menuItems = [
  {
    title: 'Account Info',
    icon: InfoIcon,
    path: paths.realityjam.account
  },
  {
    title: 'Create Avatar',
    icon: PlusIcon,
    path: paths.realityjam.createAvatar
  },
  {
    title: 'FAQ',
    icon: FAQIcon,
    path: paths.realityjam.faq
  },
  {
    title: 'Contact',
    icon: ContactIcon,
    path: paths.marketplace.getInTouch
  }
];
