import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import styles from './Notify.module.scss';

interface INotifyMessage {
  title?: string
  message: string
}

interface IIcon {
  [key: string]: JSX.Element;
}

export const notify = {
  error: (message: string, title?: string) => toast.error(<Msg message={message} title={title} />, { icon: false, className: styles.notify__error }),
  success: (message: string, title?: string) => toast.success(<Msg message={message} title={title} />, { icon: false, className: styles.notify__success }),
  warning: (message: string, title?: string) => toast.warning(<Msg message={message} title={title} />, { icon: false, className: styles.notify__warning }),
  info: (message: string, title?: string) => toast.info(<Msg message={message} title={title} />, { icon: false, className: styles.notify__info }),
};

const Msg = ({ title, message }: INotifyMessage) => (
  <div className={styles.notify}>
    {
      title && (<div className={styles.notify__title}>{title}</div>)
    }
    <div className={styles.notify__message}>{message}</div>
  </div>
);

export const NotificationContainer = () => {
  return (
    <ToastContainer
      position="bottom-left"
      hideProgressBar
      autoClose={3000}
      closeButton={false}
      newestOnTop={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss
      draggable
    />
  );
};
