import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { ActionTypes } from '../reducers';
import { eventRoleApi } from '@/api';

export const setLoading = (loading: boolean) => ({ type: ActionTypes.LOADING, payload: loading });

export const actionSetEventRoleList = (data: any) => ({ type: ActionTypes.SET_EVENTROLES_LIST, payload: data });

export const actionSetEventRoleEditedItem = (data: any) => ({ type: ActionTypes.SET_EVENTROLE_EDIT, payload: data });

export const actionPermissionsList = (data: any) => ({
  type: ActionTypes.SET_EVENTROLES_PERMISSION_LIST,
  payload: data,
});

export const actionEventRoleList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await eventRoleApi.getEventRole(filters);

      if (response.data.success) {
        dispatch(actionSetEventRoleList(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetEvenRoleById =
  (id: string) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await eventRoleApi.getEventRoleById(id);

      if (response.data.success) {
        dispatch(actionSetEventRoleEditedItem(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionCreateEvenRoleItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));

      const selectedArray = body.permissions?.map((item: any) => item?.id);
      const response = await eventRoleApi.createEventRoleItem({
        ...body,
        permissions: selectedArray,
      });

      if (response.data.success) {
        notify.success('Event role updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionDeleteEvenRoleItem =
  (id: string) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await eventRoleApi.deleteEventRoleItem(id);

      if (response.data.success) {
        notify.success('Event role deleted successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetRolePermissionsList =
  (userIds?: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await eventRoleApi.getEventRolePermission();

      if (response.data.success) {
        dispatch(actionPermissionsList(response.data.data.items));
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateEventRoleItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));

      const selectedArray = body.permissions?.map((item: any) => item?.id);
      const response = await eventRoleApi.updateEventRoleItem({
        ...body,
        permissions: selectedArray,
      });

      if (response.data.success) {
        notify.success('Event role item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };
