import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { ActionTypes } from '../reducers';
import { assistantApi } from '@/api/assistant';
import { avatarApi } from '@/api';

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetAssistantList = (data: any) => ({
  type: ActionTypes.SET_ASSISTANT_LIST,
  payload: data,
});

export const actionSetAssistantEditedItem = (data: any) => ({
  type: ActionTypes.SET_ASSISTANT_EDIT_ITEM,
  payload: data,
});


export const actionAssistantList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await assistantApi.getAssistant({
        status: 'PREPARED',
        ...filters,
      });

      if (response.data.success) {
        const assistantList = response.data.data;

        dispatch(actionSetAssistantList(assistantList));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteAssistant = async (id: string): Promise<void> => {
  try {
    const response = await assistantApi.deleteAssistantItem(id);

    if (response.status === 204) {
      notify.success('Assistant item deleted successfully');
    } else {
      const error = response?.data?.message || response.message;

      throw error;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  }
};

export const actionGetAssistantItemById =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await assistantApi.getAssistantItemByid(body);

      if (response.data.success) {
        dispatch(actionSetAssistantEditedItem(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };


export const actionUpdateAssistantItem =
  (body: any) =>
    async (dispatch: TypedDispatch): Promise<boolean> => {
      try {
        dispatch(setLoading(true));

        const response = await assistantApi.updateAssistantItem(body);

        if (response.data.success) {
          notify.success('Assistant item updated successfully');
          return true;
        } else {
          throw response.data.message;
        }
      } catch (e: any) {
        const error = e.response?.data?.message || e.message || e;

        notify.error(error);
        throw e;
      } finally {
        dispatch(setLoading(false));
      }
    };

export const actionUpdateKnowledgeBaseItem =
  (body: any) =>
    async (dispatch: TypedDispatch): Promise<boolean> => {
      try {
        dispatch(setLoading(true));

        const response = await assistantApi.updateKnowledgeBaseItem(body);

        if (response.data.success) {
          notify.success('Assistant updated successfully');
          return true;
        } else {
          throw response.data.message;
        }
      } catch (e: any) {
        const error = e.response?.data?.message || e.message || e;

        notify.error(error);
        throw e;
      } finally {
        dispatch(setLoading(false));
      }
    };
