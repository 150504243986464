import { AxiosRequestConfig } from 'axios';
import { api } from '@/core/api';
import { venuePaths } from '@/core/api/api.config';

export const venueApi = {
  getVenue: (params: any) => api.get(venuePaths.venue, { params }),
  updateVenueItem: (body: any, config?: AxiosRequestConfig) => api.patch(venuePaths.venue, body, config),
  createVenueItem: (body: any, config?: AxiosRequestConfig) => api.post(venuePaths.venue, body, config),
  deleteVenueItem: (id: any) => api.delete(venuePaths.venueById(id)),
  getVenueItemByid: (id: any) => api.get(venuePaths.venueById(id)),
  mintVenueItem: (venueId: string) => api.put(venuePaths.mintVenue(venueId)),
  mintLocationsItem: (venueId: string) => api.put(venuePaths.mintLocations(venueId)),
};
