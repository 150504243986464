export const getDomain = (url: string, subdomain?: boolean) => {
  subdomain = subdomain || false;

  url = url.replace(/(https?:\/\/)?(www.)?/i, '');

  let urlArr = [];
  if (!subdomain) {
    urlArr = url.split('.');

    url = urlArr.slice(url.length - 2).join('.');
  }

  if (url.indexOf('/') !== -1) {
      return url.split('/')[0];
  }

  return url;
};
