import { userApi } from '@/api/user';
import { TypedDispatch } from '@/core/models';
import { notify } from '@/shared/components/Notify/Notify';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { ActionTypes } from '../reducers';

export const setLoading = (loading: boolean) => ({ type: ActionTypes.LOADING, payload: loading });

export const actionSetCreatorsList = (data: any) => ({ type: ActionTypes.SET_CREATOR_LIST, payload: data });

export const actionSetCreatorEditedData = (data: any) => ({ type: ActionTypes.SET_CREATOR_EDIT_ITEM, payload: data });

export const actionRolesLists = (data: any) => ({ type: ActionTypes.SET_CREATOR_ROLES_LIST, payload: data });

export const actionCreatorsList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const noDeletingInView = { ...filters, isDeleted: false };
      const response = await userApi.getUserData(noDeletingInView);

      if (response.data.success) {
        dispatch(actionSetCreatorsList(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateCreator =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.updateUserDetailsAdmin(body);

      if (response.data.success) {
        dispatch(actionCreatorsList());
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateCreatorData =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.updateUserIdDetailsAdmin(body);

      if (response.data.success) {
        dispatch(actionCreatorsList());
        notify.success('Creator data updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionBanCreator =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.bunUser(body);

      if (response.data.success) {
        dispatch(actionCreatorsList());
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUnbanCreator =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.unBunUser(body);

      if (response.data.success) {
        dispatch(actionCreatorsList());
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetCreatorById =
  (id: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.getUserItemByid({ id });

      if (response.data.success) {
        dispatch(actionSetCreatorEditedData(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionGetRolesList =
  (body?: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await userApi.getRolesList(body);

      if (response.data.success) {
        dispatch(actionRolesLists(response.data.data));
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };
