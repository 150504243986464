export const adminStatusCreateOptions = [
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
];

export const adminStatusEditOptions = [
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Blocked',
    value: 'BLOCKED',
  },
];

export const clientStatusOptions = [
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'OnAir',
    value: 'ONAIR',
  },
  {
    label: 'Finished',
    value: 'FINISHED',
  },
];

export const activatedOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const knowledgeBaseRequestStatusOptions = [
  {
    label: 'In progress',
    value: 'IN_PROGRESS',
  },
  {
    label: 'Ready',
    value: 'READY',
  },
  {
    label: 'Prepared for Convai',
    value: 'PREPARED',
  },
];

export const blockedOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const deletedOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const genderOptions = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Not specified',
    value: 'NOT_SPECIFIED',
  },
];

export const avatarTypeOptions = [
  {
    label: 'Premade',
    value: 'PREMADE',
  },
  {
    label: 'Custom',
    value: 'CUSTOM',
  },
];

export enum RoleRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export const proposalDecisionOptions = [
  {
    label: 'Approve',
    value: 'APPROVE',
  },
  {
    label: 'Decline',
    value: 'REJECT',
  },
];

export enum PoolLifecycleStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  DEFEATED = 'DEFEATED',
  SUCCEED = 'SUCCEED',
}

export enum Faction {
  INDEFINITE = "INDEFINITE",
  BLUE = "BLUE",
  RED = "RED",
}

export const FactionOptions = [
  {
    label: 'Nuri',
    value: Faction.RED,
  }, {
    label: 'Savi',
    value: Faction.BLUE,
  }, {
    label: 'None',
    value: Faction.INDEFINITE,
  },
];

export enum Location {
  SAMSARA = "Samsara",
  JAVAIA = "Javaia",
  UTARA = "Utara",
  INDU = "Indu",
  KATANAKA = "Katanaka",
}

export const LocationOptions = [
  {
    label: 'Studio City',
    value: Location.SAMSARA,
  }, {
    label: 'Neoktokyo',
    value: Location.UTARA,
  }, {
    label: 'Emajca',
    value: Location.KATANAKA,
  }, {
    label: 'Jotunheim',
    value: Location.JAVAIA,
  }, {
    label: 'Jade Mountain',
    value: Location.INDU,
  },
];

export enum SubscriptionPlan {
  FREE = 'Free',
  STANDARD = 'Demo Tier',
  PREMIUM = 'Jam Tier',
  PREMIUM2 = 'Avatar Tier',
}

export enum SubscriptionStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  CANCELED = 'Canceled',
}

export enum SubscriptionServiceType {
  STRIPE = "Stripe",
  RADOM = "Radom",
}

export const voiceOptions = [
  {
    label: 'High quality US Masculine Voice',
    value: 'High quality US Masculine Voice'
  },
  {
    label: 'Middle-aged Deep Bold US Masculine Voice',
    value: 'Middle-aged Deep Bold US Masculine Voice'
  },
  {
    label: 'Middle-aged Deep Strong US Masculine Voice',
    value: 'Middle-aged Deep Strong US Masculine Voice'
  },
  {
    label: 'Middle-aged Flirtatious US Feminine Voice',
    value: 'Middle-aged Flirtatious US Feminine Voice'
  },
  {
    label: 'Old-aged Deep Bold US Masculine Voice',
    value: 'Old-aged Deep Bold US Masculine Voice'
  },
  {
    label: 'Old-aged US Masculine Voice',
    value: 'Old-aged US Masculine Voice'
  },
  {
    label: 'Child US Masculine Voice',
    value: 'Child US Masculine Voice'
  },
  {
    label: 'High quality US Feminine Voice',
    value: 'High quality US Feminine Voice'
  },
  {
    label: 'Old-aged Wise US Feminine Voice',
    value: 'Old-aged Wise US Feminine Voice'
  },
  {
    label: 'Middle-aged Russian Accent Female Voice',
    value: 'Middle-aged Russian Accent Female Voice'
  },
  {
    label: 'Young Asian Masculine Voice',
    value: 'Young Asian Masculine Voice'
  },
  {
    label: 'Young Asian Feminine Voice',
    value: 'Young Asian Feminine Voice'
  },
];
