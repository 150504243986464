import React, { useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { FormLabel, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box } from '@mui/system';

import styles from './CustomInputPassword.module.scss';

export interface InputProps {
  form: FormikProps<string>;
  field: FieldInputProps<any>;
  placeholder: string;
  type: string;
  onChange?: any;
  value?: string;
  onBlur?: any | undefined;
  name?: string;
  id?: string;
  required?: boolean;
  label?: string;
  error?: string;
  multiline?: boolean;
  maxRows?: number;
  disabled?: boolean;
}

export const CustomInputPassword = (props: InputProps): JSX.Element => {
  const { form, field, onChange, placeholder, label, error, multiline, maxRows, required, disabled } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const onChangeHandler = (event: React.ChangeEvent<any>) => {
    field.onChange(event);
    onChange && onChange(event);
    form.setFieldTouched(field.name, true, false);
  };

  return (
    <Box className={styles.input}>
      <FormLabel className={styles.input__label}>
        {label}
        {required && <span> *</span>}
      </FormLabel>
      <TextField
        name={field.name}
        value={field.value}
        variant="outlined"
        autoComplete="off"
        hiddenLabel
        type={showPassword ? 'text' : 'password'}
        helperText={error}
        error={Boolean(error)}
        placeholder={placeholder}
        multiline={multiline}
        maxRows={maxRows}
        onChange={onChangeHandler}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword} edge="end">
                {showPassword ? <Visibility className={styles.input__icon} /> : <VisibilityOff className={styles.input__icon} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {form.touched[field.name] && Boolean(form.errors[field.name]) && <div className={styles.input__error}>{form.errors[field.name]}</div>}
    </Box>
  );
};
