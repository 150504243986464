import { useEffect, useState } from 'react';

import useDebounce from '@/core/hooks/useDebounce';
import { notify } from '@/shared/components/Notify/Notify';
import { userApi } from '@/api/user';

interface useSearchUserProps {
  value: string;
  user?: any;
  setOpen?: (val: boolean) => void;
}

const useSearchUser = ({ value, setOpen, user }: useSearchUserProps) => {
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedValue = useDebounce({ value });

  useEffect(() => {
    if (debouncedValue && !user) {
      (async function () {
        try {
          setLoading(true);

          const params = {
            limit: 10,
            username: debouncedValue,
          };

          const response = await userApi.getUserData(params);
          if (response.data.success) {
            if (response.data.data.items.length) {
              setUsers(response.data.data.items);
              setOpen && setOpen(true);
            } else {
              setOpen && setOpen(false);
            }
          } else {
            throw response.data.message;
          }
        } catch (e: any) {
          const error = e.response?.data?.message || e.message || e;
          notify.error(error);
          throw e;
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [debouncedValue]);

  return {
    users,
    loading,
  };
};

export default useSearchUser;
