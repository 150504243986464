export type AwsS3FileLocation = {
  id: string;
  hash: string;
};

export type FileLocation = AwsS3FileLocation;

export const normalizeFileUrl = (fileLocation: AwsS3FileLocation): string => {
  const fileStorageApi = process.env.REACT_APP_PUBLIC_FILE_STORAGE_API as string;
  return fileLocation ? `${fileStorageApi}/${fileLocation.hash}` : '';
}
