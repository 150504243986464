import { AxiosRequestConfig } from 'axios';
import { api } from '@/core/api';
import { instancePaths } from '@/core/api/api.config';

export const instanceApi = {
  getInstance: (params: any) => api.get(instancePaths.instance, { params }),
  updateInstanceItem: (id: string, body: any, config?: AxiosRequestConfig) => api.patch(instancePaths.instanceById(id), body, config),
  createInstanceItem: (body: any, config?: AxiosRequestConfig) => api.post(instancePaths.instance, body, config),
  deleteInstanceItem: (id: string) => api.delete(instancePaths.instanceById(id)),
  getInstanceItemByid: (id: string) => api.get(instancePaths.instanceById(id)),
};
