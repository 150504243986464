export const correctServiceName = (serviceName: string): string => {
  const serviceMapping = {
    event: 'event',
    eventRole: 'event roles',
    venue: 'venue',
    user: 'user',
    creator: 'creator',
    instance: 'instance',
    avatar: 'avatar',
    customAvatar: 'custom avatar',
    assistant: 'assistant',
    proposal: 'proposal',
    pool: 'pool',
    location: 'location',
    guild: 'guild',
  };

  return serviceMapping[serviceName];
};
