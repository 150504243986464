// Edit fields are not required except id
// Here is a data which we nead to get from server

export const editFieldsFromData = {
  venueOwner: {
    id: '', // Is hidden from user, not added in ./formEditFields.js
    walletAddress: '',
  },
  venue: {
    id: '',
    name: '',
    poolId: undefined,
    sceneId: '',
    metaUrl: '',
    thumbnailFile: undefined,
    coverFile: undefined,
    description: '',
    faction: '',
    location: '',
  },
  event: {
    title: '',
    venueName: '',
    startAt: '',
    finishAt: '',
    logUrl: '',
    videoUrl: '',
    secondVideoURL: '',
    previewVideoUrl: '',
    mockupPrefubId: '',
    adminStatus: '',
    clientStatus: '',
    stream: '',
    thumbnailFile: undefined,
    coverFile: undefined,
    gallery: [],
    removeGalleryFiles: [],
    description: '',
  },
  eventRoleToUser: {
    name: '',
    role: [],
  },
  eventRole: {
    name: '',
    permissions: [],
  },
  user: {
    id: '',
    username: '',
    email: '',
    // walletAddress: '', // do not need sand this field as form value
    updatedAt: '',
    isActivated: '',
    isBlocked: '',
    isDeleted: '',
    faction: undefined,
  },
  creator: {
    id: '',
    username: '',
    email: '',
    updatedAt: '',
    isActivated: '',
    isBlocked: '',
    isDeleted: '',
  },
  instance: {
    slots: 0,
    selfAddressUrl: '',
    voipStream: '',
    logUrl: '',
  },
  avatar: {
    name: '',
    description: '',
    glbFile: undefined,
  },
  customAvatar: {
    name: '',
    type: '',
    gender: '',
    isActive: '',
    glbFile: undefined,
    thumbnailFile: undefined,
    description: '',
  },
  assistant: {
    avatarId: '',
    isActive: '',
    backstory: '',
    avatar: undefined,
    voiceStyle: undefined,
    knowledgeBase: [],
    background: [],
    webglFile: undefined,
  },
  interactions: {
    interactions: 0,
  },
  proposal: {
    name: '',
    decision: 'Make a decision',
    glbFile: undefined,
    thumbnailFile: undefined,
    lifecycleStatus: '',
    gallery: [],
    removeGalleryFiles: [],
    faction: '',
    location: '',
    votingEndsAt: undefined,
  },
  pool: {
    periodStart: '',
    periodFinish: '',
    amount: '',
    duration: '',
  },
};
