import { DetailedHTMLProps, HTMLAttributes, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { Menu, MenuItem, IconButton, Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { serviceNameChecker } from '@/core/utils/serviceNameChecker';
import { Button } from '@/shared/components/Button';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import styles from './RowEventRolesActions.module.scss';
import { actionDeleteEvenRoleItem, actionEventRoleList } from '@/modules/EventRolesPage/actions';

interface RowUserActions extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  roleId: string;
  props?: any;
  className?: string;
}

export const RowEventRolesActions = ({ roleId, className }: RowUserActions): JSX.Element => {
  const dispatch = useDispatch();
  const currentPath = useLocation();
  const moduleName = currentPath.pathname.split('/')[1];
  const service = serviceNameChecker(moduleName);

  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = async () => {
    await dispatch(actionDeleteEvenRoleItem(roleId) as any);
    handleClose();
    handleCloseModal();
    dispatch(actionEventRoleList() as any);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openConfirmModal = () => setIsOpenedModal(true);

  const handleCloseModal = () => {
    setIsOpenedModal(false);
    handleClose();
  };

  return (
    <div className={cn(styles.tableActions, className)}>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon className={styles.tableActions__icon_btn} />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        <MenuItem className={styles.tableActions__type}>
          <Link
            to={`/${moduleName}/${roleId}`}
            className={styles.tableActions__link}
          >
            <EditIcon className={styles.tableActions__icon} />
            Edit
          </Link>
        </MenuItem>
        <MenuItem
          className={styles.tableActions__type}
          onClick={openConfirmModal}
        >
          <DeleteIcon className={styles.tableActions__icon} />
          Delete
        </MenuItem>
      </Menu>

      {isOpenedModal && (
        <Modal
          open={isOpenedModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-delete-item"
          aria-describedby="modal-modal-description"
        >
          <Box className={styles.tableActions__modal}>
            <Typography
              className={styles.tableActions__text}
              id="modal-delete-item"
              variant="h6"
              component="h2"
            >
              Are you sure?
            </Typography>
            <Box className={styles.tableActions__buttons}>
              <Button
                className={styles.tableActions__btn}
                onClick={handleCloseModal}
              >
                No
              </Button>
              <Button
                className={styles.tableActions__btn}
                onClick={handleDelete}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};
