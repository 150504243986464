import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Field, Form, Formik, FormikValues } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import * as web3lib from '@vrjam/vrjam-web3-lib';

import { editFieldsFromData, formEditFields } from '@/core/components/Edit/settings';
import { serviceNameChecker } from '@/core/utils/serviceNameChecker';
import { correctServiceName } from '@/core/utils/correctServiceName';
import { validationOfEditService } from '@/core/utils/validationOfService';
import useGetServiceItemById from '@/core/hooks/useGetServiceItemById';
import useConnectWallet from '@/core/hooks/useConnectWallet';
import { actionUpdateVenueItem } from '@/modules/VenuesPage/actions';
import { actionUpdateEventItem, actionSetEventRole } from '@/modules/EventsPage/actions';
import { actionAddUserRoles, actionRemoveUserRoles, actionUpdateUserData } from '@/modules/UsersPage/actions';
import { actionUpdateInstanceItem } from '@/modules/InstancePage/actions';
import { actionUpdateAvatarItem } from '@/modules/AvatarsPage/actions';
import { actionAssignCustomAvatar, actionUpdateCustomAvatarItem } from '@/modules/CustomAvatarsPage/actions';
import { actionUpdateEventRoleItem } from '@/modules/EventRolesPage/actions';
import { actionUpdateCreatorData } from '@/modules/CreatorsPage/actions';
import { actionUpdateProposalDecision, actionUpdateProposalItem } from '@/modules/ProposalPage/actions';
import { actionCreatePool, actionUpdatePoolItem } from '@/modules/PoolPage/actions';
import { Button } from '@/shared/components/Button';
import { CustomInput } from '@/shared/components/CustomInput';
import { ErrorLoading } from '@/shared/components/ErrorLoading';
import { notify } from '@/shared/components/Notify/Notify';
import { correctOperation } from '@/core/utils/correctOperation';

import styles from './Edit.module.scss';
import { actionUpdateAssistantItem } from '@/modules/AssistantsPage/actions';

export const Edit = (): JSX.Element | null => {
  const dispatch = useDispatch<any>();
  const currentPath = useLocation();
  const navigate = useNavigate();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState(currentPath.pathname.split('/')[1]);
  const [getItemId, setGetItemId] = useState(currentPath.pathname.split('/')[2]);
  const [domainFolder, setDomainFolder] = useState(currentPath.pathname.split('/')[3]);
  const [serviceName, setServiceName] = useState<string>(serviceNameChecker(moduleName));
  const { isConnectedWallet, handleWalletPopup } = useConnectWallet();

  useEffect(() => {
    const moduleName = currentPath.pathname.split('/')[1];
    const itemId = currentPath.pathname.split('/')[2];
    const domain = currentPath.pathname.split('/')[3];
    const service = serviceNameChecker(moduleName);

    setServiceName(service);
    setModuleName(moduleName);
    setGetItemId(itemId);
    setDomainFolder(domain);
  }, [currentPath.pathname]);

  const serviceAllData = useGetServiceItemById(serviceName, getItemId, domainFolder);
  const { editedItem, isLoading, isError } = serviceAllData;

  if (editedItem) {
    if (editedItem.startAt) editedItem.startAt = moment(editedItem.startAt).format('YYYY-MM-DDTHH:mm');
    if (editedItem.finishAt) editedItem.finishAt = moment(editedItem.finishAt).format('YYYY-MM-DDTHH:mm');
    if (editedItem.periodStart) editedItem.periodStart = moment(editedItem.periodStart).format('YYYY-MM-DDTHH:mm');
    if (editedItem.periodFinish) editedItem.periodFinish = moment(editedItem.periodFinish).format('YYYY-MM-DDTHH:mm');
    if (editedItem.votingEndsAt) editedItem.votingEndsAt = moment(editedItem.votingEndsAt).format('YYYY-MM-DDTHH:mm');
    if ((domainFolder || serviceName) === 'proposal' && editedItem.lifecycleStatus !== 'PENDING') {
      editedItem.decision = editedItem.lifecycleStatus === 'REJECTED' ? 'REJECT' : 'APPROVE';
    }
  }

  const isAvailable = !!editedItem?.isMinted;

  const createFormData = (values: FormikValues) => {
    const bodyFormData = new FormData();

    bodyFormData.append('id', editedItem.id);
    for (const [key, value] of Object.entries(values)) {
      if (
        value !== editedItem[key] &&
        key !== 'removeGalleryFiles' &&
        key !== 'glbFile' &&
        key !== 'thumbnailFile' &&
        key !== 'coverFile' &&
        key !== 'glbLocation' &&
        key !== 'thumbnailLocation' &&
        key !== 'coverLocation' &&
        key !== 'webglLocation' &&
        key !== 'knowledgeBase' &&
        key !== 'avatar' &&
        key !== 'gallery'
      ) {
        bodyFormData.append(key, value);
      }
    }

    if (values.removeGalleryFiles.length) {
      bodyFormData.append('removeGalleryFiles', JSON.stringify(values.removeGalleryFiles));
    }

    for (let i = 0; i < values.gallery?.length; i++) {
      if (!values.gallery[i].uuid) {
        bodyFormData.append('galleryFiles', values.gallery[i]);
      }
    }

    values.thumbnailFile && bodyFormData.append('thumbnailFile', values.thumbnailFile[0]);
    values.coverFile && bodyFormData.append('coverFile', values.coverFile[0]);
    values.glbFile && bodyFormData.append('glbFile', values.glbFile[0]);
    return bodyFormData;
  };

  const handleEditUserData = useCallback((body: any) => {
    const { roles, id } = body;

    const difference = (arrayOne: any, arrayTwo: any) =>
      arrayOne.filter((relation1: any) => !arrayTwo.some((relation2: any) => relation2.id === relation1.id));

    const addedItemsArray = difference(roles, editedItem.roles); // use add
    const removedItemsArray = difference(editedItem.roles, roles); // use remove

    if (addedItemsArray.length > 0) {
      const requestBody = { id, roles: addedItemsArray };
      dispatch(actionAddUserRoles(requestBody) as any);
    }

    if (removedItemsArray.length > 0) {
      const requestBody = { id, roles: removedItemsArray };
      dispatch(actionRemoveUserRoles(requestBody) as any);
    }

    dispatch(actionUpdateUserData(body) as any);
  }, [editedItem]);

  const handleEditProposalData = async (body: any) => {
    const { decision, id } = body;
    const bodyFormData = createFormData(body);

    await dispatch(actionUpdateProposalItem(bodyFormData) as any);

    if (decision.length > 0) {
      const requestBody = { id, decision };
      dispatch(actionUpdateProposalDecision(requestBody) as any);
    }

  };

  const handleEditPoolData = useCallback(async (body: any) => {
    try {
      if (!web3lib.isZeroRemindOfDivWithDecimals(body.amount, 100)) {
        throw Error('Amount is invalid');
      }
      if (!isConnectedWallet) {
        await handleWalletPopup();
        return false;
      } else {
        const updatedPool = await dispatch(actionUpdatePoolItem(body.id, body) as any);
        await dispatch(actionCreatePool(updatedPool));
        return true;
      }
    } catch (e: any) {
      console.warn(e);
      const error = e.response?.data?.message || e.reason || e.data?.message || e.message || e;
      notify.error(error);
      throw e;
    }
  }, [dispatch, isConnectedWallet, handleWalletPopup]);

  const sendData =  useCallback(async (body: any) => {
    setIsLoadingUpdate(true);
    try {
      let isUpdated = true;
      switch (domainFolder || serviceName) {
        case 'eventRoleToUser':
          await dispatch(actionSetEventRole(body) as any);
          break;
        case 'venue':
          await dispatch(actionUpdateVenueItem(body) as any);
          break;
        case 'event':
          await dispatch(actionUpdateEventItem(getItemId, body) as any);
          break;
        case 'eventRole':
          await dispatch(actionUpdateEventRoleItem(body) as any);
          break;
        case 'instance':
          await dispatch(actionUpdateInstanceItem(getItemId, body) as any);
          break;
        case 'user':
          await handleEditUserData(body);
          break;
        case 'creator':
          await dispatch(actionUpdateCreatorData(body) as any);
          break;
        case 'avatar':
          await dispatch(actionUpdateAvatarItem(body) as any);
          break;
        case 'customAvatar':
          await dispatch(actionUpdateCustomAvatarItem(body) as any);
          break;
        case 'proposal':
          await handleEditProposalData(body);
          break;
        case 'pool':
          isUpdated = await handleEditPoolData(body);
          break;
        case 'assignAvatar':
          await dispatch(actionAssignCustomAvatar(body) as any);
          break;
        case 'interactions':
          isUpdated = await dispatch(actionUpdateAssistantItem(body) as any);
          break;
      }

      if (isUpdated) {
        navigate(`/${ moduleName }`);
      }

      setIsLoadingUpdate(false);
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsLoadingUpdate(false);
    }
  }, [dispatch, domainFolder, serviceName, handleEditUserData, handleEditPoolData]);

  // validation
  const validationSettings = validationOfEditService(serviceName, domainFolder);
  const validationParams = yup.object().shape({ ...validationSettings });

  const transferHandler = async (nftId: string, toId: string) => {
    setIsLoadingUpdate(true);
    try {
      const collection = await web3lib.api.collection();
      const approveTx = await collection.transferNft(nftId, toId);
      if (approveTx.status!==1) {
        throw new Error('Transaction error');
      } else {
        notify.success('Transfer NFT success', 'Success');
        navigate(`/${moduleName}`);
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsLoadingUpdate(false);
    }
  }

  const submitHandler = (values: FormikValues) => {
    const updatedData = {...values};
    for (const [key, value] of Object.entries(updatedData)) {
      if (key === 'startAt' || key === 'finishAt' || key === 'periodStart' || key === 'periodFinish' || key === 'votingEndsAt') {
        updatedData[key] = moment(value).utc().format();
      }
    }

    const bodyFormData = createFormData(updatedData);
    const { eventRoleToUser } = updatedData;

    switch (domainFolder || serviceName) {
      case 'venueOwner':
        console.log('---updatedData.tokenId, updatedData.walletAddress---', updatedData.tokenId, updatedData.walletAddress)
        return transferHandler(updatedData.tokenId, updatedData.walletAddress)
      case 'eventRoleToUser':
        return sendData(eventRoleToUser);
      case 'user':
      case 'creator':
      case 'avatar':
      case 'instance':
      case 'eventRole':
      case 'proposal':
      case 'pool':
      case 'assignAvatar':
        return sendData(updatedData);
      default:
        return sendData(bodyFormData);
    }
  };

  if (!serviceName) return null;

  return (
    <div className={styles.edit}>
      <h2 className={styles.edit__title}>
        {correctOperation(serviceName, domainFolder)} {correctServiceName(serviceName)}
      </h2>
      <ErrorLoading
        isLoading={!editedItem}
        isError={isError}
      >
        {!isAvailable ? (
          <Formik
            enableReinitialize
            initialValues={{
              ...editFieldsFromData[`${domainFolder || serviceName}`],
              ...editedItem,
              removeGalleryFiles: [],
            }}
            validationSchema={validationParams}
            onSubmit={submitHandler}
          >
            <Form className={styles.edit__form}>
              {formEditFields(serviceName, domainFolder).map((item: any) => (
                <div
                  key={item.name}
                  className={styles.edit__row}
                >
                  <Field
                    name={item.name}
                    label={item.label}
                    options={item.options}
                    component={item.component || CustomInput}
                    required={item.isRequired}
                    type={item.type}
                    disabled={item.isDisabled}
                    InputProps={{
                      inputProps: { min: item.min || 0 },
                    }}
                  />
                </div>
              ))}

              <Button
                className={styles.edit__btn}
                loading={isLoadingUpdate}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Formik>
        ) : (
          <p className={styles.edit__notEditable}>This {`${moduleName}`} is minted and can't be edited</p>
        )}
      </ErrorLoading>

      <Link
        to={`/${moduleName}`}
        className={styles.edit__link}
        color="inherit"
        type="button"
      >
        Go to {correctServiceName(serviceName)} page
      </Link>
    </div>
  );
};
