import { proposalApi } from '@/api';
import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { ActionTypes } from '../reducers';
import { userApi } from '@/api/user';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetProposalList = (data: any) => ({
  type: ActionTypes.SET_PROPOSAL_LIST,
  payload: data,
});

export const actionSetProposalEditedItem = (data: any) => ({
  type: ActionTypes.SET_PROPOSAL_EDIT_ITEM,
  payload: data,
});

export const actionGetProposalList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await proposalApi.getProposal(filters);

      if (response.data.success) {
        const userIds: string[] = [];
        const usersList = response.data.data;

        usersList.items &&
          usersList.items.forEach((item: any) => {
            if (item.creatorId) {
              userIds.push(item.creatorId);
            }
          });

        if (userIds.length) {
          const usersResponse = await userApi.getUsersByIds(userIds);

          if (usersResponse.data.success) {
            const userList = usersResponse.data.data;
            usersList.items.forEach((item: any) => {
              if (item.creatorId) {
                const userIndex = userList.findIndex(
                  (user: { id: string; username: string }) => item.creatorId === user.id,
                );
                item.userName = userList[userIndex]?.username;
              }
            });
          }
        }

        dispatch(actionSetProposalList(usersList));
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateProposalItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response = await proposalApi.updateProposalItem(body, config);

      if (response.data.success) {
        notify.success('Proposal item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      console.log(error, ' <<<< ERROR from backend '); // TODO

      notify.error(error);
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const actionUpdateProposalDecision =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch(setLoading(true));

      const response = await proposalApi.updateDecisionItem(body);

      if (!response.data.success) {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteProposal = async (id: string): Promise<void> => {
  try {
    const response = await proposalApi.getProposal(id);

    if (response.status === 204) {
      notify.success('Proposal item deleted successfully');
    } else {
      const error = response?.data?.message || response.message;

      throw error;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  }
};

export const actionGetProposalItemById =
  (id: string) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    {
      try {
        dispatch(setLoading(true));
        const response = await proposalApi.getProposalById(id);

        if (response.data.success) {
          dispatch(actionSetProposalEditedItem(response.data.data));
        } else {
          dispatch({ type: ActionTypes.ERROR });
          throw response.data.message;
        }
      } catch (e: any) {
        const error = e.response?.data?.message || e.message || e;

        notify.error(error);
        dispatch({ type: ActionTypes.ERROR });
        throw e;
      } finally {
        dispatch(setLoading(false));
      }
    }
  };
