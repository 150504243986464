import { instanceApi } from '@/api';
import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { ActionTypes } from '../reducers';
import { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';

export const actionSetInstanceList = (data: any) => ({ type: ActionTypes.SET_INSTANCE_LIST, payload: data });

export const actionSetInstanceEditedItem = (data: any) => ({ type: ActionTypes.SET_INSTANCE_EDIT_ITEM, payload: data });

export const actionInstanceList =
  (filters?: FilterProps) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch({ type: ActionTypes.LOADING });
      const response = await instanceApi.getInstance(filters);

      if (response.data.success) {
        dispatch(actionSetInstanceList(response.data.data));
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      dispatch({ type: ActionTypes.ERROR });
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    }
  };

export const actionUpdateInstanceItem =
  (id: string, body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch({ type: ActionTypes.LOADING });

      const response = await instanceApi.updateInstanceItem(id, body);

      if (response.data.success) {
        notify.success('Instance item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      throw error;
    }
  };

export const deleteInstance = async (id: string): Promise<void> => {
  try {
    const response = await instanceApi.deleteInstanceItem(id);

    if (response.status === 204) {
      notify.success('Instance item deleted successfully');
    } else {
      const error = response?.data?.message || response.message;

      throw error;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  }
};

export const actionCreateInstanceItem =
  (body: any) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch({ type: ActionTypes.LOADING });
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response = await instanceApi.createInstanceItem(body, config);

      if (response.data.success) {
        notify.success('Instance item updated successfully');
      } else {
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;
      notify.error(error);
      throw e;
    } finally {
      dispatch({ type: ActionTypes.LOADED });
    }
  };

export const actionGetInstanceItemById =
  (id: string) =>
  async (dispatch: TypedDispatch): Promise<void> => {
    try {
      dispatch({ type: ActionTypes.LOADING });
      const response = await instanceApi.getInstanceItemByid(id);

      if (response.data.success) {
        dispatch(actionSetInstanceEditedItem(response.data.data));
      } else {
        dispatch({ type: ActionTypes.ERROR });
        throw response.data.message;
      }
    } catch (e: any) {
      const error = e.response?.data?.message || e.message || e;

      notify.error(error);
      dispatch({ type: ActionTypes.ERROR });
      throw e;
    }
  };
