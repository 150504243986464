import { api } from '@/core/api';
import { assistantPaths } from '@/core/api/api.config';
import { AxiosRequestConfig } from 'axios';

export const assistantApi = {
  getAssistant: (params: any) => api.get(assistantPaths.assistant, { params }),
  deleteAssistantItem: (id: string) => api.delete(assistantPaths.assistantById(id)),
  getAssistantItemByid: (id: string) => api.get(assistantPaths.assistantById(id)),
  updateAssistantItem: (body: any, config?: AxiosRequestConfig) => api.put(assistantPaths.assistant, body, config),
  updateKnowledgeBaseItem: (body: any, config?: AxiosRequestConfig) => api.patch(assistantPaths.knowledgeBase, body, config),
};
