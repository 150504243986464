import { ChangeEvent, DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Box, Input, InputAdornment } from '@mui/material';
import cn from 'classnames';

import { ReactComponent as SearchSvg } from '@/resources/icons/search.svg';
import { ReactComponent as CloseSvg } from '@/resources/icons/close-modal-icon.svg';

import styles from './SectionSearch.module.scss';

interface SearchProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
  searchHandle?: any;
}

export const SectionSearch = ({ className, searchHandle }: SearchProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const onEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (searchHandle) {
        searchHandle(value);
      }
    }
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const onClear = () => {
    setValue('');
    if (searchHandle) {
      searchHandle('');
    }
  };

  useEffect(() => {
    inputRef.current?.addEventListener('keypress', onEnter);

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keypress', onEnter);
      }
    };
  }, [value]);

  return (
    <Box
      className={cn(styles.search, className, {
        [styles.search_focused]: focused,
      })}
    >
      <Input
        ref={anchorRef}
        value={value}
        onChange={inputChangeHandler}
        inputRef={inputRef}
        className={styles.search__field}
        disableUnderline
        placeholder="Search"
        onFocus={onFocus}
        onBlur={onBlur}
        startAdornment={
          <InputAdornment
            position="start"
            variant="filled"
            className={styles.search__field_searchIcon}
          >
            <SearchSvg />
          </InputAdornment>
        }
        endAdornment={
          <>
            {value && (
              <InputAdornment
                position="end"
                onClick={onClear}
                className={styles.search__field_closeIcon}
              >
                <CloseSvg />
              </InputAdornment>
            )}
          </>
        }
      />
      <button
        className={styles.search__button}
        onClick={() => searchHandle(value)}
      >
        Search
      </button>
    </Box>
  );
};
